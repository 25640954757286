import {Team, useApiCall, useApiEndpoint} from "@jane/lib/src/api";
import React, {useMemo} from "react"
import {JaneEmployee, useEmployeesData} from "../resources/EmployeeContext";
import {useTeamData} from "../resources/TeamContext";
import {usePermissions} from "../resources/PermissionsHook";
import {config} from "../config";
import { ContentContainer } from "@jane/lib/src/components/content/ContentContainer";
import { Loading } from "@jane/lib/src/components/Loading";
import {getRelatedTeamIds, useTeamMap} from "../components/team-select";
import DynamicDataTable from "../components/data-table/dynamic-data-table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimesCircle, faWarning} from "@fortawesome/free-solid-svg-icons";
import { HoverHint } from "@jane/lib/src/components/content/HoverHint";
import {NavLink} from "react-router-dom";
import { PageHeader } from "@jane/lib/src/components/content/PageHeader";

interface EmployeeWithTeam extends JaneEmployee {
  team_name: string
}
export default function EmployeesListPage(): JSX.Element {
  const { employees, isLoading, error } = useEmployeesData()
  const { teams, isLoading: loadingTeams } = useTeamData()

  const { teams: teamsApi } = useApiCall(config)
  const { canListTeams } = usePermissions()
  const { resource: teamResource, isLoading: loadingTeam } = useApiEndpoint(() => teamsApi.myTeam(), canListTeams)
  const { resource: secondaryTeams, isLoading: loadingSecondaryTeams } = useApiEndpoint(() => teamsApi.myTeams(), canListTeams)

  return <ContentContainer>
    <PageHeader>Medewerkers</PageHeader>

    <Loading loading={isLoading || loadingTeam || loadingTeams || loadingSecondaryTeams}>
      { !! employees && <FilteredEmployeeTable employees={employees} teams={teams} primaryTeam={teamResource!} secondaryTeams={secondaryTeams || []} />  }
    </Loading>
  </ContentContainer>
}

function FilteredEmployeeTable(props: {employees: JaneEmployee[], teams: Team[], primaryTeam: Team|null, secondaryTeams: Team[]}): JSX.Element {
  const teamMap: {[id: number]: Team} = useMemo(() => {
    let teamMap: {[id: number]: Team} = {}
    props.teams.forEach(team => teamMap[team.id] = team)
    return teamMap
  }, [props.teams])
  const enrichedEmployees = useMemo(() => props.employees
    .map((employee: JaneEmployee): EmployeeWithTeam => {
      return {
        ...employee,
        team_name: employee.team_id !== null && teamMap[employee.team_id] ? teamMap[employee.team_id].name : '-'
      }
    }), [props.employees, teamMap])

  return <div>
    <EmployeeTable employees={enrichedEmployees} teams={props.teams} primaryTeam={props.primaryTeam} secondaryTeams={props.secondaryTeams} />
  </div>
}

function EmployeeTable({employees, teams, primaryTeam, secondaryTeams}: {employees: EmployeeWithTeam[], teams: Team[], primaryTeam: Team|null, secondaryTeams: Team[]}): JSX.Element {
  const teamMap = useTeamMap(teams, primaryTeam, secondaryTeams)
  const {canListAllTeams} = usePermissions()
  const teamOptionsMap: Map<number, [string, number]> = useMemo(() => {
    return new Map([
      [-2, ['Alle', 0]],
      [-1, ['Mijn teams', 0]],
      ...teamMap.filter(teams => teams[0] !== undefined)
        .map<[Team, number]>(teams => [teams.slice(-1)[0], teams.length])
        .map<[number, [string, number]]>(([team, indentation]) => [team.id, [team.name, indentation]])
    ])
  }, [teamMap])
  const teamFilterOptions: {[value: string]: string} = useMemo(() => {
    return Object.fromEntries(
      Array.from(teamOptionsMap)
        .filter(([id]: [number, [string, number]]): boolean => {
          return !(canListAllTeams && id === -1)
        })
        .map(([id, [name, indentation]]: [number, [string, number]]): [string, string] => {
          return [id.toString(), (canListAllTeams ? "\xA0\xA0".repeat(indentation) : "") + name]
        })
    )
  }, [teamOptionsMap, canListAllTeams])
  /** Used to look up related teams */
  const relatedTeamsMap: {[teamId: string]: string[]} = useMemo(() => {
    return Object.fromEntries(teams.map((team) => {
      return [team.id.toString(), getRelatedTeamIds(team, teamMap).map(id => id.toString())]
    }))
  }, [teamMap, teams])
  return <DynamicDataTable<EmployeeWithTeam>
    searchableProperties={['given_name', 'family_name', 'email', 'team_name', 'employee_number']}
    keyProperty={'id'}
    data={employees}
    availableFilters={[
      {
        title: 'Team',
        property: 'team_id',
        options: teamFilterOptions,
        defaultValue: canListAllTeams ? '-2' : '-1',
        filterCallback: (row, filter) => {
          return filter === '-2'
            || (
              filter === '-1'
              && row.team_id !== null
              && Object.keys(teamFilterOptions).includes(row.team_id.toString())
            )
            || (
              row.team_id !== null
              && relatedTeamsMap[filter]?.includes(row.team_id.toString())
            )
        },
      },
      {
        title: 'Sync status',
        property: 'sub',
        options: {
          'all': 'Alle',
          'synced': 'Jane account',
          'sync_error': 'Niet in systeem',
        },
        defaultValue: 'all',
        filterCallback: (row, filter) => {
          if (filter === 'synced') {
            return !!row.sub
          } else if (filter === 'sync_error') {
            return !row.sub
          }
          return true
        }
      },
      {
        title: 'Rol',
        property: 'role',
        options: {
          'all': 'Alle',
          'Werknemer': 'Werknemer',
          'Coordinator': 'Coordinator',
          'Manager': 'Manager',
        },
        defaultValue: 'all',
        filterCallback: (row, filter) => filter === 'all' ? true : row.role === filter,
      },
      {
        title: 'Email',
        property: 'email',
        options: {
          'all': 'Alle',
          'miep': '@miep.nu',
          'other': 'Anders',
          'none': 'Onbekend',
        },
        defaultValue: 'all',
        filterCallback: (row, filter) => {
          switch(filter) {
            case "miep": return row.email?.endsWith("@miep.nu")
            case "other": return row.email !== null && ! row.email.endsWith("@miep.nu")
            case "none": return row.email === null
            default: return true
          }
        },
      },
      {
        title: 'Profielfoto',
        property: 'employee_picture_url',
        options: {
          'all': 'Alle',
          'yes': 'Heeft foto',
          'no': 'Geen foto',
        },
        defaultValue: 'all',
        filterCallback: (row, filter) => {
          switch(filter) {
            case "yes": return row.employee_picture_url !== null
            case "no": return row.employee_picture_url === null
            default: return true
          }
        },
      },
    ]}
    columns={[
      {header: 'Naam', property: 'id', transform: (id, employee) => <div className={"flex items-center"}>
          { employee.sub ? <>
            <HoverHint key={id} hint={'Jane account'}>
              <FontAwesomeIcon icon={faCheckCircle} className={'text-emerald-500 text-lg'} />
            </HoverHint>
          </> : <>
            <HoverHint key={id} hint={'Niet in systeem'}>
              <FontAwesomeIcon icon={faWarning} className={'text-amber-500 text-lg'} />
            </HoverHint>
          </>}
          <div className={"flex-1 ml-3"}>
            <small className={'text-gray-500'}>{employee.employee_number} &middot; {employee.role}</small>
            <div>
              {employee.given_name} <strong>{employee.family_name}</strong>
            </div>
          </div>
        </div>},
      {header: 'Email', property: 'email'},
      {header: 'Team', property: 'team_name'},
      {header: 'Acties', property: 'id', transform: (id, row) => {
          if (!canListAllTeams && (row.team_id === null || !Object.keys(teamFilterOptions).includes(row.team_id.toString()))) {
            return <HoverHint key={id} hint={'Deze medewerker zit in een team waar jij geen toegang tot hebt'}>
              <FontAwesomeIcon icon={faTimesCircle} className={'text-gray-500 mr-1'} /> <span>Geen toegang</span>
            </HoverHint>
          }
          return <NavLink to={`/employees/${id}`} className={'text-brand-primary'}>Beheer</NavLink>
        }}
    ]}
    loading={false}
  />
}

