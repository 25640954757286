import { Client } from "@jane/lib/src/api"
import { Card } from "@jane/lib/src/components/Card";
import React, {useMemo} from "react"
import {JaneEmployee} from "../resources/EmployeeContext";
import {usePermissions} from "../resources/PermissionsHook";
import {useTeamData} from "../resources/TeamContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleExclamation,
  faPeopleGroup,
  faPerson,
  faWarning
} from "@fortawesome/free-solid-svg-icons";


const ProfileCard: React.FC<{person: Client|JaneEmployee}> = props => {
    const person: Client|JaneEmployee = props.person
    const {canSeeSubjectIdOnPerson} = usePermissions()
    const { teams } = useTeamData()
    const team = useMemo(() => {
        return teams.find((e) => e.id === person.team_id)
    }, [teams, person])

    const type: "client"|"employee" = "client_number" in person ? "client" : "employee"
    const number = "client_number" in person ? person.client_number : person.employee_number

    const userStatus: JSX.Element = useMemo(() => {
        if (! person.sub) {
            return <div className={"bg-brand-error px-2 rounded text-brand-on-error"}>
                <div className={"my-1"}><FontAwesomeIcon icon={faCircleExclamation} className={"mr-2"} /> Niet in systeem</div>
            </div>
        }
        const actions: string[] = person.actions || []
        if (actions.length === 0) {
            return <div className={"text-base text-brand-primary"}>
                <div className={"my-1"}><FontAwesomeIcon icon={faCheckCircle} className={"mr-2"} /> Actief Jane Account</div>
            </div>
        }

        if (actions.includes("UPDATE_PASSWORD")) {
            return <div className={"bg-brand-error px-2 rounded text-brand-on-error"}>
                <div className={"my-1"}><FontAwesomeIcon icon={faWarning} className={"mr-2"} /> Account nog niet geactiveerd</div>
            </div>
        }

        if (actions.includes("CONFIGURE_TOTP")) {
            return <div className={"bg-brand-error px-2 rounded text-brand-on-error"}>
                <div className={"my-1"}><FontAwesomeIcon icon={faWarning} className={"mr-2"} /> 2FA nog niet ingesteld</div>
            </div>
        }

        return <div className={"bg-brand-error px-2 rounded text-brand-on-error"}>
            <div className={"my-1"}><FontAwesomeIcon icon={faWarning} className={"mr-2"} /> Actie van gebruiker vereist</div>
        </div>
    }, [person])

    return <Card title={"Profiel"} id={"profile"}>
        {/* Contact info */}
        <div className={"flex items-center"}>
            <div className={"text-brand-on-primary bg-brand-primary flex items-center justify-center h-12 w-12 rounded-full mr-4"}>
              <FontAwesomeIcon icon={faPerson} className={"text-2xl"} />
            </div>
            <div className={"text-lg flex-1 flex justify-between"}>
                <div>
                    <div className={"text-xs font-bold uppercase tracking-wide"}>{type === "client" ? "Cliënt" : "Medewerker"} #{number}</div>
                    <div className={"font-bold"}>{person.name}</div>
                    <div>
                        {person.preferred_username}
                        {person.preferred_username?.toLowerCase() !== person.email?.toLowerCase() && !! person.email ? <> &middot; {person.email}</> : ''}
                    </div>
                </div>
                {!! person.sub && canSeeSubjectIdOnPerson && <div>
                    <div className={"text-xs bg-brand-surface-variant text-brand-on-surface-variant border border-brand-on-surface-variant rounded font-mono px-2 py-1"}>
                        { person.sub }
                    </div>
                </div>}
            </div>
        </div>
        {/* Role */}
        <div className={"flex"}>
            <div className={"w-12 mr-4"}>
            </div>
            <div className={"text-base text-brand-secondary"}>
                { "role" in person ? <>
                    <div className={"my-1"}><FontAwesomeIcon icon={faPerson} className={"mr-2"} /> {person.role}</div>
                </> : <></> }
            </div>
        </div>
        {/* Teams */}
        <div className={"flex"}>
            <div className={"w-12 mr-4"}>
            </div>
            <div className={"text-base text-brand-secondary"}>
                { team ? <>
                    <div className={"my-1"}><FontAwesomeIcon icon={faPeopleGroup} className={"mr-2"} /> {team.name}</div>
                </> : <></> }
            </div>
        </div>
        {/* Status */}
        <div className={"flex"}>
            <div className={"w-12 mr-4"}>
            </div>
            <div>
                { userStatus }
            </div>
        </div>
    </Card>
}

export default ProfileCard
