import BroadcastRepository from "./repositories/broadcast-repository";
import CacheRepository from "./repositories/cache-repository";
import SettingsRepository from "./repositories/settings-repository";
import EmployeesRepository from "./repositories/employees-repository";
import ClientsRepository from "./repositories/clients-repository";
import LocationsRepository from "./repositories/locations-repository";
import NetworkRepository from "./repositories/network-repository";
import NotificationPreferencesRepository from "./repositories/notification-preferences-repository";
import TasksRepository from "./repositories/tasks-repository";
import AgendaRepository from "./repositories/agenda-repository";
import TeamsRepository from "./repositories/teams-repository";
import SyncRepository from "./repositories/sync-repository";
import DocumentsRepository from "./repositories/documents-repository";
import ChatRepository from "./repositories/chat-repository";
import LogsRepository from "./repositories/logs-repository";
import TelephoneRepository from "./repositories/telephone-repository";
import ClientContactsRepository from "./repositories/client-contacts-repository";
import {useAuth} from "../auth/hook";
import {ConfigInterface} from "../config";
import LearnRepository from "./repositories/learn-repository";

export type { Broadcast } from "./repositories/broadcast-repository"
export type { SettingMap } from "./repositories/settings-repository"
export type { Employee, KeycloakUser } from "./repositories/employees-repository"
export type { Client, SelectableClient } from "./repositories/clients-repository"
export type { ClientContactDetails } from "./repositories/client-contacts-repository"
export type { Location, SocialCard } from "./repositories/locations-repository"
export type { RelationType, ClientContact, PermissionSet } from "./repositories/network-repository"
export type { NotificationPreferences } from "./repositories/notification-preferences-repository"
export type { Task, TaskType, TaskItemType, TaskStatus } from "./repositories/tasks-repository"
export type { AgendaItem } from "./repositories/agenda-repository"
export type { Team, TeamAssignment } from "./repositories/teams-repository"
export type { Document } from "./repositories/documents-repository"
export type { SyncStatus } from "./repositories/sync-repository"
export type { ChatRoomInfo, ChatRoomInfoMember } from "./repositories/chat-repository";
export type { MatchedItem, SearchResult, SavedMatch } from "./repositories/telephone-repository";


export { ApiError } from "./call"
export { useApiEndpoint } from "./hook"
export const useApiCall = (config: ConfigInterface): {
    broadcasts: BroadcastRepository
    cache: CacheRepository
    chat: ChatRepository
    settings: SettingsRepository
    employees: EmployeesRepository
    clients: ClientsRepository
    clientContacts: ClientContactsRepository
    locations: LocationsRepository
    network: NetworkRepository
    notificationPreferences: NotificationPreferencesRepository
    tasks: TasksRepository
    agenda: AgendaRepository
    teams: TeamsRepository
    sync: SyncRepository
    documents: DocumentsRepository
    logs: LogsRepository
    telephone: TelephoneRepository
    learn: LearnRepository
} => {
    const { token, selectedClient } = useAuth()

    return {
        broadcasts: new BroadcastRepository(config.restApiUrl, token, selectedClient),
        cache: new CacheRepository(config.restApiUrl, token, selectedClient),
        chat: new ChatRepository(config.restApiUrl, token, selectedClient),
        settings: new SettingsRepository(config.restApiUrl, token, selectedClient),
        employees: new EmployeesRepository(config.restApiUrl, token, selectedClient),
        clients: new ClientsRepository(config.restApiUrl, token, selectedClient),
        clientContacts: new ClientContactsRepository(config.restApiUrl, token, selectedClient),
        locations: new LocationsRepository(config.restApiUrl, token, selectedClient),
        network: new NetworkRepository(config.restApiUrl, token, selectedClient),
        notificationPreferences: new NotificationPreferencesRepository(config.restApiUrl, token, selectedClient),
        tasks: new TasksRepository(config.restApiUrl, token, selectedClient),
        agenda: new AgendaRepository(config.restApiUrl, token, selectedClient),
        teams: new TeamsRepository(config.restApiUrl, token, selectedClient),
        sync: new SyncRepository(config.restApiUrl, token, selectedClient),
        documents: new DocumentsRepository(config.restApiUrl, token, selectedClient),
        logs: new LogsRepository(config.restApiUrl, token, selectedClient),
        telephone: new TelephoneRepository(config.restApiUrl, token, selectedClient),
        learn: new LearnRepository(config.restApiUrl, token, selectedClient),
    }
}
