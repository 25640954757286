import {AbstractRepository} from "../repository";

export type NotificationPreferences = {
    whatsapp_enabled: boolean
    email_enabled: boolean
    sms_enabled: boolean
    push_enabled: boolean
}

export default class NotificationPreferencesRepository extends AbstractRepository {

    /**
     * Get notification settings for current user
     */
    public myPreferences(): Promise<NotificationPreferences> {
        return this.request<NotificationPreferences>('GET', 'my_notification_settings')
    }

    /**
     * Modify notification settings for current user
     */
    public updateMyPreferences(preferences: NotificationPreferences): Promise<void> {
        return this.request('PUT', 'my_notification_settings', preferences)
            .then(() => {})
    }

    /**
     * Get notification settings for user
     */
    public subjectPreferences(subject: string): Promise<NotificationPreferences> {
        return this.request<NotificationPreferences>('GET', `notification_settings/${subject}`)
    }

    /**
     * Modify notification settings for user
     */
    public updateSubjectPreferences(subject: string, preferences: NotificationPreferences): Promise<void> {
        return this.request('PUT', `notification_settings/${subject}`, preferences)
            .then(() => {})
    }
}
