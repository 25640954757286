import {FC, ReactNode} from "react";

interface BadgeProps {
  children: ReactNode
  className: string
}
export const Badge: FC<BadgeProps> = props => {
  return <span className={`px-2 py-1 rounded-full text-xs font-semibold ${props.className}`}>
    {props.children}
  </span>
}