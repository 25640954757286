import {AbstractRepository} from "../repository";
export type LogLevel = 'debug'|'info'|'warn'|'error'
export type LogLine = {
    id: string,
    level: LogLevel,
    category: string,
    user: string|null,
    message: string|null,
    context: object|null,
    request_id: string|null,
    created_at: string,
}
interface LogParams {
    page?: number
    level?: Array<LogLevel>
    category?: Array<string>
    user?: string
    request_id?: string
}
interface PaginationMetadata {
    total: number
    per_page: number
    current_page: number
    last_page: number
    from: number
    to: number
}

export default class LogsRepository extends AbstractRepository {
    /**
     * List settings for a Jane instance
     */
    public get(params: LogParams): Promise<{data: LogLine[], pagination: PaginationMetadata}> {
        const p = new URLSearchParams()
        if (params.page) {
            p.append('page', params.page.toString())
        }
        if (params.level) {
            params.level.forEach(level => p.append('level[]', level))
        }
        if (params.category) {
            params.category.forEach(category => p.append('category[]', category))
        }
        if (params.user) {
            p.append('user', params.user)
        }
        if (params.request_id) {
            p.append('request_id', params.request_id)
        }
        return this.request<{data: LogLine[], pagination: PaginationMetadata}>('GET', 'event_logs?'+p.toString())
    }
}
