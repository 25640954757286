import {FC} from "react";

export const Toggle: FC<{value: boolean, onChange: (newValue: boolean) => void, label?: string}> = props => {
  if (props.label) {
    return <label className={"flex items-center h-16 border border-brand-secondary rounded px-4 cursor-pointer"}>
      <ToggleSwitch value={props.value} onChange={props.onChange} />
      <span className={"ml-3"}>{props.label}</span>
    </label>
  }
  return <label><ToggleSwitch value={props.value} onChange={props.onChange} /></label>
}

const ToggleSwitch: FC<{value: boolean, onChange: (newValue: boolean) => void}> = props => {
  return <div className={`block rounded-full w-12 h-6 ${props.value ? 'bg-brand-primary ' : 'bg-brand-secondary '} transition-colors relative cursor-pointer`}>
    {/* Invisible input */}
    <input type="checkbox" checked={props.value} onChange={(e) => props.onChange(e.target.checked)} className={"hidden"}/>
    {/* Ball */}
    <div className={`h-6 w-6 border bg-white rounded-full absolute transition-all top-0 ${props.value ? 'left-6 border-brand-800' : 'left-0 border-slate-200'}`}></div>
  </div>
}