import {AbstractRepository} from "../repository";

export type Team = {
    id: number
    beginDate: string
    endDate: string
    identificationNo: string
    name: string
    parentObjectId: number
}

export type TeamAssignment = {
    ons_employee_id: number
    ons_team_id: number
    primary: boolean
}

export default class TeamsRepository extends AbstractRepository {
    /**
     * List all teams in the system
     */
    public all(): Promise<Array<Team>> {
        return this.request<{data: {[id: number]: Team}}>('GET', 'teams')
            .then(this.unpackDataCollectionResponse)
    }

    /**
     * Get the primary team of the current user
     */
    public myTeam(): Promise<Team|null> {
        return this.request<{data: Team|null}>('GET', 'my_team')
            .then(r => r.data)
    }

    /**
     * Get the secondary teams of the current user
     */
    public myTeams(): Promise<Array<Team>> {
        return this.request<{data: {[id: number]: Team}}>('GET', 'my_teams')
            .then(this.unpackDataCollectionResponse)
    }

    /**
     * List secondary teams for an employee
     */
    public secondaryTeams(employeeId: number): Promise<Array<TeamAssignment>> {
        return this.request<{data: {[id: number]: TeamAssignment}}>('GET', `employee/${employeeId}/team_assignments`)
            .then(this.unpackDataCollectionResponse)
    }

    /**
     * Update secondary teams for an employee
     */
    public updateSecondaryTeams(employeeId: number, teams: Array<number>): Promise<void> {
        return this.request('POST', `employee/${employeeId}/team_assignments`, { teams })
            .then(() => {})
    }
}
