import {AbstractRepository} from "../repository";
import {ClientContactDetails} from "./client-contacts-repository";

// TODO: Rename to network member
export type ClientContact = {
    email: string
    enabled: boolean
    firstName: string
    lastName: string
    id: string
    username: string
}

export type PermissionSet = {
    client_id: number
    can_access_tasks: boolean
    can_access_agenda: boolean
    can_access_contacts: boolean
    can_access_documents: boolean
}

export type RelationType = {
    id: number
    name: string
    category: string
}

export default class NetworkRepository extends AbstractRepository {
    /**
     * List all network members
     */
    public members(): Promise<ClientContact[]> {
        return this.request<{data: ClientContact[]}>('GET', 'my_client_contacts')
            .then(this.unpackDataCollectionResponse)
    }

    /**
     * List all network members for client
     */
    public membersForClient(clientSubject: string): Promise<ClientContact[]> {
        return this.request<{data: ClientContact[]}>('GET', `client/${clientSubject}/client_contacts`)
            .then(this.unpackDataCollectionResponse)
    }

    /**
     * Invite a new network member
     */
    public invite(firstName: string, lastName: string, email: string, relationTypeId: number, gender: string): Promise<void> {
        return this.request('POST', 'client_contact',{
            first_name: firstName,
            last_name: lastName,
            email: email,
            relation_type_id: relationTypeId,
            gender: gender,
        }).then(() => {})
    }

    /**
     * Validate invite
     */
    public validateInvite(token: string): Promise<{token: boolean, user_enabled: boolean}> {
        return this.unauthorizedRequest('POST', 'public/client_contact/validate_invite', {
            token,
        })
    }

    /**
     * Accept invite
     */
    public acceptInvite(token: string, password?: string): Promise<void> {
        return this.unauthorizedRequest('POST', 'public/client_contact/accept_invite', {
            token,
            password,
        }).then(() => {})
    }

    /**
     * List permissions for network member.
     */
    public memberPermissions(memberSubject: string): Promise<Array<PermissionSet>> {
        return this.request<{data: {[id: number]: PermissionSet}}>('GET', `client_contact/${memberSubject}/permissions`)
            .then(this.unpackDataCollectionResponse)
    }

    /**
     * Modify permissions for network member.
     */
    public updateMemberPermissions(memberSubject: string, permissions: PermissionSet): Promise<void> {
        return this.request('PUT', `client_contact/${memberSubject}/permissions`, permissions)
    }

    /**
     * List available relation types
     */
    public relationTypes(): Promise<Array<RelationType>> {
        return this.request<{data: {[id: number]: RelationType}}>('GET', `client_contact_relation_types`)
            .then(this.unpackDataCollectionResponse)
    }

    public addClientContactForClient(relationTypeId: number, firstName: string, lastName: string, gender: string, clientId: number, phoneNumber: string) {
        return this.request<{data: ClientContactDetails}>('POST', 'telephone/client_contact', {
            relation_type_id: relationTypeId,
            first_name: firstName,
            last_name: lastName,
            gender: gender,
            client_id: clientId,
            telephone: phoneNumber,
        }).then((r) => r.data)
    }
}
