import { Team } from "@jane/lib/src/api/repositories/teams-repository"
import {useCallback, useMemo} from "react"

export function useTeamMap(teams: Team[], primaryTeam: Team|null, secondaryTeams: Team[]): Team[][] {
    const getParents = useCallback((team: Team): Team[] => {
        if (team.parentObjectId !== null) {
            const parent = teams.find(x => x.id === team.parentObjectId)
            if (parent !== undefined) {
                return [...getParents(parent), team]
            }
        }
        return [team]
    }, [teams])

    return useMemo(
        () => teams
            .map(team => getParents(team))
            .filter(teams => {
                if (teams.some(team => team.id === primaryTeam?.id)) {
                    return true
                }
                return secondaryTeams.some(st => st.id === teams.slice(-1)[0].id)
            })
            .sort((a, b) => {
                const pathA = a.map(t => t.id.toString().padStart(4, '0')).join('.')
                const pathB = b.map(t => t.id.toString().padStart(4, '0')).join('.')
                return pathA > pathB ? 1 : -1
            }),
        [teams, getParents, primaryTeam?.id, secondaryTeams]
    )
}
export function getRelatedTeamIds(team: Team|undefined, teamMap: Team[][]): number[] {
    const teamHeirarchies = teamMap
        .filter(teams => team === undefined || teams.some(x => x.id === team.id))
    let results = new Set<number>()
    for (const teamHeirarchy of teamHeirarchies) {
        let hasFoundTeam = false
        for (const teamResult of teamHeirarchy) {
            if (teamResult.id === team?.id) {
                hasFoundTeam = true
            }
            if (hasFoundTeam || team === undefined) {
                results.add(teamResult.id)
            }
        }
    }

    return Array.from(results)
}
