import React, {FC} from "react";
import {usePersistentState} from "../../util/usePersistentState";

export const SplashScreen: FC<{children?: React.ReactNode}> = (props) => {
  const [isCollapsed] = usePersistentState<boolean>('collapse_sidebar', false)
  return <div className={"flex flex-col min-h-screen bg-brand-surface text-brand-on-surface"}>
    <nav className={"bg-brand-primary h-20 flex items-stretch justify-between"}></nav>
    <div className={"flex-1 flex items-stretch"}>
      <aside
        className={`bg-white ${isCollapsed ? 'w-16' : 'w-48'} border-r border-slate-200 flex flex-col justify-between pt-8`}>
        <div className={'flex flex-col'}>
          <div className={`h-12 flex items-center px-5 font-medium`}>
              <span className={`w-5 text-center ${isCollapsed ? '' : 'mr-3'}`}>
                <span className={"block h-4 w-4 rounded-full bg-brand-primary-container"}></span>
              </span>
            {!isCollapsed && <span className={"block h-4 w-16 rounded-full bg-brand-primary-container"}></span>}
          </div>
          <div className={`h-12 flex items-center px-5 font-medium`}>
              <span className={`w-5 text-center ${isCollapsed ? '' : 'mr-3'}`}>
                <span className={"block h-4 w-4 rounded-full bg-brand-primary-container"}></span>
              </span>
            {!isCollapsed && <span className={"block h-4 w-24 rounded-full bg-brand-primary-container"}></span>}
          </div>
          <div className={`h-12 flex items-center px-5 font-medium`}>
              <span className={`w-5 text-center ${isCollapsed ? '' : 'mr-3'}`}>
                <span className={"block h-4 w-4 rounded-full bg-brand-primary-container"}></span>
              </span>
            {!isCollapsed && <span className={"block h-4 w-20 rounded-full bg-brand-primary-container"}></span>}
          </div>
        </div>
      </aside>
      <main className={"flex-1"}>
        {props.children}
      </main>
    </div>
  </div>
}