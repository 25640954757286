import React from "react";
import {ActiveChat} from "./active-chat";
import {ChatSidebar} from "./sidebar";
import {ChatContextProvider} from "./provider";
import {Employee, Team} from "../api";
import {ConfigInterface} from "../config";

const ChatView: React.FC<{rocketChatHostname: string, employees: Employee[], teams: Team[], config: ConfigInterface}> = (props) => {
    return <div className={"max-w-screen-2xl p-4 mx-auto -mb-64"}>
        <div className={"flex bg-white rounded-xl shadow-lg"} style={{height: "calc(100vh - 14em)"}}>
            <ChatContextProvider
                rocketChatWebsocketUrl={`wss://${props.rocketChatHostname}/websocket`}
                employees={props.employees}
                teams={props.teams}
                config={props.config}
            >
                <ChatSidebar config={props.config} />
                <ActiveChat />
            </ChatContextProvider>
        </div>
    </div>
}
export default ChatView
