import {Call} from "./Call";

export function parseBroadsoftMessage(m: any): Call|null {
  try {
    if (m['type'] === 'event') {
      const id = m.payload['xsi:eventID']?.[0]
      const data = m.payload['xsi:eventData']?.[0] ?? {}
      const event = data['$']?.['xsi1:type']?.split(':')?.[1]
      switch(event) {
        case 'CallReceivedEvent':
          if (data['xsi:call']?.[0]?.['xsi:remoteParty']?.[0]?.['xsi:address']?.[0]?._ === undefined && data['xsi:call']?.[0]?.['xsi:remoteParty']?.[0]?.['xsi:privacy'] === undefined) {
            console.warn('CallReceivedEvent without number, ignoring...', data)
            return null
          }
          if (data['xsi:call']?.[0]?.['xsi:personality']?.[0] !== 'Terminator') {
            console.warn('CallReceivedEvent with personality not Terminator, ignoring...', data)
            return null
          }
          return {
            id,
            callId: data['xsi:call']?.[0]?.['xsi:callId']?.[0],
            type: 'ring',
            number: data['xsi:call']?.[0]?.['xsi:remoteParty']?.[0]?.['xsi:privacy'] === undefined ? {
              countryCode: data['xsi:call']?.[0]?.['xsi:remoteParty']?.[0]?.['xsi:address']?.[0]?.$?.countryCode,
              number: data['xsi:call']?.[0]?.['xsi:remoteParty']?.[0]?.['xsi:address']?.[0]?._,
              name: data['xsi:call']?.[0]?.['xsi:remoteParty']?.[0]?.['xsi:name']?.[0],
            } : "ANONYMOUS",
            startTime: new Date(Number(data['xsi:call']?.[0]?.['xsi:startTime']?.[0])),
          }
        case 'CallAnsweredEvent':
          if (data['xsi:call']?.[0]?.['xsi:remoteParty']?.[0]?.['xsi:address']?.[0]?._ === undefined && data['xsi:call']?.[0]?.['xsi:remoteParty']?.[0]?.['xsi:privacy'] === undefined) {
            console.warn('CallAnsweredEvent without number, ignoring...', data)
            return null
          }
          if (data['xsi:call']?.[0]?.['xsi:personality']?.[0] !== 'Terminator') {
            console.warn('CallReceivedEvent with personality not Terminator, ignoring...', data)
            return null
          }
          return {
            id,
            callId: data['xsi:call']?.[0]?.['xsi:callId']?.[0],
            type: 'answer',
            number: data['xsi:call']?.[0]?.['xsi:remoteParty']?.[0]?.['xsi:privacy'] === undefined ? {
              countryCode: data['xsi:call']?.[0]?.['xsi:remoteParty']?.[0]?.['xsi:address']?.[0]?.$?.countryCode,
              number: data['xsi:call']?.[0]?.['xsi:remoteParty']?.[0]?.['xsi:address']?.[0]?._,
              name: data['xsi:call']?.[0]?.['xsi:remoteParty']?.[0]?.['xsi:name']?.[0],
            } : "ANONYMOUS",
            startTime: new Date(Number(data['xsi:call']?.[0]?.['xsi:startTime']?.[0])),
            answerTime: new Date(Number(data['xsi:call']?.[0]?.['xsi:answerTime']?.[0])),
          }
        case 'CallReleasedEvent':
          if (data['xsi:call']?.[0]?.['xsi:remoteParty']?.[0]?.['xsi:address']?.[0]?._ === undefined && data['xsi:call']?.[0]?.['xsi:remoteParty']?.[0]?.['xsi:privacy'] === undefined) {
            console.warn('CallReleasedEvent without number, ignoring...', data)
            return null
          }
          if (data['xsi:call']?.[0]?.['xsi:personality']?.[0] !== 'Terminator') {
            console.warn('CallReceivedEvent with personality not Terminator, ignoring...', data)
            return null
          }
          return {
            id,
            callId: data['xsi:call']?.[0]?.['xsi:callId']?.[0],
            type: 'release',
            number: data['xsi:call']?.[0]?.['xsi:remoteParty']?.[0]?.['xsi:privacy'] === undefined ? {
              countryCode: data['xsi:call']?.[0]?.['xsi:remoteParty']?.[0]?.['xsi:address']?.[0]?.$?.countryCode,
              number: data['xsi:call']?.[0]?.['xsi:remoteParty']?.[0]?.['xsi:address']?.[0]?._,
              name: data['xsi:call']?.[0]?.['xsi:remoteParty']?.[0]?.['xsi:name']?.[0],
            } : "ANONYMOUS",
            startTime: new Date(Number(data['xsi:call']?.[0]?.['xsi:startTime']?.[0])),
            releaseTime: new Date(Number(data['xsi:call']?.[0]?.['xsi:releaseTime']?.[0])),
          }
        case 'CallRedirectedEvent':
          if (data['xsi:calls']?.[0]?.['xsi:call']?.[0]?.['xsi:remoteParty']?.[0]?.['xsi:address']?.[0]?._ === undefined) {
            console.warn('CallRedirectedEvent without number, ignoring...', data)
            return null
          }
          if (data['xsi:calls']?.[0]?.['xsi:call']?.[0]?.['xsi:personality']?.[0] !== 'Terminator') {
            console.warn('CallReceivedEvent with personality not Terminator, ignoring...', data)
            return null
          }
          return {
            id,
            callId: data['xsi:calls']?.[0]?.['xsi:call']?.[0]?.['xsi:callId']?.[0],
            type: 'redirect',
            number: {
              countryCode: data['xsi:calls']?.[0]?.['xsi:call']?.[0]?.['xsi:remoteParty']?.[0]?.['xsi:address']?.[0]?.$?.countryCode,
              number: data['xsi:calls']?.[0]?.['xsi:call']?.[0]?.['xsi:remoteParty']?.[0]?.['xsi:address']?.[0]?._,
              name: data['xsi:calls']?.[0]?.['xsi:call']?.[0]?.['xsi:remoteParty']?.[0]?.['xsi:name']?.[0],
            },
            startTime: new Date(Number(data['xsi:calls']?.[0]?.['xsi:call']?.[0]?.['xsi:startTime']?.[0])),
          }
        case 'CallSubscriptionEvent':
          return null
        default:
          console.log('ik snap het niet', id, event, data)
          return null
      }
    }
  } catch (e) {
    console.error('Error parsing message', m, e);
  }
    return null
}
