import {AbstractRepository} from "../repository";

export type SettingMap = {[setting: string]: string|number|boolean}

export default class SettingsRepository extends AbstractRepository {
    /**
     * List settings for a Jane instance
     */
    public all(): Promise<SettingMap> {
        return this.request<{data: SettingMap}>('GET', 'settings')
            .then(json => json.data)
    }

    /**
     * Update all settings for a Jane instance
     */
    public update(settings: SettingMap): Promise<SettingMap> {
        return this.request<{data: SettingMap}>('PATCH', 'settings', settings)
            .then(json => json.data)
    }
}
