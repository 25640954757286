import React, {useCallback, useEffect, useMemo, useState} from "react";
import useWebSocket, {ReadyState} from "react-use-websocket";
import {WebSocketMessage} from "react-use-websocket/dist/lib/types";
import {ChatRoomInfo, ChatRoomInfoMember, Employee, Team, useApiCall, useApiEndpoint} from "../api";
import {useAuth} from "../auth/hook";
import {ParsedToken} from "../auth/types";
import {ConfigInterface} from "../config";


export type RequestChatTokenResponse = {token?: string, userId?: string, error?: string}
interface RocketChatLoginResponse {
  status: "success"
  data: {
    userId: string
    authToken: string
    me: {
      _id: string
      username: string
      status: string
      statusConnection: string
      active: boolean
      name: string
      email: string
      emails: string[]
      services: unknown
      roles: string[]
      avatarUrl: string
      settings: {
        [category: string]: {
          [setting: string]: unknown
        }
      }
    }
  }
}
export interface ChatConversation {
    id: string
    name: string
    timestamp: Date
}
export interface ChatUser {
    id: string
    name: string
    role: string
    avatar: string|null
}
export interface ChatMessage {
    id: string
    conversationId: string
    userId: string
    message: string
    read: boolean
    timestamp: Date
    file?: {
        _id: string
        type: string
        name: string
    }
    attachments?: Array<{
        image_dimensions: {width: number, height: number},
        image_url: string
        image_type: string
        image_size: number
    }|{
        title: string
        title_link: string
        type: string
    }>
}
interface ChatContextType {
    connected: boolean
    loaded: boolean
    conversations: ChatConversation[]
    users: ChatUser[]
    chat?: ChatConversation&ChatRoomInfo
    messages: ChatMessage[]
    currentConversationId: string|null
    currentChatMembers: ChatRoomInfoMember[]
    selectConversation: (conversationId: string) => void
    sendMessage: (conversationId: string, message: string) => void
    canAddChats: boolean
    employees: Employee[]
    teams: Team[]
    formatMediaUrl: (path: string) => string
    loadChatRooms: (rooms: ChatRoomInfo[]) => void
    loadMoreHistory: (conversationId: string, limit: number) => Promise<void>
    hasUnloadedHistory: (conversationId: string) => boolean
    deleteMessage: (messageId: string) => void
    sendAttachment: (conversationId: string, file: File) => Promise<void>
}
const ChatContext = React.createContext<ChatContextType>({} as ChatContextType)

export function ChatContextProvider({ children, rocketChatWebsocketUrl, config, employees, teams }:{children: React.ReactNode, config: ConfigInterface, rocketChatWebsocketUrl: string, employees: Employee[], teams: Team[]}): JSX.Element {
    const [currentConversationId, setCurrentConversationId] = React.useState<string|null>(null)
    const { token, user, hasRoles } = useAuth()

    /**
     * Browser notifications
     */
    const [isTabFocussed, setIsTabFocussed] = useState(true)
    const [hasNotificationPermissions, setHasNotificationPermissions] = useState(false)
    useEffect(() => {
        if (window) {
            window.addEventListener('visibilitychange', (e) => {
                const doc = e.target as {hidden: boolean}|null
                setIsTabFocussed(doc?.hidden === false)
            })
            if ('Notification' in window) {
                if (Notification.permission !== 'denied' && Notification.permission !== 'granted') {
                    Notification.requestPermission().then((result) => {
                        if (result === 'granted') {
                            setHasNotificationPermissions(true)
                        }
                    })
                } else {
                    setHasNotificationPermissions(Notification.permission === 'granted')
                }
            }
        }
    }, [])
    const onNewMessage = useCallback((message: ChatMessage) => {
        if (hasNotificationPermissions && !isTabFocussed && message.userId !== user?.sub) {
            new Notification('Nieuw chatbericht in Miep', {body: message.message})
        }
    }, [hasNotificationPermissions, isTabFocussed, user])

    const { conversations: rawConversations, messages, sendAttachment, sendChatMessage, connected, loaded, refreshRooms, loadMoreHistory, hasUnloadedHistory, deleteMessage, formatMediaUrl } = useRocketChat(rocketChatWebsocketUrl, user, token, onNewMessage)
    const { chatRooms: chatRoomsFromApi } = useChatRoomsInfo(config)
    const [chatRooms, setChatRooms] = useState<ChatRoomInfo[]>([])
    useEffect(() => {
        if (chatRooms.length === 0 && chatRoomsFromApi.length > 0) {
            setChatRooms(chatRoomsFromApi)
        }
    }, [chatRoomsFromApi, chatRooms])
    const canAddChats = useMemo(() => hasRoles(['manager', 'coordinator']), [hasRoles])
    const conversations = useMemo<ChatConversation[]>(() => {
        const chatRoomIds = chatRooms.map(r => r.id)
        return rawConversations.filter(c => chatRoomIds.includes(c.id))
            .map(c => {
                const room = chatRooms.find(r => r.id === c.id)
                if (room!.type === "network") {
                    c.name = "Mijn netwerk"
                } else {
                    c.name = room!.name ?? `Fout: ${room!.id}`
                }
                return c
            })
    }, [rawConversations, chatRooms])

    const chat = useMemo(() => {
        const conversation = conversations.find(c => c.id === currentConversationId)
        const chatRoom = chatRooms.find(r => r.id === currentConversationId)
        if (! chatRoom|| !conversation) {
            return;
        }
        return {
            ...chatRoom,
            ...conversation,
        }
    }, [currentConversationId,conversations,chatRooms])

    const createAvatarUrl = useCallback((member: ChatRoomInfoMember): string|null => {
        return member.employee_picture_url === null
            ? null
            :`${member.employee_picture_url}&accessToken=${token}`
    }, [token])

    const users = useMemo<ChatUser[]>(() => {
        const userMap: {[sub: string]: ChatUser} = {}
        chatRooms.forEach(r => {
            r.members.forEach((m) => {
                userMap[m.sub] = {
                    id: m.sub,
                    name: m.name,
                    role: m.role,
                    avatar: createAvatarUrl(m),
                }
            })
        })
        return Object.values(userMap)
    }, [chatRooms, createAvatarUrl])

    const currentChatMembers: ChatRoomInfoMember[] = useMemo(() => {
        const currentConversation = chatRooms.find(c => c.id === currentConversationId)
        return (currentConversation?.members || []).map(m => {
            return {
                ...m,
                employee_picture_url: createAvatarUrl(m),
            }
        })
    }, [chatRooms, users, currentConversationId, createAvatarUrl])

    useEffect(() => {
        if (conversations.length > 0 && currentConversationId === null) {
            setCurrentConversationId(conversations[0].id)
        }
    }, [currentConversationId, setCurrentConversationId, conversations])


    return <ChatContext.Provider value={{
        connected,
        loaded,
        conversations,
        users,
        chat,
        messages,
        currentConversationId,
        currentChatMembers,
        formatMediaUrl,
        selectConversation: (conversationId: string) => setCurrentConversationId(conversationId),
        sendMessage: sendChatMessage,
        canAddChats,
        employees,
        teams,
        loadMoreHistory,
        hasUnloadedHistory,
        loadChatRooms: rooms => {
            setChatRooms(rooms)
            refreshRooms()
        },
        sendAttachment,
        deleteMessage,
    }}>
        {children}
    </ChatContext.Provider>
}
export function useChatContext(): ChatContextType {
    return React.useContext(ChatContext)
}


interface RocketChatHook { conversations: ChatConversation[], messages: ChatMessage[], refreshRooms: () => void, formatMediaUrl: (path: string) => string, sendAttachment: (conversationId: string, file: File) => Promise<void>, sendChatMessage: (conversationId: string, message: string) => void, loadMoreHistory: (conversationId: string, limit: number) => Promise<void>, hasUnloadedHistory: (conversationId: string) => boolean, deleteMessage: (messageId: string) => void, connected: boolean, loaded: boolean }
const useRocketChat = (rocketChatWebsocketUrl: string, user?: ParsedToken, accessToken?: string, onNewMessage?: (message: ChatMessage) => void): RocketChatHook  => {

    const {chatToken, userId: rcUserId} = useChatToken(user, accessToken)

    const { sendMessage, lastMessage, readyState } = useWebSocket(rocketChatWebsocketUrl)
    const [receivedMessages, setReceivedMessages] = useState<MessageEvent[]>([])
    const [, setSentMessages] = useState<WebSocketMessage[]>([])

    const formatMediaUrl = useCallback((path: string) => {
        if (chatToken === undefined || rcUserId === undefined || rocketChatWebsocketUrl === undefined) {
            return '#'
        }
        const mediaUrl = new URL(path, rocketChatWebsocketUrl)
        mediaUrl.protocol = 'https:'
        mediaUrl.searchParams.set('rc_uid', rcUserId)
        mediaUrl.searchParams.set('rc_token', chatToken)
        return mediaUrl.toString()
    }, [rocketChatWebsocketUrl, chatToken, rcUserId])

    const sendAttachment = useCallback(async (conversationId: string, attachment: File) => {
        if (chatToken === undefined || rcUserId === undefined || rocketChatWebsocketUrl === undefined) {
            return
        }

        const endpoint = new URL(`/api/v1/rooms.upload/${conversationId}`, rocketChatWebsocketUrl)
        endpoint.protocol = 'https:'
        const form = new FormData()
        form.append('file', attachment)
        await fetch(endpoint.toString(), {
            method: 'POST',
            headers: {
                'X-Auth-Token': chatToken,
                'X-User-Id': rcUserId,
            },
            body: form,
        })
    }, [rocketChatWebsocketUrl, chatToken, rcUserId])

    const send = useCallback((message: any) => {
        const msg = JSON.stringify(message)
        setSentMessages(m => [...m, msg])
        sendMessage(msg)
    }, [sendMessage])

    // When the connection is established, we need to send a "connect" message
    const [sessionId, setSessionId] = React.useState<string>()
    useEffect(() => {
        if (readyState === ReadyState.OPEN && !sessionId) {
            send({
                msg: "connect",
                version: "1",
                support: ["1"],
            })
        }
    }, [readyState, send, sessionId])

    interface MethodRequest { id: string, callback: (response: any) => void}
    const [methodCalls, setMethodCalls] = React.useState<MethodRequest[]>([])
    const callMethod = useCallback((method: string, params: any, callback: (response: any) => void) => {
        const id = generateMessageId()
        setMethodCalls(methodCalls => [...methodCalls, {id, callback}])
        send({
            msg: "method",
            id,
            method,
            params,
        })
    }, [setMethodCalls, send])
    // @ts-ignore
    const handleResult = useCallback((result) => {
        const {id, result: response} = result
        const call = methodCalls.find(call => call.id === id)
        if (call) {
            call.callback(response)
            setMethodCalls(calls => calls.filter(call => call.id !== id))
        } else {
            console.warn('Result for unknown request: ', result)
        }
    }, [methodCalls, setMethodCalls])

    // When a session exists, we need to authenticate the session using our chatToken
    const [userId, setUserId] = React.useState<string>()
    const hasLoggedIn = !! userId
    useEffect(() => {
        if (sessionId && !hasLoggedIn && chatToken) {
            callMethod("login", [{ "resume": chatToken }], (response) => {
                setUserId(response.id)
            })

        }
    }, [sessionId, callMethod, chatToken])

    // Fetch all rooms
    const [hasRequestedRooms, setHasRequestedRooms] = React.useState<boolean>(false)

    const [rooms, setRooms] = React.useState<(ChatConversation)[]>()
    useEffect(() => {
        if (! hasRequestedRooms && hasLoggedIn) {
            callMethod("rooms/get", [{"$date": 0}], (response) => {
                const updatedRooms: {_id: string, t: string, ts: {$date: number}, name?: string}[] = response.update || []
                const removedRooms: string[] = response.remove || []
                setRooms((currentRooms) => {
                    currentRooms = currentRooms || []
                    // Remove updated rooms from original array
                    currentRooms = currentRooms.filter(room => !updatedRooms.find(r => r._id === room.id))
                    // Remove deleted rooms from original array
                    currentRooms = currentRooms.filter(room => !removedRooms.includes(room.id))
                    // Add updated rooms
                    updatedRooms.forEach(room => {
                        let roomName = !!room.name ? room.name.replaceAll('_', ' ') : 'NO RCHAT NAME'
                        roomName = roomName.includes('.') ? roomName.split('.')[1] : roomName
                        if (room.t === 'p') {
                            currentRooms?.push({
                                id: room._id,
                                name: roomName,
                                timestamp: new Date(room.ts.$date),
                            })
                        }
                    })
                    return currentRooms
                })
            })

            setHasRequestedRooms(true)
        }
    }, [hasRequestedRooms, hasLoggedIn, send])

    // When authenticated, subscribe to all user notifications
    const [eventSubscriptionId, setEventSubscriptionId] = React.useState<string>()
    useEffect(() => {
        if (! eventSubscriptionId && hasLoggedIn) {
            const id = `${new Date().getTime()}`
            send({
                msg: "sub",
                id,
                name: "stream-room-messages",
                params: ["__my_messages__", false]
            })
            setEventSubscriptionId(id)
        }
    }, [send, eventSubscriptionId, hasLoggedIn])


    const [chatHistory, setChatHistory] = useState<ChatMessage[]>([])

    // @ts-ignore
    const handleNewMessage = useCallback((messageUpdate) => {
        const [message] = messageUpdate.args
        if ('t' in message) {
            // Do not handle any new system announcements
            return
        }
        const {rid, msg, _id, ts, u, file, attachments} = message
        const messageObject = {
            id: _id,
            conversationId: rid,
            userId: u.username,
            message: msg,
            attachments,
            file,
            timestamp: new Date(ts.$date),
            read: false,
        }
        setChatHistory(h => [...h, messageObject])
        if (onNewMessage) {
            onNewMessage(messageObject)
        }
    }, [rooms, onNewMessage])

    // @ts-ignore
    const handleRoomUpdate = useCallback((messageUpdate) => {
        if (messageUpdate.eventName.match(/\/deleteMessage$/)) {
            const [message] = messageUpdate.args
            setChatHistory(messages => messages.filter(m => m.id !== message._id))
        }
    }, [])

    // When a new message arrives, handle it.

    useEffect(() => {
        if (lastMessage && receivedMessages[receivedMessages.length - 1] !== lastMessage) {
            setReceivedMessages(m => [...m, lastMessage])
            const message = JSON.parse(lastMessage.data)
            switch (message.msg) {
                case "connected":
                    setSessionId(message.session)
                    break
                case "ping":
                    send({msg: "pong"})
                    break
                case "result":
                    handleResult(message)
                    break;
                case "changed":
                    switch (message.collection) {
                        case "stream-room-messages":
                            handleNewMessage(message.fields)
                            break;
                        case "stream-notify-room":
                            handleRoomUpdate(message.fields)
                            break;
                        default:
                            console.warn('Unknown change collection: ', message)
                    }
                    break;
                case "error":
                    switch (message.reason) {
                        case "Must connect first":
                            setSessionId(undefined)
                            break;
                        case "Already connected":
                            break;
                        default:
                            console.error('Unknown error: ', message)
                    }
                    break;
                default:
                    console.warn("Unknown message: ", message)
            }

        }
    }, [lastMessage, handleResult, receivedMessages])

    /** Loads room history for a period, returns the oldest message it has found (if any) */
    const loadHistory = useCallback((roomId: string, limit = 100, fromDate?: Date, toDate?: Date): Promise<ChatMessage|null> => {
        const fromDateParam = fromDate ? {"$date": fromDate.getTime()} : null
        const toDateParam = toDate ? {"$date": toDate.getTime()} : {"$date": new Date().getTime()}
        return new Promise((resolve) => {
            callMethod("loadHistory", [ roomId, fromDateParam, limit, toDateParam], (response) => {
                const messages: ChatMessage[] = (response.messages || [])
                    .map((m: any) => {
                        const {_id, msg, ts, t, u, rid, attachments, file} = m
                        return {
                            id: _id,
                            conversationId: rid,
                            userId: u.username,
                            message: msg,
                            type: t,
                            attachments,
                            file,
                            timestamp: new Date(ts.$date),
                            read: false,
                        }
                    })
                const oldestMessage = messages.reduce<ChatMessage|null>((previous, message) => {
                    if (previous === null) {
                        return message
                    }
                    return (previous.timestamp.getTime() > message.timestamp.getTime()) ? message : previous
                }, null)
                setChatHistory(h => [...h, ...messages.filter((m: any) => ! (m.type !== undefined))])
                resolve(oldestMessage)
            })
        })
    }, [])

    // When the rooms are loaded, fetch the history for all the rooms
    const [hasFetchedHistory, setHasFetchedHistory] = React.useState<boolean>(false)
    // Used for fetching more history, defines where in time we are with fetching
    const [roomTimeCursors, setRoomTimeCursors] = React.useState<{[roomId: string]: Date}>({})
    useEffect(() => {
        if (! hasFetchedHistory && rooms !== undefined) {
            const promises = rooms.map(room => {
                return loadHistory(room.id, 300)
            })
            setHasFetchedHistory(true)
            // Register the timestamps of the oldest message from each room.
            Promise.all(promises).then((loadHistoryRequests) => {
                setRoomTimeCursors((oldState) => {
                    let newState = {...oldState}
                    loadHistoryRequests.forEach((message) => {
                        if (message) {
                            newState[message.conversationId] = message.timestamp
                        }
                    })
                    return newState
                })
            })
        }
    }, [hasFetchedHistory, rooms])

    const [messageDeleteSubscriptionIds, setMessageDeletedSubscriptionIds] = React.useState<string[]>([])
    useEffect(() => {
        const roomsWithoutSubscription = (rooms ?? []).filter(room => ! messageDeleteSubscriptionIds.includes(room.id))
        if (roomsWithoutSubscription.length > 0 && hasFetchedHistory) {
            const addedSubscriptions: string[] = roomsWithoutSubscription.map((room) => {
                const id = `${new Date().getTime()}del${room.id}`
                send({
                    msg: "sub",
                    id,
                    name: "stream-notify-room",
                    params: [`${room.id}/deleteMessage`, false]
                })
                return room.id
            })
            setMessageDeletedSubscriptionIds(old => [...old, ...addedSubscriptions])
        }
    }, [send, messageDeleteSubscriptionIds, rooms, hasFetchedHistory])

    const loadMoreHistory = useCallback(async (conversationId: string, limit = 300) => {
        const toTime = roomTimeCursors[conversationId] ?? new Date()
        const fromTime = new Date(toTime.getTime() - 30 * 24 * 3600 * 1000)
        const oldestMessage = await loadHistory(conversationId, limit, fromTime, toTime)
        setRoomTimeCursors((old) => {
            return {
                ...old,
                [conversationId]: oldestMessage?.timestamp ?? fromTime
            }
        })
    }, [roomTimeCursors])

    const hasUnloadedHistory = useCallback((conversationId: string): boolean => {
        const room = rooms?.find(r => r.id === conversationId)
        if (! room) {
            console.info('Room not found', {conversationId})
            return false
        }
        const timeCursor = roomTimeCursors[conversationId] ?? new Date()
        // Time cursor is before the room was created
        return room.timestamp.getTime() < timeCursor.getTime()
    }, [roomTimeCursors, rooms])

    // Send a chat-message
    const sendChatMessage = useCallback((conversationId: string, message: string) => {
        callMethod("sendMessage", [{
            rid: conversationId,
            msg: message,
            _id: generateMessageId() + "01",
        }], () => {})
    }, [callMethod])

    const deleteMessage = useCallback((messageId: string) => {
        callMethod('deleteMessage', [{_id: messageId}], () => {})
        setChatHistory(messages => messages.filter(m => m.id !== messageId))
    }, [callMethod])

    return {
        sendChatMessage: sendChatMessage,
        conversations: rooms || [],
        messages: chatHistory,
        refreshRooms: () => setHasRequestedRooms(false),
        loadMoreHistory,
        connected: readyState === ReadyState.OPEN && hasLoggedIn,
        loaded: hasFetchedHistory,
        hasUnloadedHistory,
        formatMediaUrl,
        deleteMessage,
        sendAttachment,
    }
}

const useChatToken = (user?: ParsedToken, accessToken?: string): {chatToken?: string, userId?: string} => {
    const [chatToken, setChatToken] = React.useState<string>()
    const [userId, setUserId] = React.useState<string>()

    useEffect(() => {
        if (accessToken && ! chatToken) {
            fetch(`https://chat01.janehelpt.eu/api/v1/login`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                serviceName: 'keycloak',
                accessToken,
                expiresIn: 200,
              }),
            })
                .then(async (r): Promise<RocketChatLoginResponse> => await r.json())
                .then(r => {
                    const token = r.data.authToken
                    const userId = r.data.userId
                    if (!! token) {
                        setChatToken(token)
                    }
                    if (!! userId) {
                        setUserId(userId)
                    }
                })
                .catch(e => console.error(e))
        }
    }, [ user, accessToken, chatToken ])

    return {chatToken, userId}
}

function useChatRoomsInfo(config: ConfigInterface): {chatRooms: ChatRoomInfo[]} {
    const {chat} = useApiCall(config)
    const {resource} = useApiEndpoint(() => chat.all())

    return {chatRooms: resource ?? []}
}

function generateMessageId(): string {
    const seed = Math.floor(Math.random() * 1000).toString()
    return new Date().getTime().toString() + seed
}
