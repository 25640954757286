import {useApiCall, useApiEndpoint} from "@jane/lib/src/api"
import React, {useState} from "react"
import {config} from "../config";
import {useNavigate} from "react-router-dom";
import DataTable from "./data-table/data-table";
import {Loading} from "@jane/lib/src/components/Loading";
import {Card} from "@jane/lib/src/components/Card";
import {faRotate} from "@fortawesome/free-solid-svg-icons";
import {Button} from "@jane/lib/src/components/form/Button";

export default function SyncStatus(props: {
  objectId: number,
  type: "client" | "client_contact" | "employee"
}): JSX.Element {

  const {sync} = useApiCall(config)
  const {isLoading, resource: status, error} = useApiEndpoint(() => sync.status(props.type, props.objectId))
  const navigate = useNavigate()

  const [isSyncing, setIsSyncing] = useState(false)

  const startSync = async () => {
    setIsSyncing(true)
    await sync.sync(props.type === "employee" ? "employee" : "client", props.objectId)
    await new Promise(resolve => setTimeout(resolve, 10000))
    navigate(0)
  }

  if (error !== undefined) {
    return <div title={"Synchronisatie status"}>
      <p className={"text-danger"}>U heeft geen rechten om de synchronisatie status van dit persoon te bekijken.</p>
    </div>
  }

  return <Card title={'Synchronisatie status'} id={"sync"}>
    <div className={"mb-4"}>
      <Button onClick={startSync} icon={faRotate} disabled={isSyncing}
              text={isSyncing ? "Synchroniseren..." : "Start synchronisatie"} type={'primary'} size={'sm'}/>
    </div>
    <Loading loading={isLoading || !status}>
      {!!status && <>

        <DataTable
          keyProperty={'id'}
          data={status.map((v, k) => ({...v, id: k}))}
          columns={[
            {
              property: 'synced',
              header: '',
              transform: (v) => {
                if (v) {
                  return <div
                    className={"h-4 w-4 bg-green-600 flex items-center justify-center rounded-full text-white"}><i
                    className={"bi bi-check"}></i></div>
                } else {
                  return <div className={"h-4 w-4 bg-red-600 flex items-center justify-center rounded-full text-white"}>
                    <i className={"bi bi-x"}></i></div>
                }
              }
            },
            {
              property: 'updated_at',
              header: 'Laatst voorgekomen',
              transform: (v) => formatDateString(v),
            },
            {
              property: 'created_at',
              header: 'Eerst voorgekomen',
              transform: (v) => v ? formatDateString(v) : '-',
            },
            {
              property: 'reason',
              header: 'Reden',
              transform: (v) => <div className={"w-full text-black leading-relaxed"}>{v}</div>,
            },
          ]}
        />

      </>}
    </Loading>

  </Card>
}

function formatDateString(dateString: string): string {
  const date = new Date(dateString)
  const dateDateText = date.toLocaleDateString('nl-NL', {day: 'numeric', month: 'numeric', year: 'numeric'})
  const dateTimeText = date.toLocaleTimeString('nl-NL', {hour: 'numeric', hour12: false, minute: '2-digit'})
  return `${dateDateText} ${dateTimeText}`
}
