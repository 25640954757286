import {ApiError, useApiCall} from "@jane/lib/src/api"
import { Card } from "@jane/lib/src/components/Card";
import { Button } from "@jane/lib/src/components/form/Button";
import { Input } from "@jane/lib/src/components/form/Input";
import { useToasts } from "@jane/lib/src/toasts/toasts";
import React, {FormEvent, useState} from "react"
import {config} from "../config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWarning} from "@fortawesome/free-solid-svg-icons";

export default function PasswordResetForm(props: {subject: string}): JSX.Element {
    const [password, setPassword] = useState('')
    const [confirmation, setConfirmation] = useState('')
    const [temporary, setTemporary] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const { sync } = useApiCall(config)
    const { showToast } = useToasts()
    function save(event: FormEvent) {
        event.preventDefault()
        setError('')
        setIsLoading(true)
        sync.setSubjectPassword(props.subject, password, temporary)
            .then(() => {
                showToast('Wijzigingen opgeslagen', 'Het wachtwoord is opnieuw ingesteld')
                setPassword('')
                setConfirmation('')
            })
            .catch(err => {
                try {
                    if (err instanceof ApiError) {
                        setError((typeof err.errorBag === "string") ? err.errorBag : err.errorBag!.password?.join(','))
                    } else {
                        setError(String(err))
                    }
                } catch (e) {
                    setError(String(err))
                }
            })
            .finally(() => {
                setIsLoading(false)
            })

    }
    return <div className={"my-5"}>
        <Card title={"Wachtwoord resetten"} id={"password_reset"}>
            <form onSubmit={save} className={"space-y-4"}>
                <div>
                  <Input label={'Wachtwoord'} type={'password'} value={password} onChange={setPassword} />
                </div>
              <div>
                <Input label={'Wachtwoord (verificatie)'} type={'password'} value={confirmation} onChange={setConfirmation} />
              </div>
                <label className={"flex items-center rounded border border-brand-secondary px-4 py-3 my-3 cursor-pointer"}>
                    <input type={"checkbox"} className={"mr-4"} checked={temporary} onChange={e => setTemporary(e.target.checked)} />
                    <div>
                        <strong>Tijdelijk wachtwoord</strong>
                        <p className={"mb-0 mt-1"}>Dit wachtwoord kan slechts een keer gebruikt worden om in te loggen waarna de gebruiker direct wordt gevraagd om een nieuw wachtwoord in te stellen.</p>
                    </div>
                </label>
                {password !== confirmation && (password.length + confirmation.length) > 0 && <div className={"my-3 text-brand-error"}>
                    <FontAwesomeIcon icon={faWarning} className={"mr-2 text-brand-error"} /> <strong className={''}>De ingevoerde wachtwoorden komen niet overeen.</strong>
                </div>}
                {error !== undefined && error.length > 0 && <div className={"my-3 text-brand-error"}>
                    <FontAwesomeIcon icon={faWarning} className={"mr-2 text-brand-error"} /> <strong className={''}>Het ingevoerde wachtwoord voldoet niet aan de eisen: {error}</strong>
                </div>}
                <Button type={"primary"} disabled={ isLoading || ! password || ! confirmation || password !== confirmation } text={'Reset wachtwoord'} onClick={() => save({preventDefault: ()=>{}} as FormEvent)}  size={'sm'} />
                { error === "" ? "" : <>
                    <div className={"text-danger mt-2"}>{ error }</div>
                </>}
            </form>
        </Card>
    </div>
}
