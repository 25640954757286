import React, {Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState} from "react";
import {useApiCall, useApiEndpoint, Client, Employee} from "@jane/lib/src/api";
import { LogLevel, LogLine } from "@jane/lib/src/api/repositories/logs-repository"
import { ContentContainer } from "@jane/lib/src/components/content/ContentContainer"
import { PageHeader } from "@jane/lib/src/components/content/PageHeader";
import {config} from "../config";
import {useInterval} from "../util/useInterval";
import {useClientData} from "../resources/ClientContext";
import {useEmployeesData} from "../resources/EmployeeContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons"
import * as fapl from "@fortawesome/pro-light-svg-icons";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {useNavigate} from "react-router-dom";

interface LogViewerSettings {
  autoRefresh: boolean
  autoScroll: boolean
  order: 'asc' | 'desc'
  filtersEnabled: boolean,
  activeFilters: {
    [key: string]: string|string[]
  }
}

interface EnrichedLogLine extends LogLine {
  rowBg: string
  categoryVariant: {
    icon: IconDefinition
    text: string
  }
  userObject: Client|Employee|undefined
}

export default function Logs(): JSX.Element {
  // const { logs: logsApi } = useApiCall(config)
  // const { resource: logsResource, isLoading: loadingLogs } = useApiEndpoint(() => logsApi.get())

  const [settings, setSettings] = useState<LogViewerSettings>({
    autoRefresh: false,
    autoScroll: true,
    order: 'asc',
    filtersEnabled: false,
    activeFilters: {
      level: ['info', 'warn', 'error'],
      category: [],
    },
  })
  const {logs: logsRepository} = useApiCall(config)
  const [logs, setLogs] = useState<LogLine[]>([])
  const {clients} = useClientData()
  const {employees} = useEmployeesData()
  const topRef = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState(false)
  const bottomRef = useRef<HTMLDivElement>(null)
  console.log(logs)

  const peopleMap = useMemo(() => {
    return {
      client: clients.reduce((acc, client) => {
        acc[client.id] = client
        return acc
      }, {} as {[onsId: number]: Client}),
      employee: employees.reduce((acc, employee) => {
        acc[employee.id] = employee
        return acc
      }, {} as {[onsId: number]: Employee}),
    }
  }, [clients, employees])

  const enrichedLogs = useMemo(() => {
    return logs
      .sort((a, b) => settings.order === 'asc' ? Number(a.id) - Number(b.id) : Number(b.id) - Number(a.id))
      .map((log): {
        level: LogLevel;
        rowBg: string;
        categoryVariant: { icon: IconDefinition; text: string };
        context: object | null;
        created_at: string;
        id: string;
        userObject: any;
        category: string;
        message: string | null;
        user: string | null;
        request_id: string | null
      } => {
        const [userType, userId] = (log.user?.split(':') ?? []) as [string|undefined, string|undefined]
        return {
          ...log,
          rowBg: log.level === 'error' ? 'bg-red-50' : log.level === 'warn' ? 'bg-yellow-50' : 'bg-white',
          categoryVariant: getCategoryVariant(log.category),
          userObject: ['client','employee'].includes(userType ?? '') && userId !== undefined ? peopleMap[userType as 'client'|'employee']?.[Number(userId)] ?? undefined : undefined
        }
      })
  }, [logs, settings, peopleMap])

  async function fetchLogs (options: Parameters<typeof logsRepository.get>[0], settings: LogViewerSettings, topRef: React.RefObject<HTMLDivElement>, bottomRef: React.RefObject<HTMLDivElement>) {
    setLoading(true)
    await logsRepository.get(options)
      .then(({data}) => setLogs(data))
    if (settings.autoScroll) {
      if (settings.order === 'asc') {
        bottomRef.current?.scrollIntoView({behavior: 'smooth'})
      } else {
        topRef.current?.scrollIntoView({behavior: 'smooth'})
      }
    }
    setLoading(false)
  }
  useEffect(() => {
    fetchLogs(settings.filtersEnabled ? {...settings.activeFilters} : {}, settings, topRef, bottomRef)
  }, [settings, bottomRef])

  useInterval(() => {
    fetchLogs(settings.filtersEnabled ? {...settings.activeFilters} : {}, settings, topRef, bottomRef)
  }, settings.autoRefresh ? 2000 : null)


  return <ContentContainer size={'lg'}>
    <PageHeader>Logs</PageHeader>
    <div className={'absolute top-20 left-0 w-full h-[calc(100vh-5rem)] bg-white flex flex-col items-stretch'}>
      <LogViewerToolbar settings={settings} setSettings={setSettings} peopleMap={peopleMap}/>
      <div className={"bg-slate-100 h-12 flex items-center"}>
        <table>
          <thead>
          <tr>
            <th className={"w-32 font-medium text-sm uppercase px-3 py-2 text-slate-500"}>Level</th>
            <th className={"w-48 font-medium text-sm uppercase px-2 py-2 text-slate-500"}>Timestamp</th>
            <th className={"w-auto font-medium text-sm uppercase px-2 py-2 text-slate-500"}>Information</th>
          </tr>
          </thead>
        </table>
      </div>
      <div className={`flex-1 overflow-y-scroll bg-white`}>
        <div ref={topRef} id={"top"}/>
        {settings.order === 'desc' && <div className={"h-12 flex items-stretch"}>
          <div className={"flex items-center justify-start px-3"}>
            {settings.autoRefresh ? <>
              <FontAwesomeIcon className={"animate-spin text-xl"} icon={fa.faArrowsRotate} />
              <span className={"text-sm mx-3"}>
                            Automatisch verversen...
                        </span>
              <button className={"text-blue-600 text-sm font-medium hover:bg-blue-100 px-2 py-1 rounded"}
                      onClick={() => setSettings(old => ({...old, autoRefresh: false}))}>Stop met automatisch verversen
              </button>
            </> : <>
              {loading ? <>
                <FontAwesomeIcon className={"animate-spin text-xl"} icon={fa.faArrowsRotate} />
                <span className={"text-sm mx-3"}>
                                Ophalen...
                            </span>
              </> : <>
                <button className={"text-blue-600 text-sm font-medium hover:bg-blue-100 px-2 py-1 rounded"}
                        onClick={() => {
                          fetchLogs(settings.filtersEnabled ? {...settings.activeFilters} : {}, settings, topRef, bottomRef)
                        }}>Nieuwe logs ophalen
                </button>
              </>}
            </>}
          </div>
        </div>}
        <table className={"w-full"}>
          <tbody>
          {enrichedLogs.map((line, i) => <LogViewerLine line={line} key={i} setFilters={(setter) => {
            setSettings(old => ({...old, filtersEnabled: true, activeFilters: setter(old.activeFilters)}))
          }}/>)}
          </tbody>
        </table>
        {settings.order === 'asc' && <div className={"h-12 flex items-stretch"}>
          <div className={"flex items-center justify-start px-3"}>
            {settings.autoRefresh ? <>
              <FontAwesomeIcon className={"animate-spin text-xl"} icon={fa.faArrowsRotate} />
              <span className={"text-sm mx-3"}>
                            Automatisch verversen...
                        </span>
              <button className={"text-blue-600 text-sm font-medium hover:bg-blue-100 px-2 py-1 rounded"}
                      onClick={() => setSettings(old => ({...old, autoRefresh: false}))}>Stop met automatisch verversen
              </button>
            </> : <>
              {loading ? <>
                <FontAwesomeIcon className={"animate-spin text-xl"} icon={fa.faArrowsRotate} />
                <span className={"text-sm mx-3"}>
                                Ophalen...
                            </span>
              </> : <>
                <button className={"text-blue-600 text-sm font-medium hover:bg-blue-100 px-2 py-1 rounded"}
                        onClick={() => {
                          fetchLogs(settings.filtersEnabled ? {...settings.activeFilters} : {}, settings, topRef, bottomRef)
                        }}>Nieuwe logs ophalen
                </button>
              </>}
            </>}
          </div>
        </div>}
        <div ref={bottomRef} id={"bottom"}/>
      </div>
    </div>
  </ContentContainer>
}

const LogViewerToolbar: FC<{settings: LogViewerSettings, setSettings: Dispatch<SetStateAction<LogViewerSettings>>, peopleMap: {client: {[onsId: number]: Client}, employee: {[onsId: number]: Employee}}}> = props => {
  const navigate = useNavigate()
  return <div>
    <div className={"h-16 px-4 border-b border-slate-200 flex items-center justify-between"}>
      <div className={"space-x-8"}>
        <span className={"text-lg font-bold hover:bg-slate-100 px-3 py-2 rounded hover:cursor-pointer"} onClick={() => navigate('/settings')}><FontAwesomeIcon className={"mr-2"} icon={fa.faChevronLeft}/>Terug</span>
        <span className={"text-lg font-bold"}>Log viewer</span>
      </div>
      <div className={'flex items-center space-x-2'}>
        <button className={"px-3 h-10 border border-slate-100 hover:bg-slate-50 rounded-lg flex items-center space-x-2"} onClick={() => props.setSettings({...props.settings, filtersEnabled: !props.settings.filtersEnabled})}><FontAwesomeIcon icon={props.settings.filtersEnabled ? fa.faFilter : fapl.faFilter}/> <span className={"text-sm"}>{props.settings.filtersEnabled ? 'Filters: aan' : 'Filters: uit'}</span></button>
        {/*<button className={"px-3 h-10 border border-slate-100 hover:bg-slate-50 rounded-lg flex items-center space-x-2"} onClick={() => props.setSettings({...props.settings, filtersEnabled: !props.settings.filtersEnabled})}><FontAwesomeIcon icon={fa.faFilter}/> <span className={"text-sm"}>{props.settings.filtersEnabled ? 'Filters: aan' : 'Filters: uit'}</span></button>*/}
        <button className={"px-3 h-10 border border-slate-100 hover:bg-slate-50 rounded-lg flex items-center space-x-2"} onClick={() => props.setSettings({...props.settings, order: props.settings.order === "asc" ? 'desc' : 'asc'})}><FontAwesomeIcon icon={props.settings.order === 'asc' ? fa.faSortDown : fa.faSortUp} /> <span className={"text-sm"}>{props.settings.order === 'asc' ? 'Nieuwste onder' : 'Nieuwste boven'}</span></button>
        <button className={"px-3 h-10 border border-slate-100 hover:bg-slate-50 rounded-lg flex items-center space-x-2"} onClick={() => props.setSettings({...props.settings, autoRefresh: !props.settings.autoRefresh})}><FontAwesomeIcon icon={props.settings.autoRefresh ? fa.faToggleOn : fa.faToggleOff} /> <span className={"text-sm"}>{props.settings.autoRefresh ? 'Auto refresh' : 'Auto refresh'}</span></button>
      </div>
    </div>
    {props.settings.filtersEnabled ? <>
      <div className={"h-16 px-4 border-b border-slate-200 flex items-center justify-end space-x-2"}>
        <span className={"font-medium mr-2"}>Filters:</span>
        <RequestFilter settings={props.settings} setSettings={props.setSettings} />
        <PersonFilter settings={props.settings} setSettings={props.setSettings} peopleMap={props.peopleMap} />
        <SeverityFilter settings={props.settings} setSettings={props.setSettings} />
        <CategoryFilter settings={props.settings} setSettings={props.setSettings} />
        <button className={"w-10 h-10 hover:bg-slate-50 rounded-lg flex items-center justify-center"} onClick={() => props.setSettings({...props.settings, filtersEnabled: false})}><FontAwesomeIcon icon={fa.faX} className={'font-bold text-lg'} /></button>
      </div>
    </> : <></>}
  </div>
}

const RequestFilter: FC<{settings: LogViewerSettings, setSettings: Dispatch<SetStateAction<LogViewerSettings>>}> = props => {
  if (! props.settings.activeFilters.request_id) {
    return <></>
  }
  const type = (props.settings.activeFilters.request_id as string).startsWith('http') ? 'web' : 'cli'
  const shortId = (props.settings.activeFilters.request_id as string).split(':')?.[1]?.split('-')?.[0] ?? '?'
  return <button
    className={"px-3 h-10 border border-slate-100 hover:bg-slate-50 rounded-lg flex items-center space-x-2 text-sm"}
    onClick={() => props.setSettings(old => {
      return {...old, activeFilters: Object.fromEntries(Object.entries(old.activeFilters).filter(([k]) => k !== 'request_id'))}
    })}
  >
    <FontAwesomeIcon icon={fa.faX} className={`mr-2`} /> Alleen <div className={"border border-slate-200 rounded-full px-2"}>
    <span className={"font-medium"}>{type.toUpperCase()}</span> : <span className={"font-medium"}>{shortId}</span>
  </div>
  </button>
}

const PersonFilter: FC<{settings: LogViewerSettings, setSettings: Dispatch<SetStateAction<LogViewerSettings>>, peopleMap: {client: {[onsId: number]: Client}, employee: {[onsId: number]: Employee}}}> = props => {
  if (! props.settings.activeFilters.user) {
    return <></>
  }
  let userName: string|null
  let userType: string|null
  if ((props.settings.activeFilters.user as string).startsWith('client:')) {
    userType = 'C'
  }else if ((props.settings.activeFilters.user as string).startsWith('employee:')) {
    userType = 'E'
  } else {
    userType = '?'
  }
  const userObject = props.peopleMap[userType === 'C' ? 'client' : 'employee'][Number((props.settings.activeFilters.user as string).split(':')[1])]
  if (userObject?.name) {
    userName = userObject.name
  } else {
    userName = (props.settings.activeFilters.user as string).split(':')[1] ?? 'n/a'
  }
  return <button
    className={"px-3 h-10 border border-slate-100 hover:bg-slate-50 rounded-lg flex items-center space-x-2 text-sm"}
    onClick={() => props.setSettings(old => {
      return {...old, activeFilters: Object.fromEntries(Object.entries(old.activeFilters).filter(([k]) => k !== 'user'))}
    })}
  >
    <FontAwesomeIcon icon={fa.faX} className={`mr-2`} /> Alleen <div className={"border border-slate-200 rounded-full px-2"}>
    <span className={"font-medium"}>{userType.toUpperCase()}</span> : <span className={"font-medium"}>{userName}</span>
  </div>
  </button>
}

const SeverityFilter: FC<{settings: LogViewerSettings, setSettings: Dispatch<SetStateAction<LogViewerSettings>>}> = props => {
  const [activeSeverities, setActiveSeverities] = useState((props.settings.activeFilters.level ?? []) as string[])
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    setActiveSeverities((props.settings.activeFilters.level ?? []) as string[])
  }, [props.settings.activeFilters.level])

  const toggleSeverity = (severity: string) => {
    if (activeSeverities.includes(severity)) {
      setActiveSeverities(old => old.filter(l => l !== severity))
    } else {
      setActiveSeverities(old => ([...old, severity]))
    }
  }
  useEffect(() => {
    if (!expanded) {
      if (props.settings.activeFilters.level !== activeSeverities) {
        props.setSettings(old => ({...old, activeFilters: {...old.activeFilters, level: activeSeverities}}))
      }
    }
  }, [props.settings.activeFilters.level, activeSeverities, expanded])
  return <div className={"relative"}>
    <button
      className={"px-3 h-10 border border-slate-100 hover:bg-slate-50 rounded-lg flex items-center space-x-2 text-sm"}
      onClick={() => setExpanded(old => !old)}
    >
      <FontAwesomeIcon icon={fa.faBug} className={"mr-2"}></FontAwesomeIcon> Levels ({activeSeverities.length ?? 0})

    </button>
    {expanded && <>
      <div className={"fixed inset-0 bg-black opacity-10"} onClick={() => setExpanded(false)}></div>
      <div className={"absolute top-10 right-0 bg-white border border-slate-100 rounded-lg shadow text-slate-500 text-sm"}>
        <label className={`h-9 m-0 flex items-center px-3 hover:bg-slate-50 cursor-pointer ${activeSeverities.length === 0 && 'text-slate-700'}`}>
          <input type="checkbox" className={"h-3 w-3"} checked={activeSeverities.length === 0} onChange={() => setActiveSeverities([])} />
          <FontAwesomeIcon icon={fa.faAsterisk} className={"mx-2"}></FontAwesomeIcon>
          <span>Alles</span>
        </label>
        <label className={`h-9 m-0 flex items-center px-3 hover:bg-slate-50 cursor-pointer ${activeSeverities.includes('debug') && 'text-slate-700'}`}>
          <input type="checkbox" className={"h-3 w-3"} checked={activeSeverities.includes('debug')} onChange={() => toggleSeverity('debug')} />
          <FontAwesomeIcon icon={fa.faBug} className={"mx-2"}></FontAwesomeIcon>
          <span>Debug</span>
        </label>
        <label className={`h-9 m-0 flex items-center px-3 hover:bg-blue-50 cursor-pointer ${activeSeverities.includes('info') && 'text-blue-700'}`}>
          <input type="checkbox" className={"h-3 w-3"} checked={activeSeverities.includes('info')} onChange={() => toggleSeverity('info')} />
          <FontAwesomeIcon icon={fa.faInfoCircle} className={"mx-2"}></FontAwesomeIcon>
          <span>Info</span>
        </label>
        <label className={`h-9 m-0 flex items-center px-3 hover:bg-yellow-50 cursor-pointer ${activeSeverities.includes('warn') && 'text-yellow-700'}`}>
          <input type="checkbox" className={"h-3 w-3"} checked={activeSeverities.includes('warn')} onChange={() => toggleSeverity('warn')} />
          <FontAwesomeIcon icon={fa.faExclamation} className={"mx-2"}></FontAwesomeIcon>
          <span>Warning</span>
        </label>
        <label className={`h-9 m-0 flex items-center px-3 hover:bg-red-50 cursor-pointer ${activeSeverities.includes('error') && 'text-red-700'}`}>
          <input type="checkbox" className={"h-3 w-3"} checked={activeSeverities.includes('error')} onChange={() => toggleSeverity('error')} />
          <FontAwesomeIcon icon={fa.faXmarkCircle} className={"mx-2"}></FontAwesomeIcon>
          <span>Error</span>
        </label>
      </div>
    </>}
  </div>
}

const CategoryFilter: FC<{settings: LogViewerSettings, setSettings: Dispatch<SetStateAction<LogViewerSettings>>}> = props => {
  const [activeCategories, setActiveCategories] = useState((props.settings.activeFilters.category ?? []) as string[])
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    setActiveCategories((props.settings.activeFilters.category ?? []) as string[])
  }, [props.settings.activeFilters.category])

  const toggleCategory = (category: string) => {
    if (activeCategories.includes(category)) {
      setActiveCategories(old => old.filter(l => l !== category))
    } else {
      setActiveCategories(old => ([...old, category]))
    }
  }
  useEffect(() => {
    if (!expanded) {
      if (props.settings.activeFilters.category !== activeCategories) {
        props.setSettings(old => ({...old, activeFilters: {...old.activeFilters, category: activeCategories}}))
      }
    }
  }, [props.settings.activeFilters.category, activeCategories, expanded])
  return <div className={"relative"}>
    <button
      className={"px-3 h-10 border border-slate-100 hover:bg-slate-50 rounded-lg flex items-center space-x-2 text-sm"}
      onClick={() => setExpanded(old => !old)}
    >
      <FontAwesomeIcon icon={fa.faTag} className={"mr-2"}></FontAwesomeIcon> Categorieën ({activeCategories.length ?? 0})

    </button>
    {expanded && <>
      <div className={"fixed inset-0 bg-black opacity-10"} onClick={() => setExpanded(false)}></div>
      <div className={"absolute top-10 right-0 bg-white border border-slate-100 rounded-lg shadow text-slate-500 text-sm"}>
        <label className={`h-9 m-0 flex items-center px-3 hover:bg-slate-50 cursor-pointer ${activeCategories.length === 0 && 'text-slate-700'}`}>
          <input type="checkbox" className={"h-3 w-3"} checked={activeCategories.length === 0} onChange={() => setActiveCategories([])} />
          <FontAwesomeIcon icon={fa.faAsterisk} className={"mx-2"}></FontAwesomeIcon>
          <span>Alles</span>
        </label>
        {Object.entries(KNOWN_CATEGORIES).map(([key, value], i) => {
          return <label className={`h-9 m-0 flex items-center px-3 hover:bg-slate-50 cursor-pointer ${activeCategories.includes(key) && 'text-slate-700'}`} key={i}>
            <input type="checkbox" className={"h-3 w-3"} checked={activeCategories.includes(key)} onChange={() => toggleCategory(key)} />
            <FontAwesomeIcon icon={value.icon} className={`mx-2`}></FontAwesomeIcon>
            <span className={"whitespace-nowrap"}>{value.text}</span>
          </label>
        })}
      </div>
    </>}
  </div>
}

// eslint-disable-next-line no-unused-vars
const LogViewerLine: FC<{line: EnrichedLogLine, setFilters: (filters: (old: LogViewerSettings['activeFilters']) => LogViewerSettings['activeFilters']) => void}> = ({line, setFilters}) => {
  const [expanded, setExpanded] = useState(false)
  if (expanded) {
    return <>
      <tr>
        <td className={`w-32 px-2 py-2 ${line.rowBg}`}><LogViewerLineLevel level={line.level} /></td>
        <td className={`w-48 px-2 py-2 ${line.rowBg}`}><LogViewerLineDate date={line.created_at} /></td>
        <td className={`w-auto px-2 py-2 ${line.rowBg}`}>
          <div className={"flex items-center space-x-2"}>
            <LogViewerLineCategory line={line} />
            <LogViewerLineRequest line={line} onClick={() => setFilters(old => (line.request_id ? {...old, request_id: line.request_id} : old))} />
            <LogViewerLineUser line={line} onClick={() => setFilters(old => (line.user ? {...old, user: line.user} : old))} />
            <LogViewerLineMessage message={line.message} />
          </div>
        </td>
        <td className={`w-48 ${line.rowBg}`}>
          <div className={"flex justify-end mr-2"}>
            <button onClick={() => setExpanded(x => !x)} className={"h-8 px-2 mr-3 text-sm text-slate-600 hover:text-blue-600 rounded hover:bg-slate-100"}><FontAwesomeIcon icon={expanded ? fa.faChevronUp : fa.faChevronDown} className={`mr-2`}></FontAwesomeIcon> {expanded ? "Minder informatie" : "Meer informatie"}</button>
          </div>
        </td>
      </tr>
      <tr>
        <td colSpan={2} className={`px-2 py-2 border-b border-slate-100 ${line.rowBg}`}></td>
        <td colSpan={2} className={`px-2 py-2 border-b border-slate-100 ${line.rowBg}`}>
          <h4 className={"text-sm"}>Technische details</h4>
          <div className={"border border-slate-100 w-full rounded p-2"}>
            {Object.keys(line.context??{}).length > 0 ? <pre className={""}>{JSON.stringify(line.context, undefined, 2)}</pre> : <div className={"text-center py-3"}>Geen details beschikbaar</div>}
          </div>
        </td>
      </tr>
    </>
  }
  return <tr>
    <td className={`w-32 border-b border-slate-100 px-2 py-2 ${line.rowBg}`}><LogViewerLineLevel level={line.level} /></td>
    <td className={`w-48 border-b border-slate-100 px-2 py-2 ${line.rowBg}`}><LogViewerLineDate date={line.created_at} /></td>
    <td className={`w-auto border-b border-slate-100 px-2 py-2 ${line.rowBg}`}>
      <div className={"flex items-center space-x-2"}>
        <LogViewerLineCategory line={line} />
        <LogViewerLineRequest line={line} onClick={() => setFilters(old => (line.request_id ? {...old, request_id: line.request_id} : old))} />
        <LogViewerLineUser line={line} onClick={() => setFilters(old => (line.user ? {...old, user: line.user} : old))} />
        <LogViewerLineMessage message={line.message} />
      </div>
    </td>
    <td className={`w-48 border-b border-slate-100 ${line.rowBg}`}>
      <div className={"flex justify-end mr-2"}>
        <button onClick={() => setExpanded(x => !x)} className={"h-8 px-2 mr-3 text-sm text-slate-600 hover:text-blue-600 rounded hover:bg-slate-100"}><FontAwesomeIcon icon={expanded ? fa.faChevronUp : fa.faChevronDown} className={`mr-2`}/> {expanded ? "Minder informatie" : "Meer informatie"}</button>
      </div>
    </td>
  </tr>
}

const LogViewerLineLevel: FC<{level: LogLevel}> = ({level}) => {
  const variant = {
    error: {
      style: 'text-red-600',
      icon: fa.faCircleXmark,
      text: 'Error',
    },
    info: {
      style: 'text-blue-600',
      icon: fa.faCircleInfo,
      text: 'Info',
    },
    warn: {
      style: 'text-yellow-600',
      icon: fa.faCircleExclamation,
      text: 'Warning',
    },
    debug: {
      style: 'text-gray-600',
      icon: fa.faBug,
      text: "Debug",
    },
  }[level]
  return <div className={`${variant.style} font-medium text-xs tracking-wide uppercase h-7 flex items-center justify-start px-2 rounded`}>
    <FontAwesomeIcon icon={variant.icon} className={`mr-2`}></FontAwesomeIcon><span>{variant.text}</span>
  </div>
}
const LogViewerLineDate: FC<{date: string}> = ({date}) => {
  const dateDate = new Date(date)
  const dateDateText = dateDate.toLocaleDateString('nl-NL', { day: 'numeric', month: 'numeric', year: 'numeric'})
  const dateTimeText = dateDate.toLocaleTimeString('nl-NL', { hour: 'numeric', hour12: false, minute: '2-digit', second: '2-digit'})
  return <div className={"text-xs text-gray-600"}>{`${dateDateText} ${dateTimeText}`}</div>
}

const LogViewerLineRequest: FC<{line: LogLine, onClick?: () => void}> = ({line, onClick}) => {
  const type = line.request_id?.startsWith('http') ? 'web' : 'cli'
  const shortId = line.request_id?.split(':')?.[1]?.split('-')?.[0] ?? '?'
  return <button className={"rounded-full h-6 bg-slate-100 flex items-center text-xs tracking-wide hover:bg-slate-200"} onClick={onClick}>
    <div className={"ml-2 pr-1 border-r-2 border-slate-300"}>
      {type.toUpperCase()}
    </div>
    <div className={"mr-2 ml-1"}>
      {shortId}
    </div>
  </button>
}

function LogViewerLineCategory(props: { line: EnrichedLogLine }) {
  const variant = props.line.categoryVariant
  return <div className={"rounded-full h-6 flex items-center text-xs font-medium tracking-wide"}>
    <FontAwesomeIcon icon={variant.icon}></FontAwesomeIcon>
    <div className={"mx-2"}>
      {variant.text}
    </div>
  </div>
}

function LogViewerLineUser(props: { line: EnrichedLogLine, onClick?: () => void }) {
  let userName: string|null
  let userType: string|null
  if (props.line.user?.startsWith('client:')) {
    userType = 'C'
  }else if (props.line.user?.startsWith('employee:')) {
    userType = 'E'
  } else {
    userType = '?'
  }
  if (props.line.userObject?.name) {
    userName = props.line.userObject.name
  } else {
    userName = props.line.user ?? 'n/a'
  }

  return <button className={"rounded-full h-6 bg-slate-100 flex items-center text-xs tracking-wide hover:bg-slate-200"} onClick={props.onClick}>
    <div className={"w-6 h-6 rounded-full flex items-center justify-center bg-slate-200 text-base"}>
      {userType}
    </div>
    <div className={"mx-2"}>
      {userName}
    </div>
  </button>
}

function LogViewerLineMessage(props: { message: string|null }) {
  return <div className={"mx-4 text-sm font-medium"}>
    {props.message}
  </div>
}
const KNOWN_CATEGORIES: {[type: string]: {icon: IconDefinition, text: string}}= {
  'agenda': {
    icon: fa.faCalendar,
    text: 'Agenda',
  },
  'app_settings': {
    icon: fa.faGear,
    text: 'App Instellingen',
  },
  'broadcasts': {
    icon: fa.faBullhorn,
    text: 'Publicaties',
  },
  'chats': {
    icon: fa.faMessage,
    text: 'Chats',
  },
  'chat_notifications': {
    icon: fa.faCommentDots,
    text: 'Chat meldingen',
  },
  'clients': {
    icon: fa.faUserGroup,
    text: 'Cliënten',
  },
  'client_contacts': {
    icon: fa.faUserGroup,
    text: 'Netwerk leden',
  },
  'credentials': {
    icon: fa.faKey,
    text: 'Inloggegevens',
  },
  'documents': {
    icon: fa.faFile,
    text: 'Documenten',
  },
  'employees': {
    icon: fa.faUserGroup,
    text: 'Medewerkers',
  },
  'firebase_tokens': {
    icon: fa.faKey,
    text: 'Push notificatie tokens',
  },
  'notifications': {
    icon: fa.faBell,
    text: 'Meldingen',
  },
  'notification_preferences': {
    icon: fa.faBell,
    text: 'Meldingsvoorkeuren',
  },
  'tasks': {
    icon: fa.faSquareCheck,
    text: 'Taken',
  },
  'teams': {
    icon: fa.faUserGroup,
    text: 'Teams',
  },
  'social_cards': {
    icon: fa.faUserGroup,
    text: 'Sociale kaart',
  },
  'user_sync': {
    icon: fa.faUserGroup,
    text: 'Gebruikers Sync',
  },
}

function getCategoryVariant(category: string): { icon: IconDefinition; text: string } {
  return KNOWN_CATEGORIES[category] ?? {
    icon: fa.faQuestionCircle,
    text: category,
  }
}

