
import {callApi, postApiForm} from "./call";

export abstract class AbstractRepository {
    private baseUrl: string
    private token: string|undefined
    private selectedClient: string|undefined

    constructor(baseUrl: string, token?: string, selectedClient?: string) {
        this.baseUrl = baseUrl;
        this.token = token;
        this.selectedClient = selectedClient;
    }

    protected request<T>(method: "GET"|"POST"|"PUT"|"PATCH"|"DELETE", endpoint: string, body?: unknown, requestOptions?: {fileFormName?: string}) {
        return callApi<T>(this.baseUrl, this.token, this.selectedClient, endpoint, method, body, requestOptions)
    }
  protected formPostRequest<T>(endpoint: string, formData: FormData) {
    return postApiForm<T>(this.baseUrl, this.token, this.selectedClient, endpoint, formData)
  }

    protected unauthorizedRequest<T>(method: "GET"|"POST"|"PUT"|"PATCH"|"DELETE", endpoint: string, body?: unknown, requestOptions?: {fileFormName?: string}) {
        return callApi<T>(this.baseUrl, undefined, undefined, endpoint, method, body, requestOptions)
    }

    protected unpackDataCollectionResponse<T>(response: { data: T[]|{[k: number]: T} }): T[] {
        const list = response.data
        return Array.isArray(list) ? list : Object.values(list)
    }
}

