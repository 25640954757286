import {AbstractRepository} from "../repository";

export type RelatedCoordinator = {ons_employee_id: number, extension: string, primary: boolean}
export type MatchedItem = {
    type: 'client_contact'
    id: number
    client_id: number
    coordinators?: RelatedCoordinator[]
} | {
    type: 'client'
    id: number
    coordinators?: RelatedCoordinator[]
} | {
    type: 'employee'
    id: number
    coordinators?: RelatedCoordinator[]
}
export type SearchData = {
    city: string|null
    street: string|null
    zipcode: string|null
    date_of_birth: string|null
}
export interface SearchResult {
    id: number
    all: SearchData
    type: 'client'|'client_contact'|'employee'
    client_id: number|null
    client_number: number|null
    first_name: string
    last_name: string
    date_of_birth: string|any[]|null
    addresses: {street: string, city: string, zipcode: string}[]
}

export interface SavedMatch {
    id: number
    telephone_number: string
    match_type: 'client'|'client_contact'
    match_id: number
}

export default class TelephoneRepository extends AbstractRepository {

    /**
     * Get credentials for user
     */
    public getCredentials(): Promise<{user:string|null,token:string|null}> {
        return this.request<{data: { user: string|null, token: string|null }|string}>('GET', 'telephone/credentials')
            .then((response) => response.data)
            // TODO: Remove the bottom part, it is only for backwards compatibility
            .then((data) => {
                if (typeof data === 'string') {
                    return {
                        user: null,
                        token: data,
                    }
                }
                return data
            })
    }
    public saveCredentials(username: string, password: string): Promise<{user:string|null,token:string|null}> {
        return this.request<{data: { user: string|null, token: string|null }|string}>('POST', 'telephone/credentials', {
            user: username,
            password: password,
        })
            .then((response) => response.data)
            // TODO: Remove the bottom part, it is only for backwards compatibility
            .then((data) => {
                if (typeof data === 'string') {
                    return {
                        user: null,
                        token: data,
                    }
                }
                return data
            })
    }
    public matchPhone(phone: string): Promise<MatchedItem[]> {
        return this.request<{data: MatchedItem[]}>('POST', 'telephone/match', {
            telephone: phone,
        }).then(this.unpackDataCollectionResponse)
    }

    public search(query: string): Promise<SearchResult[]> {
        return this.request<{data: SearchResult[]}>('POST', 'telephone/query', {
            query: query,
        }).then(this.unpackDataCollectionResponse)
    }

    public browseMatches(): Promise<SavedMatch[]> {
        return this.request<{data: {[id: number]: SavedMatch}}>('GET', 'telephone/matches')
            .then(this.unpackDataCollectionResponse)
    }
    public delMatch(id: number): Promise<void> {
        return this.request('DELETE', `telephone/matches/${id}`)
    }
    public addMatch(phoneNumber: string, id: number, type: 'client'|'client_contact') {
        return this.request<{data: SavedMatch}>('POST', `telephone/matches`, {
            telephone: phoneNumber,
            match_id: id,
            match_type: type,
        }).then((response) => response.data)
    }
    public addClientContact(firstName: string, lastName: string, email: string, relationTypeId: number, gender: string) {
        return this.request<unknown>('POST', 'telephone/client_contact',{
            first_name: firstName,
            last_name: lastName,
            email: email,
            relation_type_id: relationTypeId,
            gender: gender,
        })
    }
}
