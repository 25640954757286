import { useApiCall } from "@jane/lib/src/api";
import React, {FormEvent, useState} from "react"
import {JaneEmployee, useEmployeesData} from "../resources/EmployeeContext";
import {config} from "../config";
import { useToasts } from "@jane/lib/src/toasts/toasts";
import { Select } from "@jane/lib/src/components/form/Select";
import { Button } from "@jane/lib/src/components/form/Button";
import { Card } from "@jane/lib/src/components/Card";

export default function RolesForm(props: {employee: JaneEmployee}): JSX.Element {
    const { reload } = useEmployeesData()

    const currentRole = props.employee.role === "Werknemer" ? "employee" : "coordinator"
    const rolesMap = {
        "employee": "Werknemer",
        "coordinator": "Coordinator",
    }
    const [role, setRole] = useState<"employee"|"coordinator">(currentRole)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const { employees } = useApiCall(config)
    const { showToast } = useToasts()
    function save() {
        setError('')
        setIsLoading(true)
        employees.updateRole(props.employee.sub, role)
            .then(() => {
                showToast('Wijzigingen opgeslagen', 'Het kan een minuut duren voordat de wijziging is doorgevoerd, herlaad straks de pagina om de wijziging te zien.')
                setRole(role)
                reload()
            })
            .catch(err => {
                setError(err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }
    return <Card title={'Rol aanpassen'} id={"role"}>
      <div className={"space-y-4"}>
        <Select options={rolesMap} value={role} onChange={(e) => setRole(e as "employee" | "coordinator")}
                label={'Role'}/>
        <Button type={'primary'} size={'sm'} disabled={isLoading} text={'Opslaan'} onClick={save}/>
        {error === "" ? "" : <>
          <div className={"text-danger mt-2"}>{error}</div>
        </>}
      </div>
    </Card>
}
