import {Client, Task, Team, useApiCall, useApiEndpoint} from "@jane/lib/src/api"
import { ContentContainer } from "@jane/lib/src/components/content/ContentContainer";
import { Loading } from "@jane/lib/src/components/Loading";
import React, {FC, ReactNode, useState} from "react"
import {config} from "../config";
import {useNavigate, useParams} from "react-router-dom";
import { useToasts } from "@jane/lib/src/toasts/toasts";
import {useClientData} from "../resources/ClientContext";
import {JaneEmployee, useEmployeesData} from "../resources/EmployeeContext";
import {usePermissions} from "../resources/PermissionsHook";
import { Button } from "@jane/lib/src/components/form/Button";
import {faCheckCircle, faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import {useTeamData} from "../resources/TeamContext";
import { useAuth } from "@jane/lib/src/auth/hook";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export const TaskDetailsPage: FC = () => {
    const props = useParams()
    const { tasks } = useApiCall(config)
    const { resource: task, isLoading, error } = useApiEndpoint(() => tasks.get(Number(props.id)))
    const navigate = useNavigate()
    function back() {
        window.close()
    }

    return <ContentContainer>
        <a onClick={back} className="underline text-brand-primary cursor-pointer mb-5">Terug naar overzicht</a>

        <Loading loading={isLoading}>
            { task ? <TaskDetailsCard task={task} isSubtask={false} /> : <p>
                Er zijn geen details van deze taak beschikbaar..
            </p>}
        </Loading>
    </ContentContainer>
}

function formatDateString(dateString: string): string {
    const date = new Date(dateString)
    const dateDateText = date.toLocaleDateString('nl-NL', { day: 'numeric', month: 'numeric', year: 'numeric'})
    const dateTimeText = date.toLocaleTimeString('nl-NL', { hour: 'numeric', hour12: false, minute: '2-digit'})
    return `${dateDateText} ${dateTimeText}`
}

const translateTaskType = (type: string) => {
    switch(type) {
        case "call": return "Nabellen"
        case "agenda_change": return "Afspraak gewijzigd"
        case "agenda_remove": return "Afspraak geannuleerd"
        case "sign_document": return "Document ondertekenen"
        default: return type
    }
}
const translateTaskStatus = (status: string) => {
    switch(status) {
        case "expired": return "Verlopen"
        case "pending": return "Open"
        case "acknowledged": return "Afgerond"
        default: return status
    }
}
const taskStatusTextColor = (status: string) => {
    switch(status) {
        case "expired": return "text-danger"
        case "pending": return "text-brand-primary"
        case "acknowledged": return "text-success"
        default: return status
    }
}

function TaskDetailsCard(props: {task: Task, isSubtask: boolean}): JSX.Element {
    const { tasks } = useApiCall(config)
    const { showToast } = useToasts()
    const [err, setErr] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const {clients} = useClientData()
    const {employees} = useEmployeesData()

    const {canListTeams, canListClients, canListEmployees} = usePermissions()

    const acknowledgee = [...clients, ...employees].find(x => !! props.task.acknowledged_by_sub && x.sub === props.task.acknowledged_by_sub)

    async function ack() {
        setIsLoading(true)
        setErr("")
        await tasks.ack(props.task.id)
            .then(() => {
                showToast("Taak afgerond", "Wijziging opgeslagen")
                window?.close()
            })
            .catch(() => {
                setErr('Er is iets verkeerd gegaan...')
            })
        setIsLoading(false)
    }

    const TABLE_SPACING = "py-4"
    return <div className={props.isSubtask ? "border border-brand-200 rounded p-4" : "rounded-lg shadow-lg my-8 p-8"}>
        <div className={"flex justify-between mb-4"}>
            <div>
                <h1 className={"text-xl font-medium"}>Taak: {translateTaskType(props.task.type)}</h1>
                <span className={"font-bold text-sm uppercase tracking-wide "+ taskStatusTextColor(props.task.status)}>{translateTaskStatus(props.task.status)}</span>
            </div>
            {props.task.status === "pending" && !props.isSubtask && <div>
                <span className={"text-danger mr-3"}>{err}</span>
                <Button size={"sm"} type="primary" onClick={ack} disabled={isLoading} text={"Taak afronden"} icon={faCheckCircle} />
            </div>}
        </div>
        { !! acknowledgee && <div>
            Taak is afgerond door <strong>{ acknowledgee.name }</strong> op {!! props.task.acknowledged_at && formatDateString(props.task.acknowledged_at)}
        </div> }
        <div className={"bg-gray-50 rounded p-4"}>
            <h2 className={"text-lg font-bold mb-2"}>{props.task.item_title}</h2>
            <p className={"mb-0"}>{props.task.description}</p>
        </div>
        <table className={"w-full mt-8"}>
            <tbody className={"align-top"}>
                {canListTeams && canListClients && canListEmployees ? <>
                    <tr>
                        <th className={TABLE_SPACING + " pr-4"}>
                            Toegewezen&nbsp;aan
                        </th>
                        <td className={TABLE_SPACING + " w-full"}>
                            <ProfileCard taskId={props.task.id} entityType={props.task.performer_type} entityId={props.task.performer_id} entitySub={props.task.performer_sub}/>
                        </td>
                    </tr>
                    <tr className={"border-t border-gray-100"}>
                        <th className={TABLE_SPACING}>
                            Betreft
                        </th>
                        <td className={TABLE_SPACING}>
                            <ProfileCard taskId={props.task.id} entityType={props.task.user_type} entityId={props.task.user_id} entitySub={props.task.user_sub}/>
                        </td>
                    </tr>
                </> : <></> }
                { !props.isSubtask && <tr className={"border-t border-gray-100"}>
                    <th className={TABLE_SPACING}>
                        Details
                    </th>
                    <td className={TABLE_SPACING}>
                        <ItemDetailsCard type={props.task.item_type} id={Number(props.task.item_id)} />
                    </td>
                </tr> }
            </tbody>
        </table>
    </div>
}
function ProfileCard(props: {taskId: number, entityType: string, entityId: number, entitySub: string|undefined|null}): JSX.Element {
    const {teams} = useTeamData()
    const {clients} = useClientData()
    const {employees} = useEmployeesData()
    const { token } = useAuth()

    let body: JSX.Element
    let entity: Team|Client|JaneEmployee|undefined = undefined
    let link: string|undefined

    switch (props.entityType) {
        case "team": {
                let team = teams.find(t => t.id === props.entityId)
                body = <>
                    <div className={"text-brand-primary font-bold text-xs uppercase tracking-wide"}>Team</div>
                    <div className={"font-bold"}>{team?.name ?? '-'}</div>
                </>
                entity = team
            }
            break
        case "client": {
                let client = clients.find(c => c.id === props.entityId)
                body = <>
                    <div className={"text-brand-primary font-bold text-xs uppercase tracking-wide"}>Cliënt</div>
                    <div className={"font-bold"}>{client?.name ?? '-'}</div>
                    <div>
                        {client?.preferred_username ?? '-'}
                        {client?.preferred_username?.toLowerCase() !== client?.email?.toLowerCase() && !!client?.email ? <> &middot; {client.email}</> : ''}
                    </div>
                </>
                link = `/clients/${props.entityId}?taskContext=${props.taskId}`
                entity = client
            }
            break
        case "employee": {
                let employee = employees.find(e => e.sub === props.entitySub)
                body = <>
                    <div className={"text-brand-primary font-bold text-xs uppercase tracking-wide"}>MEDEWERKER</div>
                    <div className={"font-bold"}>{employee?.name ?? '-'}</div>
                    <div>
                        {employee?.preferred_username ?? '-'}
                        {employee?.preferred_username?.toLowerCase() !== employee?.email?.toLowerCase() && !!employee?.email ? <> &middot; {employee.email}</> : ''}
                    </div>
                </>
                link = `/employees/${props.entityId}?taskContext=${props.taskId}`
                entity = employee
            }
            break
        default:
            body = <small>Geen details beschikbaar</small>
            link = undefined
    }

    return <a href={link ? link : "#"} className={"border border-brand-200 rounded p-3 flex items-center text-black hover:no-underline hover:text-black" + (link ? " cursor-pointer hover:bg-brand-100" : "")}>
          { !! entity && "employee_picture_url" in entity && entity.employee_picture_url ? <>
              <img alt={entity.name} src={`${entity.employee_picture_url}&accessToken=${token}`} className={"h-12 w-12 rounded-full"} />
          </> : <>
              <div className={"text-white bg-primary flex items-center justify-center h-12 w-12 rounded-full"}>
                  <i className={`bi ${ props.entityType === "team" ? 'bi-people-fill' : 'bi-person-fill'} text-2xl`}>
                  </i>
              </div>
          </>}
          <div className={"ml-3 flex-1"}>{body}</div>
          {!! link && <div className={"flex items-center"}>
              <span className={"text-xs uppercase tracking-wide font-bold mr-2 text-brand-primary"}>Details</span>
              <i className={"bi bi-chevron-right text-brand-primary text-xl"}> </i>
          </div> }
      </a>
}
function ItemDetailsCard(props: {type: string, id: number}): JSX.Element {

    const { tasks } = useApiCall(config)
    const { resource: task, isLoading } = useApiEndpoint(() => tasks.get(props.id), props.type === "task")

    switch (props.type) {
        case "task":
            if (isLoading || !task) {
                return <FontAwesomeIcon icon={faCircleNotch} spin={true} />
            }
            return <TaskDetailsCard task={task} isSubtask={true} />
        case "planned_visit":
            return <div className={"flex items-center border border-brand-200 rounded p-3"}>
                <div className={"text-white bg-success flex items-center justify-center h-12 w-12 rounded-full"}>
                    <i className={`bi bi-calendar-check-fill text-2xl`}>
                    </i>
                </div>
                <div className={"ml-3 flex-1"}>
                    <div className={"text-success font-bold text-xs uppercase tracking-wide"}>Agenda Wijziging</div>
                    <div>Geen details beschikbaar</div>
                </div>
            </div>
        default:
            return <div className={"flex items-center border border-brand-200 rounded p-3"}>
                Geen details beschikbaar...
            </div>
    }
}
