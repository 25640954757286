import {AbstractRepository} from "../repository";

export type SyncStatus = {
    synced: boolean
    reason: string
    updated_at: string
    created_at?: string
}

export default class SyncRepository extends AbstractRepository {
    /**
     * Reset password for subject
     */
    setSubjectPassword(subject: string, password: string, temporary: boolean): Promise<void> {
        return this.request('POST', `reset_password/${subject}`, {
            temporary,
            password,
        }).then(() => {})
    }

    /**
     * Reset password of current user
     */
    setMyPassword(password: string): Promise<void> {
        return this.request('POST', `reset_my_password`, {
            password,
        }).then(() => {})
    }

    /**
     * Get sync status
     */
    status(object: "employee"|"client"|"client_contact", objectId: number): Promise<SyncStatus[]> {
        return this.request<{data: {[id: number]: SyncStatus}}>('GET', `sync_status/${object}/${objectId}`)
            .then(this.unpackDataCollectionResponse)
    }

    /**
     * Force a sync
     */
    sync(object: "employee"|"client", objectId: number): Promise<void> {
        return this.request('POST', `force_sync/${object}/${objectId}`)
            .then(() => {})
    }
}
