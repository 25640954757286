import { useApiCall } from "@jane/lib/src/api";
import { LearnComponent } from "@jane/lib/src/api/repositories/learn-repository";
import { Checkbox } from "@jane/lib/src/components/form/Checkbox";
import {FileInput, Input} from "@jane/lib/src/components/form/Input";
import { PillSelect } from "@jane/lib/src/components/form/PillSelect";
import React, {FC, useState} from "react";
import {ModalFooter} from "../components/ModalProvider";
import {config} from "../config";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {IconButton } from "@jane/lib/src/components/form/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Select } from "@jane/lib/src/components/form/Select";

export const AddLearnCardModal: FC<{onFinished: () => void, moduleId: string, component: LearnComponent}> = (props) => {
  const {learn} = useApiCall(config)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [type, setType] = useState('text')
  const [media, setMedia] = useState<File[]>([])
  const [questionOptions, setQuestionOptions] = useState<string[]>([''])
  const [multipleChoice, setMultipleChoice] = useState(false)
  const [correctMultipleChoice, setCorrectMultipleChoice] = useState<number>(0)
  const order = (props.component.cards?.reduce((acc, x) => Math.max(acc, x.order), 0) ?? 0) + 1
  const save = async () => {
    if (type === 'text') {
      await learn.addTextCard(props.moduleId, props.component.id, title, description)
    }
    if (type === 'question') {
      await learn.addQuestionCard(props.moduleId, props.component.id, title, description, questionOptions, multipleChoice, correctMultipleChoice)
    }
    if (type === 'media') {
      await learn.addMediaCard(props.moduleId, props.component.id, title, description, media[0])
    }
    props.onFinished()
  }
  return <div className={"flex flex-col"}>
    <Input type={'text'} label={'Titel'} value={title} onChange={setTitle} />
    <PillSelect value={type} onChange={setType} options={{
      'text': "Tekstkaart",
      'media': "Beeldkaart",
      'question': "Vraagkaart",
    }} />
    {['text', 'media', 'question'].includes(type) && <Input type={'textarea'} label={type === 'question' ? 'Vraag' : 'Omschrijving'} value={description} onChange={setDescription} />}
    {type === 'media' && <div className={"flex items-center space-x-2 mt-2"}>
      <FileInput onChange={setMedia} accept={'image/*,video/*'} label={'Media'} />
    </div>}
    {type === 'question' && <div className={"mt-2 space-y-2"}>
      {questionOptions.map((option, i) => <div key={i} className={"flex items-end space-x-2"}>
        <Input type={'text'} label={`Antwoord ${i+1}`} value={option} onChange={(newValue) => setQuestionOptions(old => old.map((oldValue, oldI) => oldI === i ? newValue : oldValue))} />
        <IconButton disabled={questionOptions.length < 2} type={'danger'} size={'sm'} icon={faTimes} onClick={() => setQuestionOptions(old => old.filter((_, oldI) => oldI !== i))} />
      </div>)}
      {questionOptions.length < 5 && <button className={'font-bold text-xs text-brand-primary hover:bg-brand-surface-variant px-1 rounded'}
               onClick={() => setQuestionOptions(old => [...old, ''])}><FontAwesomeIcon icon={faPlus} className={"mr-1"}/>Optie toevoegen</button>}
      <Checkbox checked={multipleChoice} onChange={setMultipleChoice} label={'Correct antwoord'} description={'Een van de antwoorden is correct'} />
      {multipleChoice && <>
        <Select options={Object.fromEntries(questionOptions.map((v,k) => [k.toString(), v]))} value={correctMultipleChoice.toString()} onChange={(key) => setCorrectMultipleChoice(Number(key))} label={'Correct antwoord'} />
      </>}
    </div>}

    <ModalFooter text={'Opslaan'} isDisabled={(type === 'media' && media[0] === undefined) || description.length === 0 || title.length === 0} onSubmit={save} />
  </div>
}