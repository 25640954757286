import {Task, useApiCall, useApiEndpoint} from "@jane/lib/src/api"
import React, {createContext, ReactNode, useContext} from "react"
import { useAuth} from "@jane/lib/src/auth/hook"
import {config} from "../config";

interface TaskContextType {
    tasks: Task[]
    isLoading: boolean
    error: string|undefined
    reload: () => void
}

export const TaskContext = createContext<TaskContextType>({} as TaskContextType)

export function TaskContextProvider({ children } :{children: ReactNode}): JSX.Element {
    const { isLoggedIn } = useAuth()

    const { tasks } = useApiCall(config)
    const { resource, isLoading, error, reload } = useApiEndpoint(() => tasks.all(), isLoggedIn)

    return <TaskContext.Provider value={({
        tasks: resource || [],
        isLoading,
        reload,
        error: (typeof(error) === "string") ? error : undefined,
    })}>{ children }</TaskContext.Provider>
}

export function useTaskData(): TaskContextType {
    return useContext(TaskContext)
}
