import {AbstractRepository} from "../repository";

export interface ChatRoomInfoMember {
    sub: string
    name: string
    role: string
    employee_picture_url: string|null
    explicit: boolean
}
export interface ChatRoomInfo {
    id: string
    type: "network"|"team"|"message"|"group"
    concerns: string[]
    name: string|null
    members: ChatRoomInfoMember[]
}

export default class ChatRepository extends AbstractRepository {
    /**
     * List all teams in the system
     */
    public all(): Promise<Array<ChatRoomInfo>> {
        return this.request<{data: {[id: number]: ChatRoomInfo}}>('GET', 'my_chats')
            .then(this.unpackDataCollectionResponse)
    }

    public add(type: "team"|"message"|"group", members: number[], name?: string): Promise<Array<ChatRoomInfo>> {
        if (type === 'group' && name === undefined) {
            throw new Error('Name of chat must be defined when adding group')
        }
        return this.request<{data: {[id: number]: ChatRoomInfo}}>('POST', 'chat', {
            type: type,
            concerns: members,
            name: name ?? null,
        }).then(this.unpackDataCollectionResponse)
    }
}
