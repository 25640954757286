import React, {createContext, useContext, useMemo} from "react"
import {useAuth} from "@jane/lib/src/auth/hook"
import {Employee, useApiCall, useApiEndpoint} from "@jane/lib/src/api"
import {usePermissions} from "./PermissionsHook"
import {config} from "../config";

export interface JaneEmployee extends Employee {
    role: "Werknemer" | "Coordinator" | "Manager"
}

interface EmployeeContextType {
    employees: JaneEmployee[]
    isLoading: boolean
    error: string
    reload: () => void
}

export const EmployeeContext = createContext<EmployeeContextType>({} as EmployeeContextType)

export function EmployeeContextProvider({children}: {children: React.ReactNode}): JSX.Element {
    const { isLoggedIn } = useAuth()
    const { employees: employeesApi } = useApiCall(config)
    const { canListEmployees } = usePermissions()
    const { resource: employees, isLoading, error, reload } = useApiEndpoint(() => employeesApi.all(), isLoggedIn && canListEmployees)

    const allEmployees = useMemo((): JaneEmployee[] => {
        return (employees || []).map(e => {
            let role: "Werknemer" | "Coordinator" | "Manager"
            switch (e.role_name) {
                case "employee": role = "Werknemer"; break
                case "coordinator": role = "Coordinator"; break
                case "manager": role = "Manager"; break
                default: role = "Werknemer"
            }
            return {
                ...e,
                role,
            }
        })
    }, [employees])

    const context: EmployeeContextType = {
        employees: allEmployees,
        isLoading,
        error: error ? String(error) : "",
        reload,
    }
    return <EmployeeContext.Provider value={context}>{ children }</EmployeeContext.Provider>
}

export function useEmployeesData(): EmployeeContextType {
    return useContext(EmployeeContext)
}
