
export const resolveConfig = (key: string, fallback: string): string => {
  // @ts-ignore
  return import.meta.env[key] ?? fallback
}
export interface ConfigInterface {
  isProduction: boolean

  authRealmUrl: string
  authRealm: string
  authClientId: string

  restApiUrl: string,

  guestRoutes: string[],
  publicRoutes: string[],
  defaultRoute: string,
  loginRoute: string

  fileViewerBaseUrl: string
  rocketChatHostname: string

  telephoneServiceUrl: string

  sentryDsn: string
}