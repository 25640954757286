import {ClientContact, useApiCall, useApiEndpoint} from "@jane/lib/src/api"
import { Card } from "@jane/lib/src/components/Card";
import { SectionHeader } from "@jane/lib/src/components/content/SectionHeader";
import { Button } from "@jane/lib/src/components/form/Button";
import { Toggle } from "@jane/lib/src/components/form/Toggle";
import { Loading } from "@jane/lib/src/components/Loading";
import { useToasts } from "@jane/lib/src/toasts/toasts";
import React, {CSSProperties, useEffect, useState} from "react"
import {config} from "../config";
export default function CircleForm(props: {subject: string}): JSX.Element {

    const { network } = useApiCall(config)

    const [isLoadingCircle, setIsLoadingCircle] = useState(false)
    const [circle, setCircle] = useState<ClientContact[]>()

    useEffect(() => {
        if (! circle && ! isLoadingCircle) {
            setIsLoadingCircle(true)
            network.membersForClient(props.subject).then(contacts => {
                setCircle(contacts)
                setIsLoadingCircle(false)
            })
        }
    }, [circle, isLoadingCircle, network, props.subject])

    return <Card title={'Netwerk'}>

        <Loading loading={! circle || isLoadingCircle}>
            { ! circle || circle!.length === 0 ? <>
                Deze client heeft geen netwerk
            </> : <>
                { circle.map((contact, key) => {
                    return <div key={key} className="mt-3">
                        <ContactCard contact={contact} />
                    </div>
                })}
            </>}
        </Loading>

    </Card>
}

function ContactCard(props: { contact: ClientContact}): JSX.Element {
    const loadingBarStyle: CSSProperties = {
        background: '#0001',
        borderRadius: '5px',
        marginBottom: '1em',
        height: '1em',
        animationName: 'opacity-pulse',
        animationDuration: '2s',
        animationTimingFunction: 'ease-in-out',
        animationIterationCount: 'infinite'
    }

    const { showToast } = useToasts()
    const { network } = useApiCall(config)
    const { resource, isLoading: isPermissionsLoading, reload } = useApiEndpoint(() => network.memberPermissions(props.contact.id))

    const [isDirty, setIsDirty] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const isLoading = isSubmitting || isPermissionsLoading

    const [agenda, setAgenda] = useState(false)
    const [contacts, setContacts] = useState(false)
    const [documents, setDocuments] = useState(false)
    const [tasks, setTasks] = useState(false)

    // From the context of a client, only the relevant permission set is returned.
    // So we can access [0] on the array without any problems.
    const permissions = (resource ? resource[0] : undefined)
    useEffect(() => {
        if (permissions) {
            setAgenda(permissions.can_access_agenda)
            setContacts(permissions.can_access_contacts)
            setDocuments(permissions.can_access_documents)
            setTasks(permissions.can_access_tasks)
        }
    }, [permissions])

    async function saveChanges() {
        setIsSubmitting(true)
        await network.updateMemberPermissions(props.contact.id, {
            client_id: permissions!.client_id,
            can_access_agenda: agenda,
            can_access_contacts: contacts,
            can_access_documents: documents,
            can_access_tasks: tasks,
        })
        reload()
        showToast('Opgelagen', 'Voorkeuren toegang opgeslagen')
        setIsDirty(false)
        setIsSubmitting(false)
    }

    return <div className={"border border-brand-secondary rounded-lg mt-4 px-4 py-3"}>
      <SectionHeader>{ props.contact.firstName } {props.contact.lastName }</SectionHeader>
            <div className={"text-sm"}>Contact: { props.contact.username } { props.contact.username !== props.contact.email && !! props.contact.email && <> &middot; {props.contact.email}</>}</div>
            <div>
                { isLoading || !resource ? <>
                    { isLoading
                        ? <div className="mt-3" style={{ ...loadingBarStyle, width: '70%', animationDelay: '100ms' }}></div>
                        : <div>-</div>
                    }
                </> : <>
                    <hr />
                    <div className={"mt-4 mb-2 font-bold"}>
                        Toegang:
                    </div>
                  <form onSubmit={saveChanges} className={"space-y-4"}>
                    <Toggle label={'Agenda'} value={agenda} onChange={(v) => {setAgenda(v); setIsDirty(true);}} />
                    <Toggle label={'Netwerk'} value={contacts} onChange={(v) => {setContacts(v); setIsDirty(true);}} />
                    <Toggle label={'Documenten'} value={documents} onChange={(v) => {setDocuments(v); setIsDirty(true);}} />
                    <Toggle label={'Meldingen'} value={tasks} onChange={(v) => {setTasks(v); setIsDirty(true);}} />
                    { isDirty && !! permissions && <Button type="primary" submit size={'sm'} disabled={isLoading} text={'Wijzigingen opslaan'} /> }
                  </form>
                </>}
            </div>
    </div>
}
