import { ContentContainer } from "@jane/lib/src/components/content/ContentContainer"
import { Button } from "@jane/lib/src/components/form/Button"
import { Paragraph } from "@jane/lib/src/components/Paragraph"
import {faCircleInfo, faPencil, faPlus} from "@fortawesome/free-solid-svg-icons";
import {useApiCall, useApiEndpoint} from "@jane/lib/src/api";
import {config} from "../config";
import { Loading } from "@jane/lib/src/components/Loading";
import {LearnComponent } from "@jane/lib/src/api/repositories/learn-repository";
import React, {FC, useState} from "react";
import {useModal} from "../components/ModalProvider";
import {EditLearnModuleModal} from "../modals/EditLearnModuleModal";
import {useNavigate, useParams} from "react-router-dom";
import { SectionHeader } from "@jane/lib/src/components/content/SectionHeader";
import { SectionDivider } from "@jane/lib/src/components/content/SectionDivider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AddLearnComponentModal} from "../modals/AddLearnComponentModal";
import { Breadcrumbs } from "@jane/lib/src/components/content/Breadcrumbs";

export const LearnModuleDetailPage: React.FC = () => {
  const {learn} = useApiCall(config)
  const {moduleId} = useParams()
  const {resource, isLoading, reload} = useApiEndpoint(() => learn.readModule(moduleId!), moduleId !== undefined)
  const editModal = useModal({title: "Onderwerp bewerken", body: <EditLearnModuleModal module={resource!} onFinished={() => reload()} />})
  const addModal = useModal({title: "Nieuw onderdeel", body: <AddLearnComponentModal moduleId={moduleId!} onFinished={() => reload()} />})

  return <ContentContainer>
    <Loading loading={resource === undefined}>
      <Breadcrumbs currentPage={resource?.name??''} crumbs={[
        {label: 'Kennisbank', href: '/learn'}
      ]} />
      <div className={'flex items-center'}>
        <h1 className={"h-12 flex items-center text-2xl leading-tight font-bold"}>Onderwerp: {resource?.name}</h1>
        {resource?.mandatory && <span
          className={'ml-4 py-1 px-2 text-xs font-medium rounded border border-brand-on-primary-container'}>Verplicht</span>}
        <div className={"flex-1 flex justify-end"}>
          <Button type={'secondary'} size={'sm'} text={'Onderwerp bewerken'} icon={faPencil} onClick={() => editModal.open()}/>
        </div>
      </div>
      <div className={"flex justify-between"}>
        <div className={"max-w-2xl"}>
          <Paragraph>
            {resource?.description}
          </Paragraph>
        </div>
      </div>
      <SectionDivider />
      <section>
        <div className={"flex items-center justify-between mb-4"}>
          <div className={"-mb-4"}>
            <SectionHeader>Onderdelen</SectionHeader>
          </div>
          <Button type={'primary'} size={'sm'} text={'Nieuw onderdeel'} icon={faPlus} onClick={() => addModal.open()}/>
        </div>
        <div className={"grid grid-cols-4 gap-5"}>
          {resource?.components?.map(component => <ComponentCard moduleId={moduleId!} component={component} />)}
        </div>
      </section>
    </Loading>
  </ContentContainer>
}

const ComponentCard: FC<{moduleId: string, component: LearnComponent}> = props => {
  const navigate = useNavigate()
  return <div
    className={'bg-brand-primary-container text-brand-on-primary-container px-4 py-4 rounded-xl flex flex-col items-stretch'}>
    <div className={"flex items-center"}>
      <FontAwesomeIcon icon={faCircleInfo}/>
      <h3 className={'ml-3 font-medium leading-tight'}>{props.component.name}</h3>
    </div>
    <div className={"flex flex-col space-y-2 mb-4 mt-2 items-stretch flex-1 justify-end"}>
      <div className={"flex items-center justify-between"}>
        <span className={"font-light"}>Kaarten</span>
        <span className={"font-bold"}>{props.component.cards?.length ?? 0}</span>
      </div>
      <div className={"flex items-center justify-between ml-3"}>
        <span className={"font-light"}>Tekst</span>
        <span className={"font-bold"}>{props.component.cards?.filter(x => x.type === "text").length ?? 0}</span>
      </div>
      <div className={"flex items-center justify-between ml-3"}>
        <span className={"font-light"}>Beeld</span>
        <span
          className={"font-bold"}>{props.component.cards?.filter(x => x.type === "media").length ?? 0}</span>
      </div>
      <div className={"flex items-center justify-between ml-3"}>
        <span className={"font-light"}>Vragen</span>
        <span
          className={"font-bold"}>{props.component.cards?.filter(x => x.type === "question").length ?? 0}</span>
      </div>
    </div>
    <Button type={'primary'} size={'xs'} text={'Kaarten bekijken'} onClick={() => navigate(`/learn/${props.moduleId}/${props.component.id}`)}/>
  </div>
}