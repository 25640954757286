import React, {useEffect, useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {NavLink, useNavigate, useParams} from "react-router-dom"
import {Broadcast, useApiCall, useApiEndpoint} from "@jane/lib/src/api"
import {config} from "../config";
import { ContentContainer } from "@jane/lib/src/components/content/ContentContainer";
import { Loading } from "@jane/lib/src/components/Loading";
import {BroadcastsForm} from "../components/broadcasts-form";
import { useAuth } from "@jane/lib/src/auth/hook";
import { PageHeader } from "@jane/lib/src/components/content/PageHeader";

export default function BroadcastsDetailsPage(): JSX.Element {

  const {id: broadcastId} = useParams()
  const {broadcasts: broadcastsApi} = useApiCall(config)
  const {resource: broadcasts, error} = useApiEndpoint(() => broadcastsApi.get())
  const [broadcast, setBroadcast] = useState<Broadcast|null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchedBroadcast = broadcasts?.find(b => b.id.toString() === broadcastId) ?? null
    if (broadcast === null && fetchedBroadcast !== null) {
      setBroadcast(fetchedBroadcast)
    }
  }, [broadcasts, broadcast])

  const [step, setStep] = useState(2)
  const onBroadcastSubmit = async (broadcast: Partial<Broadcast>): Promise<void> => {
    if (step === 1) {
      setBroadcast(old => {
        if (old) {
          return {...old, ...broadcast}
        } else if (broadcast.subject && broadcast.message && broadcast.target_id && broadcast.target_type) {
          return broadcast as Broadcast
        }
        return null
      })
      await broadcastsApi.update(Number(broadcastId), broadcast)
      setStep(2)
    }
    if (step === 2 && broadcast.attachment_path) {
      setBroadcast(old => {
        if (old) {
          return {...old, ...broadcast}
        }
        return null
      })
      setStep(3)
    }
    if (step === 2 && broadcast.attachment_path === null) {
      setBroadcast(old => {
        if (old) {
          return {...old, ...broadcast, attachment_name: null}
        }
        return null
      })
    }
    if (step === 3) {
      navigate('/broadcasts')
    }
  }

  return <ContentContainer>
    <NavLink to={'/broadcasts'}>Terug naar overzicht</NavLink>

    <Loading loading={broadcast === null}>
      {broadcast && <>
        {broadcast.status === "draft" ? <BroadcastsForm broadcast={broadcast} onBroadcastSubmitted={onBroadcastSubmit} step={step} onStepChange={setStep} /> : <BroadcastOverview broadcast={broadcast} />}
      </>}
    </Loading>
  </ContentContainer>
}

function BroadcastOverview(props: { broadcast: Broadcast }) {
  const {token} = useAuth()
  return <div className={"bg-gray-50 rounded-lg p-6 my-3 flex flex-col space-y-3§"}>
    <span className={"text-gray-600 italic mb-3"}>Verzonden op {new Date(props.broadcast.updated_at).toLocaleDateString('nl')}</span>
    <PageHeader>{props.broadcast.subject}</PageHeader>
    <p>{props.broadcast.message}</p>
    {props.broadcast.attachment_name !== null && <div className={"flex px-4 py-3 mt-3 border border-gray-100 rounded items-center"}>
      <FontAwesomeIcon icon={['fas', 'paperclip']} className={"h-4 w-4 mr-3 font-light text-gray-600"} />
      <div className={"flex-1"}>
        { props.broadcast.attachment_name }
      </div>
      <a href={`${props.broadcast.attachment_path}&accessToken=${token}`} target={'_blank'} className={"flex items-center"} rel="noreferrer">Download<FontAwesomeIcon className={"h-4 w-4 ml-2"} icon={['fas', 'external-link-alt']} /></a>
    </div> }
  </div>
}
