import {AbstractRepository} from "../repository";

export interface ClientContactDetails {
    id: number
    sub: string|null
    name: string
    preferred_username: string|null
    gender: string
    given_name: string
    family_name: string
    email: string|null
    enabled: boolean
    role_name: string
}
export default class ClientContactsRepository extends AbstractRepository {
    /**
     * List all clients
     */
    public all(): Promise<ClientContactDetails[]> {
        return this.request<{data: ClientContactDetails[]}>('GET', 'client_contacts')
            .then(this.unpackDataCollectionResponse)
    }
}
