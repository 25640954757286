import {useAuth} from "../auth/hook";

export default function useFileUrlFactory(fileViewerBaseUrl: string): (type: string, url: string) => string {
    const { token } = useAuth()
    return (type: string, url: string): string => {
        const fileViewerAssetUrl = url + '&accessToken=' + token
        if (type === "pdf") {
            return fileViewerAssetUrl
        }
        if (type === "unsupported") {
            return url
        }
        return fileViewerBaseUrl + '?type=' + type + '&url=' + encodeURIComponent(fileViewerAssetUrl)
    }
}
