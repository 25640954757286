import React, {useMemo} from "react"
import {useEmployeesData} from "../resources/EmployeeContext";
import {usePermissions} from "../resources/PermissionsHook";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import { ContentContainer } from "@jane/lib/src/components/content/ContentContainer";
import { Loading } from "@jane/lib/src/components/Loading";
import TwoFactorResetForm from "../components/two-factor-reset-form";
import PictureForm from "../components/picture-form";
import TeamsForm from "../components/teams-form";
import RolesForm from "../components/roles-form";
import PasswordResetForm from "../components/password-reset-form";
import InviteForm from "../components/invite-form";
import SyncStatus from "../components/sync-status";
import ProfileCard from "../components/profile-card";
import { PageHeader } from "@jane/lib/src/components/content/PageHeader";


export default function EmployeesDetailPage(): JSX.Element {
  const {id} = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { employees, isLoading } = useEmployeesData()
  const permissions = usePermissions()
  const employee = useMemo(() => {
    return employees.find((e) => e.id === +(id??'0'))
  }, [employees, id])

  function goBack() {
    if (searchParams.has('taskContext')) {
      navigate(`/tasks/${searchParams.get('taskContext')}`)
    } else {
      navigate(-1)
    }
  }

  return <ContentContainer>
    <Loading loading={isLoading}>
      { employee ? <>
        <a className={"underline cursor-pointer text-brand-primary"} onClick={goBack}>Terug naar overzicht</a>

        <PageHeader>{ employee.name }</PageHeader>

        <div className={"flex"}>
          <div className={"my-6 w-64 relative hidden md:block"}>
            <div className={"sticky top-0 flex flex-col space-y-2 py-2"}>
              <a className={"underline cursor-pointer text-brand-primary"} href={"#profile"}>Profiel</a>
              { permissions.canResetEmployee2fa && <a className={"underline cursor-pointer text-brand-primary"} href={"#2fa"}>2FA</a>}
              { permissions.canManageEmployeePicture && <a className={"underline cursor-pointer text-brand-primary"} href={"#picture"}>Profielfoto</a>}
              { permissions.canManageEmployeeTeams && employee.role === "Coordinator" && <a className={"underline cursor-pointer text-brand-primary"} href={"#teams"}>Teams</a>}
              { permissions.canManageEmployeeRoles && ["Werknemer", "Coordinator"].includes(employee.role) && <a className={"underline cursor-pointer text-brand-primary"} href={"#role"}>Rol</a>}
              { permissions.canResetEmployeePassword && <a className={"underline cursor-pointer text-brand-primary"} href={"#password_reset"}>Wachtwoord reset</a>}
              { permissions.canSendEmployeeInvite && <a className={"underline cursor-pointer text-brand-primary"} href={"#invite"}>Uitnodigen</a>}
              { permissions.canSeeEmployeeSyncStatus && <a className={"underline cursor-pointer text-brand-primary"} href={"#sync"}>Sync status</a>}
            </div>
          </div>
          <div className={"flex-1 flex flex-col space-y-8"}>
            <ProfileCard person={employee} />

            { employee.sub ? <>
              { permissions.canResetEmployee2fa && <TwoFactorResetForm employee={employee} />}
              { permissions.canManageEmployeePicture && <PictureForm employee={employee} />}
              { permissions.canManageEmployeeTeams && employee.role === "Coordinator" && <TeamsForm employee={employee} />}
              { permissions.canManageEmployeeRoles && ["Werknemer", "Coordinator"].includes(employee.role) && <RolesForm employee={employee} /> }
              { permissions.canResetEmployeePassword && <PasswordResetForm subject={employee.sub} />}
              { permissions.canSendEmployeeInvite && <InviteForm subject={employee.sub} type="employee" />}
            </> : <>
            </> }
            { permissions.canSeeEmployeeSyncStatus && <SyncStatus type="employee" objectId={employee.id} /> }
          </div>
        </div>
      </> : <>
        We kunnen de medewerker die u zoekt niet vinden.
      </> }
    </Loading>
  </ContentContainer>
}
