import {Employee, useApiCall} from "@jane/lib/src/api"
import { Card } from "@jane/lib/src/components/Card";
import { Button } from "@jane/lib/src/components/form/Button";
import { useToasts } from "@jane/lib/src/toasts/toasts"
import React, {useState} from "react"
import {config} from "../config";

export default function TwoFactorResetForm(props: {employee: Employee}): JSX.Element {

    const [isLoading, setIsLoading] = useState(false)
    const [error, ] = useState("")
    const { showToast } = useToasts()
    const { employees } = useApiCall(config)

    function submit() {
        setIsLoading(true)
        employees.reset2fa(props.employee.sub)
            .then(() => {
                showToast('2FA gereset', 'Geef medewerker instructie om opnieuw in te loggen')
            })
            .catch(() => {
                showToast('Oeps!', 'Er is iets verkeerd gegaan')
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    if (! props.employee.actions || props.employee.actions.includes('CONFIGURE_TOTP')) {
        return <></>
    }

    return <Card title={'Twee Factor Authenticatie Resetten'}>
      <p className={'mb-4 text-sm'}>
          Deze medewerker heeft two-factor-authentication (2FA) aan staan. Door dit
          te resetten krijgt de medewerker bij de eerst volgende inlogpoging instructies
          om het opnieuw in te stellen.
      </p>
      <Button disabled={ isLoading } onClick={submit} text={'Reset 2FA'} type={'primary'} size={'sm'} />
      { error === "" ? "" : <>
          <div className={"text-danger mt-2"}>{ error }</div>
      </>}
    </Card>
}
