import React, {useCallback, useMemo, useState} from "react"
import DataTable, {TableProps} from "./data-table";
import {useParams} from "react-router-dom";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Input } from "@jane/lib/src/components/form/Input";
import { Select } from "@jane/lib/src/components/form/Select";

interface Filter<T> {
    title: string
    property: keyof T
    options: {[value: string]: string}
    defaultValue: string
    // eslint-disable-next-line no-unused-vars
    filterCallback?: (row: T, filterValue: string) => boolean
}
export interface DynamicDataTableProps<T> extends TableProps<T> {
    searchableProperties: Array<keyof T>
    availableFilters: Filter<T>[]
}
const PAGINATE_SIZE = 10
export default function DynamicDataTable<T> ( props: DynamicDataTableProps<T> ): JSX.Element {
    const query = useParams()
    const [search, setSearch] = useState('')
    // const [filterValues, setFilterValues] = useState<{[filter in T]: string}>({})
    const [filterValues, setFilterValues] = useState<Record<keyof T, string>>({} as Record<keyof T, string>)
    const [page, setPage] = useState(1)
    // eslint-disable-next-line no-unused-vars
    const [sortColumn, setSortColumn] = useState<keyof T | null>(null)
    // eslint-disable-next-line no-unused-vars
    const [sortOrder, setSortOrder] = useState<"ASC"|"DESC"|null>(null)
    const setFilterValue = useCallback((filter: keyof T, value: string) => {
        setPage(1)
        setFilterValues(old => {
            const filters = {
                ...old,
                [filter]: value
            }
            window.location.hash = '?' + Object.entries(filters).map(([key, value]) => `${key}=${value}`).join('&')
            return filters
        })
        // (if we include push() in the array, the page will reload endlessly)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setFilterValues])
    const getDefaultFilterValue = useCallback((filterProperty: keyof T) => {
        const filter = props.availableFilters.find(f => f.property === filterProperty)
        return (Array.isArray(query[filterProperty]) ? query[String(filterProperty)]![0] : query[String(filterProperty)]) ?? filter?.defaultValue
    }, [query, props.availableFilters])
    const getFilterValue = useCallback((filter: keyof T) => filterValues[filter] ?? getDefaultFilterValue(filter), [getDefaultFilterValue, filterValues])
    const filterableData = useMemo(() => {
        return props.data.map((row) => {
            return {
                ...row,
                _searchable: props.searchableProperties.map(prop => (row[prop] ?? '').toString()).join('|').toLowerCase()
            }
        })
    }, [props.data, props.searchableProperties])
    const filteredData = useMemo(() => {
        return filterableData.filter(row => {
            for (const filter of props.availableFilters) {
                const callback = filter.filterCallback ?? ((row: T, value: string) => row[filter.property] === value)
                const result = callback(row, getFilterValue(filter.property))
                if (! result) {
                    return false
                }
            }
            // All filters have passed
            return true
        })
    }, [filterableData, props.availableFilters, getFilterValue])
    const searchedData = useMemo(() => {
        if (search === '') {
            return filteredData
        }
        return filteredData.filter(row => row._searchable.includes(search.toLowerCase()))
    }, [filteredData, search])
    const sortedData = useMemo(() => {
        let sortedData
        if (sortOrder === null || sortColumn === null) {
            sortedData = searchedData
        } else {
            const sortFactor = sortOrder === "ASC" ? 1 : -1
            sortedData = searchedData.sort((a, b) => {
                if (a[sortColumn] > b[sortColumn]) {
                    return sortFactor
                } else {
                    return -sortFactor
                }
            })
        }
        if (sortedData.length < PAGINATE_SIZE) {
            return sortedData
        }
        return sortedData.slice((page-1)*PAGINATE_SIZE, (page-1)*PAGINATE_SIZE + PAGINATE_SIZE)
    }, [searchedData, sortOrder, sortColumn, page])
    console.log({sortOrder, sortColumn})
    const maxPages = useMemo(() => Math.floor(Math.max(searchedData.length - 1, 0) / PAGINATE_SIZE) + 1, [searchedData])
    const paginatedData = sortedData
    const changeSortSettings = useCallback(function<T>(updatedColumn: keyof T) {
        console.log({updatedColumn})
        // @ts-ignore
        if (sortColumn !== updatedColumn) {
            // @ts-ignore
            setSortColumn(updatedColumn)
            setSortOrder("ASC")
            return
        }
        // Cycle the sort order
        if (sortOrder === "ASC") {
            setSortOrder("DESC")
        } else if (sortOrder === "DESC") {
            setSortOrder(null)
        } else {
            setSortOrder("ASC")
        }
    }, [sortColumn, sortOrder])

    const sortingHeaderMapper = useCallback(function<T>( props: TableProps<T> ): JSX.Element[] {
        return props.columns.map( ( column ) =>
            <th
                key={column.header}
                className={`${props.compact ? 'px-3 py-3' : 'px-6 py-3'} bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-no-wrap cursor-pointer hover:bg-gray-100`}
                onClick={() => changeSortSettings<T>(column.property)}
            >
                <div className={"flex items-center justify-between"}>
                    <span>{column.header}</span>
                    {/* @ts-ignore */ }
                    { sortColumn === column.property && sortOrder !== null && <i className={`bi ${sortOrder === "ASC" ? "bi-caret-up-fill" : "bi-caret-down-fill"}`}></i> }
                </div>
            </th>
        )
    }, [changeSortSettings, sortColumn, sortOrder])

    return <div>
        <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-x-4 gap-y-0 mb-4'}>
            <div className={"max-w-xs"}>
              <Input type={'text'} label={'Zoeken'} value={search} onChange={(v: string) => {setSearch(v); setPage(1)}} />
            </div>
            { props.availableFilters.map((filter, i) => <div key={i}>
              <Select label={filter.title} options={filter.options} value={getFilterValue(filter.property)} onChange={(v) => setFilterValue(filter.property, v)} />
            </div>) }
        </div>
        <DataTable {...props} data={paginatedData} headerMapper={sortingHeaderMapper} />
        <div className={'flex items-center justify-center space-x-4 mt-4 text-brand-on-surface'}>
            <button className={'h-8 w-8 rounded-full'} onClick={() => setPage(p => Math.max(p - 1, 1))}><FontAwesomeIcon icon={faChevronLeft} className={`${page <= 1 && 'text-slate-300'}`}/></button>
            <strong>
                {searchedData.length} resultaten
            </strong>
            <div>
                pagina
                <input type="number" className={'border text-center mx-2'} min={1} max={maxPages} value={page} onChange={(e) => setPage(Number(e.target.value))}/>
                / {maxPages}
            </div>
            <button className={'h-8 w-8 rounded-full'} onClick={() => setPage(p => Math.min(p + 1, maxPages))}><FontAwesomeIcon icon={faChevronRight} className={`${page >= maxPages && 'text-brand-primary'}`}/></button>
        </div>
    </div>
}

