import {FC} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import {usePersistentState} from "../util/usePersistentState";

export const Loading: FC<{children: React.ReactNode, loading: boolean}> = (props) => {
  const [sillyModeEnabled] = usePersistentState('silly-mode', false)
  if (props.loading) {
    return <div className={"flex items-center justify-center h-16"}>
      <FontAwesomeIcon icon={faCircleNotch} spin={!sillyModeEnabled} bounce={sillyModeEnabled} className={"text-brand-500 text-xl"} />
    </div>
  }
  return <>{props.children}</>
}

export const TextSkeleton: FC<{ size: "sm"|"md"|"lg"}> = (props) => {
  const width = classNames({
    'w-12': props.size === "sm",
    'w-24': props.size === "md",
    'w-32': props.size === "lg",
  })
  return <div className={`rounded-lg h-4 ${width} bg-slate-100 animate-pulse`}></div>
}