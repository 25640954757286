import {AbstractRepository} from "../repository";

export default class CacheRepository extends AbstractRepository {
    /**
     * Reset the Gateway API cache
     */
    public reset(): Promise<void> {
        return this.request<undefined>('POST', 'cache/reset').then(() => {})
    }
}
