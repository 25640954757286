import {useCallback, useEffect, useState} from 'react'
import {ApiError} from "./call";
import {useToasts} from "../toasts/toasts";
import {useRefreshEffect} from "../components/RefreshController";

const ERROR_MESSAGE = "Er is iets verkeerd gegaan"

export type FetchedEndpoint<T> = {
  resource: T | undefined,
  isLoading: boolean,
  error: unknown | undefined,
  reload: () => void
}

export function useApiEndpoint<T>(callback: () => Promise<T>, shouldLoad = true): FetchedEndpoint<T> {
  const [resource, setResource] = useState<T>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<unknown>()
  const {showToast} = useToasts()

  const fetchResource = useCallback(async (): Promise<void> => {
    setError(undefined)
    setIsLoading(true)
    try {
      const result = await callback()
      if (typeof result !== "undefined") {
        setResource(result)
      }
    } catch (error) {
      if (typeof error === "string") {
        setError(error)
        let errorString = error.length > 250 ? error.slice(0, 250) + "..." : String(error)
        showToast(ERROR_MESSAGE, errorString, 8000)
      } else if (error instanceof ApiError) {
        setError(error.message)
        let errorString = error.message.length > 250 ? error.message.slice(0, 250) + "..." : error.message
        showToast(ERROR_MESSAGE, errorString, 8000)
      } else {
        setError('Er is iets mis gegaan...')
        console.warn('Unknown err: ', error)
      }
    } finally {
      setIsLoading(false)
    }
  }, [setIsLoading, callback, setResource, setError, showToast])

  useRefreshEffect(() => {
    if (!shouldLoad || isLoading || error || typeof resource !== "undefined") {
      return
    }
    fetchResource()
  }, [shouldLoad, isLoading, error, resource])
  const reload = () => fetchResource()
  return {resource, isLoading, error, reload}
}
