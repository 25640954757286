import {Broadcast, useApiCall} from "@jane/lib/src/api"
import React from "react"
import {config} from "../config";
import {useNavigate} from "react-router-dom";
import { ContentContainer } from "@jane/lib/src/components/content/ContentContainer";
import {BroadcastsForm} from "../components/broadcasts-form";
import { PageHeader } from "@jane/lib/src/components/content/PageHeader";

export default function BroadcastsCreatePage(): JSX.Element {
  const {broadcasts} = useApiCall(config)
  const navigate = useNavigate()
  const onSubmit = async (saved: Partial<Broadcast>): Promise<void> => {
    if (!saved.subject || !saved.message || !saved.target_id || !saved.target_type) {
      return
    }
    await broadcasts.create({
      subject: saved.subject,
      message: saved.message,
      status: "draft",
      target_id: saved.target_id,
      target_type: saved.target_type,
    }).then(() => {
      navigate(`/broadcasts`)
    })
  }

  return <ContentContainer>
    <PageHeader>Publicaties</PageHeader>
    <BroadcastsForm broadcast={{}} onBroadcastSubmitted={onSubmit} step={1} onStepChange={() => {}} />
  </ContentContainer>
}