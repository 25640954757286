import React, {FC, ReactNode} from "react";

interface ContentContainerProps {
  children: ReactNode
  size?: "sm"|"md"|"lg" // defaults to MD
}

export const ContentContainer: FC<ContentContainerProps> = props => {
  const maxWidth = {
    "sm": "max-w-screen-sm",
    "md": "max-w-screen-lg",
    "lg": "max-w-screen-xl",
  }[props.size ?? "md"]
  return <div className={`${maxWidth} mx-auto`}>
    {props.children}
  </div>
}