import { ContentContainer } from "@jane/lib/src/components/content/ContentContainer"
import { PageHeader } from "@jane/lib/src/components/content/PageHeader"
import { Button } from "@jane/lib/src/components/form/Button";
import React, {ReactNode, useCallback, useState} from "react"
import {usePermissions} from "../resources/PermissionsHook";
import {useNavigate} from "react-router-dom";
import {SettingMap, useApiCall, useApiEndpoint} from "@jane/lib/src/api";
import {config} from "../config";
import { Loading } from "@jane/lib/src/components/Loading";
import { useToasts } from "@jane/lib/src/toasts/toasts";
import { Input } from "@jane/lib/src/components/form/Input";
import { Toggle } from "@jane/lib/src/components/form/Toggle";
import { Card } from "@jane/lib/src/components/Card";

export default function SettingsPage(): JSX.Element {

  return <ContentContainer>
    <PageHeader>Instellingen</PageHeader>
    <LogsLink />
    <SocialCardPreferences />
    <AppPreferences />
    <CachePreferences />
  </ContentContainer>
}

function LogsLink(): JSX.Element {
  const { canAccessLogs } = usePermissions()
  const navigate = useNavigate()
  if (! canAccessLogs) {
    return <></>
  }
  return <SettingsContainer title={"Logboek"} description={"Het logboek bevat alle acties die in het systeem worden uitgevoerd. Zowel door gebruikers als door het systeem zelf."}>
    <Card title={'Systeem logs'}>
      <Button type={"primary"} size={'sm'} onClick={() => navigate('/logs')} text={'Systeem logs bekijken'} />
    </Card>
  </SettingsContainer>
}

function SocialCardPreferences(): JSX.Element {
  const navigate = useNavigate()
  return <SettingsContainer title={"Sociale kaarten"} description={"Per locatie kan een sociale kaart worden ingesteld. Per locatie die een sociale kaart heeft kan naast de link naar de kaart, een afbeelding van de regio worden toegevoegd."}>
    <Card title={'Sociale kaarten'}>
      <Button type={"primary"} size={'sm'} onClick={() => navigate('/settings/social_card')} text={'Sociale kaarten instellen'} />
    </Card>
  </SettingsContainer>
}

function AppPreferences(): JSX.Element {
  const { settings: settingsApi } = useApiCall(config)
  const { resource: settings, isLoading } = useApiEndpoint(() => settingsApi.all())

  return <SettingsContainer title={"Applicatie voorkeuren"} description={"Deze instellingen bepalen hoe een deel van de applicatie er uit ziet, maar ook fungeert."}>
    <Card title={'Voorkeuren'}>
      <Loading loading={isLoading || typeof settings === "undefined"}>
        <EditSettings settings={settings!} />
      </Loading>
    </Card>
  </SettingsContainer>
}

function EditSettings(props: {settings: SettingMap}): JSX.Element {
  const [isLoading, setIsLoading] = useState(false)
  const { showToast } = useToasts()
  const [settings, setSettings] = useState<SettingMap>(props.settings)
  const { settings: settingsApi } = useApiCall(config)
  const [isDirty, setIsDirty] = useState(false)

  const onSettingChange = useCallback((name: string, newValue: string|number|boolean): void => {
    setIsDirty(true)
    setSettings(oldSettings => {
      let newSettings = JSON.parse(JSON.stringify(oldSettings)) // Create an anonymous copy of the object.
      newSettings[name] = newValue
      return newSettings
    })
  }, [setSettings])

  const save = async (): Promise<void> => {
    setIsLoading(true)
    await settingsApi.update(settings)
    showToast('Opgeslagen', "Uw wijzigingen zijn opgeslagen.")
    setIsLoading(false)
    setIsDirty(false)
  }
  return <div className={"flex flex-col space-y-3"}>
    { Object.keys(settings).map( (key: string) => <EditSetting key={key} name={key} value={settings[key]} onChange={onSettingChange} />) }
    <Button disabled={isLoading} type={isDirty ? "primary" : "secondary"} size={'sm'} onClick={save} text={'Opslaan'} />
  </div>
}

// eslint-disable-next-line no-unused-vars
function EditSetting(props: {name: string, value: string|number|boolean, onChange: (name: string, newValue: string|number|boolean) => void}): JSX.Element {
  if (typeof props.value === "number") {
    return <Input label={props.name.replaceAll('_', ' ')} type="number" value={props.value} onChange={val => props.onChange(props.name, Number(val))} />
  } else if (typeof props.value === "boolean") {
    return <Toggle value={props.value} onChange={event => props.onChange(props.name, event)} />
  } else {
    return <Input label={props.name.replaceAll('_', ' ')} type="text" value={props.value} onChange={val => props.onChange(props.name, val)} />
  }
}

function CachePreferences(): JSX.Element {
  const [isLoading, setIsLoading] = useState(false)
  const { showToast } = useToasts()
  const { cache } = useApiCall(config)

  const reset = (): void => {
    setIsLoading(true)
    cache.reset()
      .then(() => {
        showToast('Cache reset', "De cache is gereset.")
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return <SettingsContainer title={"Applicatie cache"} description={"Om de snelheid van Jane te verhogen wordt veel-opgevraagde informatie gecachet. Soms kan het wenselijk zijn om de cache te resetten. Hierbij zullen de eerst volgende dataverzoeken vanuit Jane de meest de meest up-to-date informatie bij externe systemen opvragen."}>
    <Card title={'Applicatie cache'}>
      <Button disabled={isLoading} size={'sm'} type={"primary"} onClick={reset} text={'Reset cache'} />
    </Card>
  </SettingsContainer>

}

function SettingsContainer(props: {title: string, description: string, children: ReactNode}): JSX.Element {
  return <div className={"mt-5"}>
    <h2>{props.title}</h2>
    <div className="mt-3 md:space-x-6 flex flex-col md:flex-row items-start">
      <div className={"w-full md:flex-1"}>
        <p className={"pl-3 max-w-md py-1 tracking-wide text-slate-600 border-l-2 border-blue-400 text-sm"}>{props.description}</p>
      </div>
      <div className={"md:w-1/2 rounded-sm"}>{props.children}</div>
    </div>
  </div>
}