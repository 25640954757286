import ChatView from "@jane/lib/src/chat";
import React, {FC} from "react"
import {config} from "../config";
import {useEmployeesData} from "../resources/EmployeeContext";
import {useTeamData} from "../resources/TeamContext";


const ChatPage: FC = () => {
  const {teams} = useTeamData()
  const {employees} = useEmployeesData()
  return <ChatView
    config={config}
    rocketChatHostname={config.rocketChatHostname}
    teams={teams ?? []}
    employees={employees ?? []}
  />
}
export default ChatPage

