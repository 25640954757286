import React, {FC} from "react";
import {Paragraph} from "../Paragraph";
import {Card} from "../Card";
import {useKeycloak} from "@react-keycloak/web";
import {SplashScreen} from "./SplashScreen";
import {faShuffle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const UnauthenticatedPlaceholder: FC<{reason?: string}> = (props) => {
  const {keycloak} = useKeycloak()
  return <SplashScreen>
    <div className={'min-h-screen flex flex-col items-center justify-center'}>
      <div className={"max-w-md"}>
        <Card title={"Inloggen vereist."}>
          <Paragraph>Om de applicatie te gebruiken moet je ingelogd zijn. Je wordt automatisch doorverwezen. Als dit niet gebeurt kun je ook op 'inloggen' klikken.</Paragraph>
          { props.reason && <Paragraph>Reden: {props.reason}</Paragraph>}
          {keycloak.authenticated ? <>
            <button className={"bg-brand-primary text-brand-on-primary font-medium rounded-full px-2 py-2 w-full"} onClick={() => keycloak.logout()}><FontAwesomeIcon className={"mr-2"} icon={faShuffle} />Wisselen van account</button>
          </> : <>
            <button className={"bg-brand-primary text-brand-on-primary font-medium rounded-full px-2 py-2 w-full"} onClick={() => keycloak.login()}><FontAwesomeIcon className={"mr-2"} icon={faShuffle} />Inloggen</button>
          </>}

        </Card>
      </div>
    </div>
  </SplashScreen>
}
