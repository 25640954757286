import {Client, useApiCall, useApiEndpoint} from '@jane/lib/src/api'
import { useAuth } from '@jane/lib/src/auth/hook'
import React from 'react'
import {createContext, ReactNode, useContext} from "react"
import {usePermissions} from "./PermissionsHook";
import {config} from "../config";

interface ClientContextType {
    clients: Client[]
    isLoading: boolean
    error: string|undefined
}

export const ClientContext = createContext<ClientContextType>({} as ClientContextType)

export function ClientContextProvider({ children } :{children: ReactNode}): JSX.Element {
    const { isLoggedIn } = useAuth()


    const { clients } = useApiCall(config)
    const { canListClients } = usePermissions()
    const { resource, isLoading, error } = useApiEndpoint(() => clients.all(), isLoggedIn && canListClients)

    return <ClientContext.Provider value={({
        clients: resource || [],
        isLoading,
        error: (typeof(error) === "string") ? error : undefined,
    })}>{ children }</ClientContext.Provider>
}

export function useClientData(): ClientContextType {
    return useContext(ClientContext)
}
