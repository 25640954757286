export class FileUpload {
  file: File;
  field: string;
  constructor(file: File, field: string) {
    this.file = file;
    this.field = field;
  }
}
export async function callApi<T> (baseUrl: string, token: string|undefined, clientContextId: string|undefined, endpoint: string, method: "GET"|"POST"|"PATCH"|"PUT"|"DELETE", body?: unknown, requestOptions?: {fileFormName?: string}): Promise<T> {
  const options: RequestInit = {
    method,
    headers: {
      'Accept': 'application/json',
      ...(! (body instanceof File || body instanceof FileUpload) ? {'Content-Type': 'application/json'} : {}),
      ... (!! token ? {'Authorization': `Bearer ${token}`} : {}),
      ... (!! clientContextId ? {'X-Context-Client-ID': clientContextId} : {}),
    },
  }
  if (typeof body !== "undefined") {
    if (body instanceof File) {
      // Add file to request body
      const form = new FormData()
      form.append(requestOptions?.fileFormName ?? "picture", body, body.name)
      options.body = form
    } else if (body instanceof FileUpload) {
      // Add file to request body
      const form = new FormData()
      form.append(body.field, body.file, body.file.name)
      options.body = form
    } else {
      options.body = JSON.stringify(body)
    }
  }
  return fetch(`${baseUrl}/${endpoint}`, options)
    .then(async (response): Promise<[Response, "JSON"|"TEXT", T|string]> => {
      if (response.headers.get('Content-Type') === "application/json") {
        const json: T = await response.json()
        return [response, "JSON", json]
      } else if (response.status === 204) {
        return [response, "TEXT", ""]
      } else {
        const text: string = await response.text()
        return [response, "TEXT", text]
      }
    })
    .then(([response, type, value]: [Response, "JSON"|"TEXT", T|string]): T => {
      if (response.status < 200 || response.status >= 300) {
        throw type === "JSON" ? ApiError.fromJson(value) : ApiError.fromText(value as string)
      }
      return value as T
    })
}
export async function postApiForm<T> (baseUrl: string, token: string|undefined, clientContextId: string|undefined, endpoint: string, body: FormData): Promise<T> {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      ... (!! token ? {'Authorization': `Bearer ${token}`} : {}),
      ... (!! clientContextId ? {'X-Context-Client-ID': clientContextId} : {}),
    },
  }
  options.body = body

  return fetch(`${baseUrl}/${endpoint}`, options)
    .then(async (response): Promise<[Response, "JSON"|"TEXT", T|string]> => {
      if (response.headers.get('Content-Type') === "application/json") {
        const json: T = await response.json()
        return [response, "JSON", json]
      } else if (response.status === 204) {
        return [response, "TEXT", ""]
      } else {
        const text: string = await response.text()
        return [response, "TEXT", text]
      }
    })
    .then(([response, type, value]: [Response, "JSON"|"TEXT", T|string]): T => {
      if (response.status < 200 || response.status >= 300) {
        throw type === "JSON" ? ApiError.fromJson(value) : ApiError.fromText(value as string)
      }
      return value as T
    })
}

interface ErrorBag {[field: string]: string[]}
export class ApiError extends Error {
  errorBag: ErrorBag|string|undefined
  constructor(message: string, errorBag?: ErrorBag) {
    super(message);
    this.errorBag = errorBag
  }

  static fromText(message: string): ApiError {
    return new ApiError(message, {})
  }

  static fromJson(json: unknown): ApiError {
    console.log(json)
    if (json !== null && typeof json === "object") {
      const jsonObject = json as {[property: string]: string|number|boolean|null|Array<unknown>|ErrorBag}
      if (!! jsonObject['message']) {
        return this.fromText(jsonObject["message"].toString())
      }
      if (!! jsonObject['error']) {
        return new ApiError('Error', jsonObject['error'] as ErrorBag);
      }
    }
    return this.fromText('Er is iets verkeerd gegaan')
  }

  toString(): string {
    return `Error: ${this.message}`
  }
}
