import { AuthContextType, ParsedToken } from "./types"
import React, {createContext, useCallback, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useNavigate} from "react-router-dom";
import {ConfigInterface} from "../config";
//
export const AuthContext = createContext<AuthContextType>({} as AuthContextType)
//
export function AuthContextProvider(props: { config: ConfigInterface, children: React.ReactNode }): JSX.Element {
  const { keycloak }       = useKeycloak()
  const navigate = useNavigate()
  const pathname = window.location.pathname
  const onGuestRoute       = props.config.guestRoutes.includes(pathname)
  const onPublicRoute      = props.config.publicRoutes.includes(pathname)
  const [selectedClient, setSelectedClient] = useState<string|undefined>()

  /**
   * The JWT token that is provided by keycloak contains some user information.
   */
  const user: ParsedToken | undefined = keycloak?.tokenParsed

  const roles: string[] = user?.realm_access?.roles || []

  /**
   * Check if the current user has any the roles described in the function
   * parameters.
   */
  const hasRoles = useCallback((requiredRoles: string[]): boolean => {
    const validRoles = requiredRoles.filter(role => roles.includes(role))
    return validRoles.length > 0
  }, [roles])

  /**
   * Assemble the context state.
   */
  const context: AuthContextType = {
    isLoggedIn: keycloak?.authenticated || false,
    onPublicRoute,
    onGuestRoute,
    hasRoles,
    user,
    selectedClient,
    selectClient: setSelectedClient,
    hasSelectedClient: selectedClient !== undefined,
    shouldSelectClient: hasRoles(['client_contact']),
    token: keycloak?.token || "logged_out",
    tokenIssuedAt: keycloak?.tokenParsed?.iat,
  }

  /**
   * Render the provider with the rendered state.
   */
  return <AuthContext.Provider value={context}>{props.children}</AuthContext.Provider>
}
