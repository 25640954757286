import {AbstractRepository} from "../repository";

export type KeycloakUser = {
    sub: string
    email: string
    enabled: boolean
    name: string
    given_name: string
    family_name: string
    preferred_username: string
}
export type Employee = KeycloakUser & {
    id: number
    role_name: string
    employee_picture_url: string|null
    employee_number: string|null
    team_id: number|null
    actions?: string[]|null
}

export default class EmployeesRepository extends AbstractRepository {
    /**
     * List all employees
     */
    public all(): Promise<Employee[]> {
        return this.request<{data: Employee[]}>('GET', 'employees')
            .then(this.unpackDataCollectionResponse)
    }

    /**
     * Update role
     */
    public updateRole(subject: string, role: "employee"|"coordinator"): Promise<void> {
        return this.request('POST', `set_user_permission/${subject}`, { role })
            .then(() => {})
    }

    /**
     * Update employee picture
     */
    public updatePicture(subject: string, picture: File): Promise<void> {
        return this.request('POST', `employee/${subject}/picture`, picture)
            .then(() => {})
    }

    /**
     * Invite employee account
     */
    public invite(subject: string): Promise<void> {
        return this.request('POST', `employee/${subject}/invite`, 'POST')
            .then(() => {})
    }

    /**
     * Reset 2FA for an employee
     */
    public reset2fa(subject: string): Promise<void> {
        return this.request('POST', `reset_2fa/${subject}`, 'POST')
            .then(() => {})
    }
}
