import {AbstractRepository} from "../repository";
import {FileUpload} from "../call";

export type Document = {
    id: number
    client_id: number
    file_name: string
    file_type: string
    view_file_url: string
    needs_signing: boolean
    description: string
    status: number
    tags: string[]
    created_at: string
    updated_at: string
}

export default class DocumentsRepository extends AbstractRepository {
    /**
     * List all teams in the system
     */
    public all(): Promise<Array<Document>> {
        return this.request<{data: {[id: number]: Document}}>('GET', 'my_documents')
            .then(this.unpackDataCollectionResponse)
    }

    public signDocument(documentId: number, signature: File): Promise<void> {
        return this.request('POST', `document/${documentId}/sign`, new FileUpload(signature, 'signature'))
            .then(() => {})
    }

}
