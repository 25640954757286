import { ContentContainer } from "@jane/lib/src/components/content/ContentContainer"
import { PageHeader } from "@jane/lib/src/components/content/PageHeader"
import { Button } from "@jane/lib/src/components/form/Button"
import { Paragraph } from "@jane/lib/src/components/Paragraph"
import {faAdd, faGraduationCap} from "@fortawesome/free-solid-svg-icons";
import {useApiCall, useApiEndpoint} from "@jane/lib/src/api";
import {config} from "../config";
import { Loading } from "@jane/lib/src/components/Loading";
import { LearnModule } from "@jane/lib/src/api/repositories/learn-repository";
import React, {useState} from "react";
import { Input } from "@jane/lib/src/components/form/Input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useModal} from "../components/ModalProvider";
import {AddLearnModuleModal} from "../modals/AddLearnModuleModal";
import {useNavigate} from "react-router-dom";
import { Breadcrumbs } from "@jane/lib/src/components/content/Breadcrumbs";

export const LearnModulesListPage: React.FC = () => {
  const {learn} = useApiCall(config)
  const {resource, reload} = useApiEndpoint(() => learn.listModules())
  const [search, setSearch] = useState('')
  const addModal = useModal({title: "Nieuw onderwerp", body: <AddLearnModuleModal onFinished={() => reload()} />})
  const modules = resource?.filter(module => module.name.toLowerCase().includes(search.toLowerCase()) || module.description.toLowerCase().includes(search.toLowerCase())) ?? []
  return <ContentContainer>
    <Breadcrumbs currentPage={'Overzicht'} crumbs={[
      {label: 'Kennisbank', href: '/learn'}
    ]} />
    <PageHeader>Kennisbank</PageHeader>
    <section className={"flex justify-between"}>
      <div className={"max-w-2xl"}>
        <Paragraph>
          De kennisbank bestaat uit onderwerpen, welke bestaan uit onderdelen. Waaronder de informatie uit het traject beschikbaar is in meerdere kaarten.<br />
          Kaarten kunnen tekst bevatten, afbeeldingen, video’s of interactieve vragen.
        </Paragraph>
      </div>
      <Button text={'Nieuw onderwerp'} size={'sm'} type={'primary'} icon={faAdd} onClick={() => addModal.open()} />
    </section>
    <section>
      <Loading loading={resource === undefined}>
        <Input label={'Zoeken'} type={'search'} placeholder={'Zoeken'} value={search} onChange={setSearch} />
        {modules.map(module => <ModuleRow key={module.id} module={module} />)}
      </Loading>
    </section>
  </ContentContainer>
}

const ModuleRow: React.FC<{module: LearnModule}> = ({module}) => {
  const navigate = useNavigate()
  return <div
    className={'mt-4 bg-brand-primary-container text-brand-on-primary-container px-4 py-3 rounded-xl flex items-stretch'}>
    <div className={"flex-1 flex flex-col items-start justify-between"}>
      <div className={'flex items-center'}>
        <FontAwesomeIcon icon={faGraduationCap} className={'text-sm'}/>
        <h2 className={"text-lg font-medium mx-3"}>{module.name}</h2>
        {module.mandatory && <span
          className={'px-2 text-xs font-medium rounded border border-brand-on-primary-container'}>Verplicht</span>}
      </div>
      <p className={'text-sm font-light'}>{module.description}</p>
    </div>
    <div className={"h-14 border-l-2 mx-3 border-brand-primary opacity-20"}></div>
    <div className={'w-24 flex flex-col items-center justify-around'}>
      <span className={"text-sm"}>Toegewezen</span>
      <span className={"font-semibold"}>123</span>
    </div>
    <div className={"h-14 border-l-2 mx-3 border-brand-primary opacity-20"}></div>
    <div className={'w-24 flex flex-col items-center justify-around'}>
      <span className={"text-sm"}>Afgerond</span>
      <span className={"font-semibold"}>123</span>
    </div>
    <div className={"h-14 border-l-2 mx-4 border-brand-primary opacity-20"}></div>
    <div className={"flex items-center justify-center"}>
      <Button text={'Details'} size={'sm'} type={'primary'} onClick={() => navigate(`/learn/${module.id}`)} />
    </div>
  </div>
}