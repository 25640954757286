
import {useEffect, useMemo} from "react"
import {SavedMatch, useApiCall, useApiEndpoint} from "../api";
import {useInterval} from "../util/useInterval";
import {ConfigInterface} from "../config";

export type MatchMap = {
    [matchType: string]: {
        [matchId: number]: SavedMatch[]
    }
}
export function useMatches(config: ConfigInterface) {
    const {telephone} = useApiCall(config)
    const {resource, reload} = useApiEndpoint(() => telephone.browseMatches())
    useEffect(() => {
      reload()
    }, [])
    useInterval(reload, 1000 * 5)
    const matchedMap = useMemo(() => {
        return resource?.reduce((acc, match): MatchMap => {
            if (!(match.match_type in acc)) {
                acc[match.match_type] = {}
            }
            if (!(match.match_id in acc[match.match_type])) {
                acc[match.match_type][match.match_id] = []
            }
            acc[match.match_type][match.match_id].push(match)
            return acc
        }, {} as MatchMap) ?? ({} as MatchMap)
    }, [resource])
    return {
        matchedMap,
    }
}