import {ClientContactDetails, useApiCall, useApiEndpoint} from "@jane/lib/src/api"
import { useAuth } from "@jane/lib/src/auth/hook"
import {createContext, ReactNode, useContext, useMemo, useState} from "react"
import {usePermissions} from "./PermissionsHook";
import {config} from "../config";


interface ClientContactContextType {
    clientContacts: ClientContactDetails[]
    isLoading: boolean
    // eslint-disable-next-line no-unused-vars
    add: (clientContact: ClientContactDetails) => void
    error: string|undefined
}
export const ClientContactContext = createContext<ClientContactContextType>({} as ClientContactContextType)

export function ClientContactContextProvider({ children } :{children: ReactNode}): JSX.Element {
    const { isLoggedIn, onPublicRoute, onGuestRoute } = useAuth()

    const { clientContacts } = useApiCall(config)
    const { canListClientContacts } = usePermissions()
    const { resource, isLoading, error } = useApiEndpoint(() => clientContacts.all(), isLoggedIn && ! onPublicRoute && ! onGuestRoute && canListClientContacts)
    const [localChanges, setLocalChanges] = useState<ClientContactDetails[]>([])

    const add = (clientContact: ClientContactDetails) => {
        setLocalChanges(x => [...x, clientContact])
    }

    const allClientContacts = useMemo(() => {
        if (resource === undefined) {
            return localChanges
        }
        const clientContactMap = {} as Record<number, ClientContactDetails>
        resource.forEach(clientContact => {
            clientContactMap[clientContact.id] = clientContact
        })
        localChanges.forEach(clientContact => {
            clientContactMap[clientContact.id] = clientContact
        })
        return Object.values(clientContactMap)
    }, [localChanges, resource])

    return <ClientContactContext.Provider value={({
        clientContacts: allClientContacts,
        isLoading,
        add,
        error: (typeof(error) === "string") ? error : undefined,
    })}>{ children }</ClientContactContext.Provider>
}

export function useClientContactData(): ClientContactContextType {
    return useContext(ClientContactContext)
}
