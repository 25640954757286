import {AbstractRepository} from "../repository";

export type Location = {
    id: number
    name: string
    parentObjectId: number
    materializedPath: string
}
export type SocialCard = {
    socialCardType: string,
    socialCardUrl: string|null
} & Location

export default class LocationsRepository extends AbstractRepository {
    /**
     * List all locations globally
     */
    public all(): Promise<Location[]> {
        return this.request<{data: Location[]}>('GET', 'locations')
            .then(this.unpackDataCollectionResponse)
    }

    /**
     * Fetch the location(s) of the client/client-contact
     */
    public myLocations(): Promise<Array<Location|SocialCard>> {
        return this.request<{data: {[id: number]: Location|SocialCard}}>('GET', 'my_locations')
            .then(this.unpackDataCollectionResponse)
    }

    /**
     * List social cards.
     */
    public socialCards(): Promise<SocialCard[]> {
        return this.request<{ data: SocialCard[] }>('GET', 'social_cards')
            .then(this.unpackDataCollectionResponse)
    }

    /**
     * Create new social card
     */
    public createSocialCard(location: Location, url: string): Promise<void> {
        return this.request('POST', 'social_card', {
            location_id: location.id,
            url,
        }).then(() => {})
    }
}
