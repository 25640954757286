import React, {useMemo } from "react"
import {useClientData} from "../resources/ClientContext";
import {usePermissions} from "../resources/PermissionsHook";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import { ContentContainer } from "@jane/lib/src/components/content/ContentContainer";
import { Loading } from "@jane/lib/src/components/Loading";
import CircleForm from "../components/circle-form";
import TasksList from "../components/tasks-list";
import NotificationPreferencesForm from "../components/notification-preferences-form";
import PasswordResetForm from "../components/password-reset-form";
import InviteForm from "../components/invite-form";
import SyncStatus from "../components/sync-status";
import ProfileCard from "../components/profile-card";
import { PageHeader } from "@jane/lib/src/components/content/PageHeader";

export default function ClientDetails(): JSX.Element {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { clients, isLoading } = useClientData()
  const permissions = usePermissions()
  const client = useMemo(() => {
    return clients.find((e) => e.id === parseInt(params.id ?? '0'))
  }, [clients, params.id])

  function goBack() {
    if (searchParams.has('taskContext')) {
      navigate(`/tasks/${searchParams.get('taskContext')}`)
    } else {
      navigate(-1)
    }
  }

  return <ContentContainer>
    <Loading loading={isLoading}>
      { client ? <>
        <a onClick={() => goBack()} className="underline cursor-pointer text-brand-primary"><a>Terug naar overzicht</a></a>

        <PageHeader>Client: { client.name }</PageHeader>

        <div className={"flex"}>
          <div className={"my-6 w-64 relative hidden md:block"}>
            <div className={"sticky top-0 flex flex-col space-y-2 py-2"}>
              <a className={"underline cursor-pointer text-brand-primary"} href={"#profile"}>Profiel</a>
              { permissions.canManageClientNetwork && <a className={"underline cursor-pointer text-brand-primary"} href={"#netwerk"}>Netwerk</a>}
              { permissions.canAccessClientTasks && <a className={"underline cursor-pointer text-brand-primary"} href={"#tasks"}>Meldingen</a>}
              { permissions.canManageClientNotifications && <a className={"underline cursor-pointer text-brand-primary"} href={"#notifications"}>Voorkeuren voor meldingen</a>}
              { permissions.canResetClientPassword && <a className={"underline cursor-pointer text-brand-primary"} href={"#password_reset"}>Wachtwoord reset</a>}
              { permissions.canSendClientInvite && <a className={"underline cursor-pointer text-brand-primary"} href={"#network"}>Netwerk</a>}
              <a className={"underline cursor-pointer text-brand-primary"} href={"#sync_status"}>Sync status</a>
            </div>
          </div>
          <div className={"flex flex-col space-y-8"}>
            <ProfileCard person={client} />
            { client.sub !== null ? <>
              { permissions.canManageClientNetwork && <CircleForm subject={client.sub} />}
              { permissions.canAccessClientTasks && <TasksList subject={client.sub} />}
              { permissions.canManageClientNotifications && <NotificationPreferencesForm subject={client.sub} />}
              { permissions.canResetClientPassword && <PasswordResetForm subject={client.sub} />}
              { permissions.canSendClientInvite && <InviteForm subject={client.sub} type="client" />}
            </> : <></> }
            <SyncStatus type="client" objectId={client.id} />
          </div>
        </div>
      </> : <>
        We kunnen de client die u zoekt niet vinden.
      </> }
    </Loading>
  </ContentContainer>
}