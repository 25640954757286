import {AbstractRepository} from "../repository";

export type AgendaItem = {
    id: string|null
    title: string
    description: string
    start_time: string
    end_time: string
    notes: string
    address: string[]
    duration: number
    network_needed: boolean
    accepted_by: string|null
}

export default class AgendaRepository extends AbstractRepository {

    /**
     * Fetch the agenda of the client
     */
    public get(): Promise<Array<AgendaItem>> {
        return this.request<{data: AgendaItem[]}>('GET', 'my_agenda')
            .then(this.unpackDataCollectionResponse)
    }

    public acceptInvite(appointmentId: string): Promise<void> {
        return this.request('POST', `agenda/appointment/${appointmentId}/accept`)
            .then(() => {})
    }
}
