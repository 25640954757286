import React, {useCallback, useMemo, useState} from "react";
import {ChatMessage, useChatContext} from "./provider";
import {Employee, Team, useApiCall} from "../api";
import {Loading} from "../components/Loading";
import {ConfigInterface} from "../config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Button} from "../components/form/Button";

interface SidebarItem {
    conversationId: string
    name: string
    lastMessage?: ChatMessage
    unread: number
    selected: boolean
}

export const ChatSidebar: React.FC<{config: ConfigInterface}> = (props) => {

    const {conversations, messages, currentConversationId, canAddChats, loaded} = useChatContext()
    const [search, setSearch] = useState("")
    const [mode, setMode] = useState<"list"|"create">("list")
    const messagesSortedByTime = useMemo(() => {
        return messages.sort((a, b) => a.timestamp.getTime() < b.timestamp.getTime() ? 1 : -1)
    }, [messages])

    const sidebarItems = useMemo(() => {
        const items: SidebarItem[] = []
        for (const conversation of conversations) {
            const lastMessage: ChatMessage|undefined = messagesSortedByTime.filter(m => m.conversationId === conversation.id)[0]
            items.push({
                conversationId: conversation.id,
                name: conversation.name,
                lastMessage: lastMessage,
                unread: 0, // TODO: Implement unread messages count.
                selected: currentConversationId === conversation.id,
            })
        }
        return items.sort((a,b) => {
            if (a.lastMessage === undefined || b.lastMessage === undefined) {
                return 0
            }
            return a.lastMessage.timestamp.getTime() < b.lastMessage.timestamp.getTime() ? 1 : -1
        })
    }, [conversations, messagesSortedByTime, currentConversationId])

    if (! loaded) {
        return <div className={"w-64 xl:w-96 border-r border-gray-100"}></div>
    }


    // Allow users to add new chats
    if (mode === "create") {
        return <div className={"w-64 xl:w-96 border-r border-gray-100 overflow-y-scroll"}>
            {/* Header */}
            <div className={"h-16 flex items-center justify-between px-4 border-b border-gray-100"}>
                <div className={"text-lg font-bold"}>Chat toevoegen</div>
                <button className={"hover:bg-gray-50 h-7 w-7 flex items-center justify-center rounded-full"} onClick={() => setMode("list")}>
                    <FontAwesomeIcon icon={faTimes} className={'text-xl'} />
                </button>
            </div>
            <div className={"p-4"}>
                <AddChatForm config={props.config} onComplete={() => setMode("list")} />
            </div>
        </div>
    }

    // Show all available chats
    return <div className={"w-64 xl:w-96 border-r border-gray-100 overflow-y-scroll"}>
        {sidebarItems.length === 0 ? <div className={"flex flex-col justify-center p-8"}>
            {canAddChats  ? <>
                <span className={"font-medium"}>Je hebt nog geen chats.</span>
                <p className={"text-gray-500 mt-3"}>Start door er een aan te maken. Dit kan voor een team, een specifieke groep, of een-op-een met een collega.</p>
                <Button type={'secondary'} size={'sm'} icon={faAdd} onClick={() => setMode("create")} text={'Maak nieuwe chat'} />
            </> : <>
                <span className={"font-medium"}>U heeft nog geen chats.</span>
                <p className={"text-gray-500 mt-3"}>Om toegang te krijgen tot een chat heeft u een uitnodiging nodig.</p>
            </>}
        </div> : <>
            {canAddChats && <div className={"m-2 flex flex-col items-stretch"}><Button type={'secondary'} size={'sm'} icon={faAdd} onClick={() => setMode("create")} text={'Maak nieuwe chat'} /></div>}
            <label className={"bg-gray-100 rounded-lg text-gray-800 px-3 py-2 m-2 text-sm flex"}>
                <i className={"bi bi-search"}></i>
                <input type={"search"} value={search} onChange={e => setSearch(e.target.value)} className={"flex-1 outline-none bg-transparent ml-2"} placeholder={"Gesprek zoeken..."} />
            </label>
        </>}
        {sidebarItems.filter(c => c.name.toLowerCase().includes(search.toLowerCase())).sort((a,b) => {
            const tA = a.lastMessage?.timestamp.getTime() ?? 0
            const tB = b.lastMessage?.timestamp.getTime() ?? 0
            return tA < tB ? 1 : -1
        }).map((v,k) => {
            return <Conversation
                key={k}
                conversationId={v.conversationId}
                name={v.name}
                lastMessage={v.lastMessage}
                unreadCount={v.unread}
                active={v.selected}
            />
        })}
    </div>
}

const Conversation: React.FC<{conversationId: string, name: string, lastMessage?: ChatMessage, unreadCount: number, active: boolean}> = props => {
    const {users, selectConversation} = useChatContext()

    const timeText = useMemo(() => {
        if (! props.lastMessage) {
            return ""
        }
        const t = props.lastMessage.timestamp
        const currentDate = Math.floor(new Date().getTime() / 1000 / 60 / 60 / 24)
        const messageDate = Math.floor(t.getTime() / 1000 / 60 / 60 / 24)
        const daysAgo = currentDate - messageDate
        if (daysAgo === 0) {
            return t.toLocaleTimeString('nl-NL', { hour: 'numeric', hour12: false, minute: '2-digit'})
        } else if (daysAgo === 1) {
            return 'Gisteren'
        } else {
            return t.toLocaleDateString('nl-NL', { day: 'numeric', month: 'short', year: 'numeric'})
        }
    }, [props.lastMessage])

    const lastMessageText = useMemo(() => {
        if (! props.lastMessage){
            return "-"
        }
        const user = users.find(u => u.id === props.lastMessage!.userId)
        let text = props.lastMessage.file ? "Bijlage" : props.lastMessage.message
        if (user) {
            text = `${user.name.split(' ')[0]}: ${props.lastMessage.file ? "Bijlage" : props.lastMessage.message}`
        }
        return text.length > 30 ? text.substring(0, 30) + "…" : text
    }, [props.lastMessage, users])

    return <div className={`m-2 rounded-lg px-4 py-3 flex flex-col ${ props.active ? "bg-blue-500" : "hover:bg-blue-50"} cursor-pointer transition-all`} onClick={() => selectConversation(props.conversationId)}>
        <div className={"flex items-center justify-between"}>
            {/* Title */}
            <div className={`font-bold ${props.active ? "text-white" : "text-black"} mb-2`}>{ props.name }</div>
            {/* Time */}
            <div className={`${props.active ? "text-white" : "text-gray-500"} text-xs`}>{ timeText }</div>
        </div>
        <div className={"flex items-center justify-between"}>
            {/* Last message */}
            <div className={`${props.active ? "text-white" : "text-gray-500"}`}>{ lastMessageText }</div>
            { props.unreadCount > 0 && !props.active && <>
                <div className={"bg-blue-500 px-1 rounded-full text-xs text-white flex items-center justify-center"} style={{ minWidth: "1.5em", minHeight: "1.5em"}}>
                    <span>{ props.unreadCount }</span>
                </div>
            </> }
        </div>
    </div>
}

interface SearchableEmployee { type: "employee", data: Employee }
interface SearchableTeam { type: "team", data: Team }
type Searchable = SearchableTeam|SearchableEmployee

const AddChatForm: React.FC<{onComplete: () => void, config: ConfigInterface}> = props => {

    const [search, setSearch] = useState<string>("")

    const {employees, teams, loadChatRooms} = useChatContext()
    const searchableEmployees = useMemo<SearchableEmployee[]>(
        () => employees
            .filter(e => e.sub !== null)
            .map(e => ({type: "employee", data: e}))
        , [employees]
    )
    const searchableTeams = useMemo<SearchableTeam[]>(() => teams.map(e => ({type: "team", data: e})), [teams])

    const [selected, setSelected] = useState<Searchable[]>([])
    const isSelected = useCallback((searchable: Searchable) => {
        return selected.find(s => s.type === searchable.type && s.data.id === searchable.data.id) !== undefined
    }, [selected])

    const select = useCallback((searchable: Searchable) => {
        if (! isSelected(searchable)) {
            setSelected(p => [...p, searchable])
        }
    }, [isSelected])

    const deselect = useCallback((searchable: Searchable) => {
        setSelected(p => p.filter(s => !(s.type === searchable.type && s.data.id === searchable.data.id)))
    }, [])

    const canSelectTeam = useMemo(() => selected.length === 0, [selected])
    const hasSelectedTeam = useMemo(() => selected.find(s => s.type === "team") !== undefined, [selected])

    const searchableItems = useMemo(() => {
        let items: Searchable[] = []
        items = items.concat(searchableEmployees)
        if (canSelectTeam) {
            items = items.concat(searchableTeams)
        }
        return items
    }, [searchableTeams, searchableEmployees, canSelectTeam])

    const searchResults = useMemo(() => {
        if (search.length <= 2) {
            return []
        }
        return searchableItems.filter(item => {
            return ! isSelected(item) && item.data.name.toLowerCase().includes(search.toLowerCase())
        })
    }, [search, searchableItems, isSelected])

    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState("")

    const {chat: chatApi} = useApiCall(props.config)

    async function submit() {
        setIsLoading(true)
        let type: "group"|"message"|"team"
        if (selected.length > 1) {
            type = "group"
        } else if (selected[0].type === "team") {
            type = "team"
        } else {
            type = "message"
        }
        const concerns = selected.map(s => s.data.id)
        const conversations = await chatApi.add(type, concerns, name)
        loadChatRooms(conversations)
        setIsLoading(false)
        props.onComplete()
    }

    if (isLoading) {
        return <div className={"flex flex-col items-center justify-center py-32"}>
          <Loading loading={true}>&nbsp;</Loading>
            <p className={"text-center text-grey-500 mt-4"}>Chat starten...</p>
        </div>
    }

    return <div>
        <div className={"flex justify-between items-center mb-4"}>
            <div>{selected.length} geselecteerd</div>
            <button disabled={selected.length === 0 || (selected.length > 1 && name.length === 0)} className={"btn btn-link text-primary font-bold"} onClick={() => submit()}>Chat starten</button>
        </div>

        <div>
            { selected.map((selectedItem, i) => {
                return <button key={i} className={"block mb-2 py-2 px-3 flex justify-between items-center w-full rounded-lg bg-brand-primary"} onClick={() => deselect(selectedItem)}>
                    <span className={"text-white font-bold"}>{selectedItem.data.name}</span>
                    <div className={"ml-2"}>
                      <FontAwesomeIcon icon={faTimes} className={'text-white'} />
                    </div>
                </button>
            }) }
        </div>

        { selected.length > 1 && <label className={"uppercase text-gray-500 text-sm mt-4 font-medium w-full"}>
            Naam <span className={"text-danger"}>*</span>
            <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder={"Groepsnaam..."} className={"w-full py-2 px-3 mt-2 bg-gray-50 rounded-lg border-0 focus:bg-gray-100 focus:outline-none"}/>
        </label> }

        <label className={"uppercase text-gray-500 text-sm mt-4 font-medium w-full"}>
            Zoeken
            <input type="search" value={search} onChange={e => setSearch(e.target.value)} placeholder={"Start chat met..."} autoFocus={true} className={"w-full py-2 px-3 mt-2 bg-gray-50 rounded-lg border-0 focus:bg-gray-100 focus:outline-none"}/>
        </label>

        {selected.length === 0 && searchResults.length === 0 && <p className={'text-center my-8 text-gray-500 text-sm'}>Selecteer ten minste een persoon om een chat te starten. Zoek met het bovenstaande veld.</p>}

        {hasSelectedTeam ? <>
            <p className={'text-center my-8 text-gray-500 text-sm'}>
                Een chat met een team kan enkel dat team bevatten.
            </p>
        </> : <>
            <div>
                { searchResults.map((result, i) => {
                    return <button key={i} className={"block mt-2 py-2 px-3 flex justify-between items-center w-full rounded-lg hover:bg-gray-50"} onClick={() => select(result)}>
                        <div className={"flex flex-col justify-between items-start text-left"}>
                            <span className={"text-black font-bold"}>{result.data.name}</span>
                            {result.type === "team" ? <>
                                <div className={"text-gray-500 text-sm"}>Team</div>
                            </> : <>
                                <div className={"text-gray-500 text-sm truncate whitespace-nowrap w-64"}>Medewerker &middot; {result.data.preferred_username}</div>
                            </> }
                        </div>
                        <div className={"ml-2"}>
                            <i className={"bi bi-plus-circle text-xl text-gray-500 font-bold"}></i>
                        </div>
                    </button>
                }) }
            </div>
        </> }

    </div>
}
