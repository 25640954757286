import React, {ChangeEvent, FC, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import {HoverHint} from "../content/HoverHint";
import {copyToClipboard} from "../../util/copyToClipboard";

const widthOptions = {
  'md': 'max-w-md',
  'full': 'max-w-full',
}
interface StringLikeInputProps {
  type: "text"|"email"|"password"|"textarea"|"search"
  label: string
  value: string
  onChange: (newValue: string) => void
  placeholder?: string
  focus?: boolean
  readonly?: boolean
  inputRef?: React.RefObject<HTMLInputElement|HTMLTextAreaElement>
  maxWidth?: keyof typeof widthOptions
}
interface NumberLikeInputProps {
  type: "number"
  label: string
  value: number
  onChange: (newValue: number) => void
  placeholder?: string
  focus?: boolean
  readonly?: boolean
  inputRef?: React.RefObject<HTMLInputElement>
  maxWidth?: keyof typeof widthOptions
}
interface DateLikeInputProps{
  type: "date"
  label: string
  value: Date
  onChange: (newValue: Date) => void
  placeholder?: string
  focus?: boolean
  readonly?: boolean
  inputRef?: React.RefObject<HTMLInputElement>
  maxWidth?: keyof typeof widthOptions
}
interface TimeLikeInputProps{
  type: "time"
  label: string
  value: Date
  onChange: (newValue: Date) => void
  placeholder?: string
  focus?: boolean
  readonly?: boolean
  inputRef?: React.RefObject<HTMLInputElement>
  maxWidth?: keyof typeof widthOptions
}
type InputProps = StringLikeInputProps | DateLikeInputProps | TimeLikeInputProps | NumberLikeInputProps
export const Input: FC<InputProps> = props => {
  return <label className={`flex flex-col text-sm font-medium text-brand-900 w-full ${props.maxWidth ?? widthOptions['md']}`}>
    {props.label}
    <InputControl {...props} />
  </label>
}

export const InputControl: FC<InputProps> = props => {
  if (props.type === "textarea") {
    return <textarea
      autoFocus={props.focus}
      className={`border-2 border-slate-200 outline-blue-700 ${props.readonly ? 'text-slate-500 cursor-not-allowed' : 'text-black'} rounded text-base font-normal px-2 py-2 mt-1 h-32 w-full`}
      placeholder={props.placeholder}
      value={props.value}
      readOnly={props.readonly}
      ref={props.inputRef as React.RefObject<HTMLTextAreaElement>}
      onChange={(e) => props.onChange(e.target.value)}
    />
  }
  if (props.type === "date") {
    return <input
      autoFocus={props.focus}
      type={"date"}
      className={`border-2 border-slate-200 outline-blue-700 ${props.readonly ? 'text-slate-500 cursor-not-allowed' : 'text-black'} rounded text-base font-normal px-2 mt-1 h-10 w-full`}
      placeholder={props.placeholder}
      readOnly={props.readonly}
      ref={props.inputRef}
      onChange={e => props.onChange(new Date(e.target.value))} value={`${props.value.getFullYear()}-${String(props.value.getMonth()+1).padStart(2, '0')}-${String(props.value.getDate()).padStart(2, '0')}`}
    />
  }
  if (props.type === "number") {
    return <input
      autoFocus={props.focus}
      type={"number"}
      className={`border-2 border-slate-200 outline-blue-700 ${props.readonly ? 'text-slate-500 cursor-not-allowed' : 'text-black'} rounded text-base font-normal px-2 mt-1 h-10 w-full`}
      placeholder={props.placeholder}
      readOnly={props.readonly}
      ref={props.inputRef}
      onChange={e => props.onChange(Number(e.target.value))} value={`${props.value}`}
    />
  }
  if (props.type === "time") {
    return <input
      autoFocus={props.focus}
      type={"time"}
      className={`border-2 border-slate-200 outline-blue-700 ${props.readonly ? 'text-slate-500 cursor-not-allowed' : 'text-black'} rounded text-base font-normal px-2 mt-1 h-10 w-full`}
      placeholder={props.placeholder}
      readOnly={props.readonly}
      ref={props.inputRef}
      onChange={e => props.onChange(new Date(`1970-01-01T${e.target.value}`))} value={`${String(props.value.getHours()).padStart(2, '0')}:${String(props.value.getMinutes()).padStart(2, '0')}`}
    />
  }

  return <input
    autoFocus={props.focus}
    type={props.type}
    className={`border-2 border-slate-200 outline-blue-700 ${props.readonly ? 'text-slate-500 cursor-not-allowed' : 'text-black'} rounded text-base font-normal px-2 mt-1 h-10 w-full`}
    placeholder={props.placeholder}
    value={props.value}
    readOnly={props.readonly}
    ref={props.inputRef as React.RefObject<HTMLInputElement>}
    onChange={(e) => props.onChange(e.target.value)}
  />
}
interface FileInputProps {
  label: string
  onChange: (files: File[]) => void
  accept?: string
  placeholder?: string
  focus?: boolean
  readonly?: boolean
  inputRef?: React.RefObject<HTMLInputElement|HTMLTextAreaElement>
  maxWidth?: keyof typeof widthOptions
}
export const FileInput: FC<FileInputProps> = props => {
  const [value, setValue] = useState<File[]>([])
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setValue(Array.from(e.target.files))
      props.onChange(Array.from(e.target.files))
    }
  }
  return <label className={"flex flex-col text-sm font-medium text-brand-900 w-full max-w-md"}>
    {props.label}
    <input type={"file"} accept={props.accept} onChange={onChange} className={"hidden"} />
    <div className={"border-2 border-slate-200 outline-blue-700 rounded mt-1 cursor-pointer"}>
      {value.length === 0 ? <>
        <div className={"m-3"}>
          <FontAwesomeIcon icon={fa.faUpload} className={"mr-2"} />
          Selecteer bestand...
        </div>
      </> : <>
        {value.map((file, i) => (
          <div className={"m-3"} key={i}>
            <FontAwesomeIcon icon={fa.faPaperclip} className={"mr-2"} />
            {file.name}
          </div>
        ))}
      </>}
    </div>
  </label>
}

export const CopyableInput: FC<Pick<StringLikeInputProps, "label"|"value">> = props => {
  const [justCopied, setJustCopied] = useState(false)
  const copy = async () => {
    await copyToClipboard(props.value)
    setJustCopied(true)
    setTimeout(() => setJustCopied(false), 1000)
  }
  return <label className={"flex flex-col text-sm font-medium text-brand-900 w-full max-w-md"}>
    {props.label}
    <div className={"border-2 border-slate-200 outline-blue-700 rounded mt-1 relative"}>
      <input
        type={"text"}
        readOnly={true}
        className={"text-black text-base font-normal h-10 px-2 w-full"}
        value={props.value}
      />
      <button className={'absolute top-1 right-1 h-8 w-8 rounded hover:bg-slate-100'} onClick={() => copy()}>
        <HoverHint hint={'Copy to clipboard'}>
          <div className={"h-full w-full flex items-center justify-center"}>
            <FontAwesomeIcon icon={justCopied ? fa.faCheck : fa.faCopy} bounce={justCopied} className={"text-brand-800"} />
          </div>
        </HoverHint>
      </button>
    </div>
  </label>
}