import {Client, Team} from "@jane/lib/src/api"
import { ContentContainer } from "@jane/lib/src/components/content/ContentContainer";
import { Loading } from "@jane/lib/src/components/Loading";
import React, {useMemo} from "react"
import {useClientData} from "../resources/ClientContext";
import {useTeamData} from "../resources/TeamContext";
import DynamicDataTable from "../components/data-table/dynamic-data-table";
import { HoverHint } from "@jane/lib/src/components/content/HoverHint";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";
import { PageHeader } from "@jane/lib/src/components/content/PageHeader";

interface ClientWithTeam extends Client {
  team_name: string
}

export default function ClientsListPage(): JSX.Element {
  const { clients, isLoading } = useClientData()
  return <ContentContainer>
    <PageHeader>Cliënten</PageHeader>

    <Loading loading={isLoading}>
      { !! clients && <FilteredClientTable clients={clients} />  }
    </Loading>
  </ContentContainer>
}

function FilteredClientTable(props: {clients: Client[]}): JSX.Element {

  const { teams } = useTeamData()
  const teamMap: {[id: number]: Team} = useMemo(() => {
    let teamMap: {[id: number]: Team} = {}
    teams.forEach(team => teamMap[team.id] = team)
    return teamMap
  }, [teams])

  const enrichedClients = useMemo(() => props.clients
    .map((client: Client): ClientWithTeam => {
      return {
        ...client,
        team_name: client.team_id !== null && teamMap[client.team_id] ? teamMap[client.team_id].name : '-'
      }
    }), [props.clients, teamMap])

  return <div>
    <ClientTable clients={enrichedClients} />
  </div>
}

function ClientTable({clients}: {clients: ClientWithTeam[]}): JSX.Element {
  return <DynamicDataTable<ClientWithTeam>
    searchableProperties={['client_number', 'family_name', 'preferred_username', 'team_name']}
    availableFilters={[
      {
        title: 'Sync status',
        property: 'sub',
        options: {
          'all': 'Alle',
          'synced': 'Jane account',
          'sync_error': 'Niet in systeem',
        },
        defaultValue: 'all',
        filterCallback: (row, filter) => {
          if (filter === 'synced') {
            return !!row.sub
          } else if (filter === 'sync_error') {
            return !row.sub
          }
          return true
        }
      },
    ]}
    keyProperty={'id'}
    data={clients}
    columns={[
      {header: 'Naam', property: 'id', transform: (id, client) => {
          let prefix: string
          switch (client.gender) {
            case "F": prefix = 'Mevrouw'; break
            case "M": prefix = 'Meneer'; break
            default: prefix = 'Persoon'
          }
          return <div className={"flex items-center"}>
            { client.sub ? <>
              <HoverHint hint={'Jane Account'} key={id}>
                <FontAwesomeIcon icon={faCheckCircle} className={"text-green-400 text-lg"} />
              </HoverHint>

            </> : <>
              <HoverHint hint={'Niet in systeem'} key={id}>
                <FontAwesomeIcon icon={faExclamationTriangle} className={"text-amber-500 text-lg"} />
              </HoverHint>
            </>}
            <div className={"flex-1 ml-3"}>
              <small className={'text-gray-500'}>{client.client_number}</small>
              <div>
                {prefix} <strong>{client.family_name}</strong>
              </div>
            </div>
          </div>
        }},
      {header: 'Gebruikersnaam', property: 'preferred_username'},
      {header: 'Team', property: 'team_name'},
      {header: 'Acties', property: 'id', transform: (id) => <NavLink className={'text-brand-primary'} to={`/clients/${id}`}>Beheer</NavLink>}
    ]}
  />
}
