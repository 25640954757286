import React, {FC} from "react";
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const Card: FC<{children: React.ReactNode, title?: string, id?: string, icon?: IconDefinition}> = ({children, title, id, icon}) => {
  return <div className={"bg-brand-primary-container text-brand-on-primary-container rounded-xl"} id={id}>
    {(icon||title) &&<header className={"pt-4 px-6 text-lg font-medium"}>
      {icon && <span className={"mr-2"}><FontAwesomeIcon icon={icon} /></span>}
      {title && <span>{title}</span>}
    </header>}
    <div className={"py-4 px-6"}>
      {children}
    </div>
  </div>
}
