import {Task, useApiCall} from "@jane/lib/src/api"
import { Card } from "@jane/lib/src/components/Card";
import { Button } from "@jane/lib/src/components/form/Button";
import { Loading } from "@jane/lib/src/components/Loading";
import React, {useEffect, useState} from "react"
import {config} from "../config";

export default function TasksList(props: {subject: string}): JSX.Element {

    const { tasks: tasksApi } = useApiCall(config)
    const limit = 3

    const [limited, setLimited] = useState(true)
    const [isLoadingTasks, setIsLoadingTasks] = useState(false)
    const [tasks, setTasks] = useState<Task[]>()

    useEffect(() => {
        if (! tasks && ! isLoadingTasks) {
            setIsLoadingTasks(true)
            tasksApi.forSubject(props.subject).then(tasks => {
                setTasks(tasks)
                setIsLoadingTasks(false)
            })
        }
    }, [tasks, isLoadingTasks, tasksApi, props.subject])

    return <Card title={'Meldingen'} id={"tasks"}>
            <Loading loading={! tasks || isLoadingTasks}>
                { ! tasks || tasks!.length === 0 ? <>
                    Deze client heeft geen meldingen gehad
                </> : <>
                    { tasks.sort((a,b) => +new Date(b.created_at) - +new Date(a.created_at)).slice(0, (limited ? limit : undefined)).map((task, key) => {
                        const status: string = {
                            "acknowledged": "Gelezen",
                            "expired": "Verlopen",
                            "pending": "Ongelezen",
                        }[task.status]


                       return <div key={key} className={"border border-brand-secondary rounded-lg mb-4"}>
                            <div className="flex">
                                <div className={`flex justify-center items-center px-2 text-xs tracking-wide uppercase text-brand-primary font-medium border-r border-brand-secondary min-w-24 mr-4`}>
                                    { status }
                                </div>
                                <div className={"py-2"}>
                                    <div>
                                        <strong>Deadline: {formatDateString(task.deadline)},&nbsp;&nbsp;&nbsp;Gelezen: {task.acknowledged_at ? formatDateString(task.acknowledged_at) : '-'}</strong>
                                    </div>
                                    { task.description.split("\n").map((line, i) => <div key={i} className={'text-sm'}>{line}</div> )}
                                </div>
                            </div>
                        </div>
                    })}
                    {limited && tasks.length > limit ? <div className="mt-2">
                        <Button size={'sm'} text={'Laat oude meldingen zien'} type={'secondary'} onClick={() => setLimited(false)} />
                    </div> : <></>}
                </>}
            </Loading>
    </Card>
}

function formatDateString(dateString: string): string {
    const date = new Date(dateString)
    const dateDateText = date.toLocaleDateString('nl-NL', { day: 'numeric', month: 'numeric', year: 'numeric'})
    const dateTimeText = date.toLocaleTimeString('nl-NL', { hour: 'numeric', hour12: false, minute: '2-digit'})
    return `${dateDateText} ${dateTimeText}`
}
