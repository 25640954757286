import {AbstractRepository} from "../repository";

export type TaskType = "agenda_change"|"agenda_remove"|"sign_document"|"call"
export type TaskItemType = "planned_visit"|"task"
export type TaskStatus = "pending"|"acknowledged"|"expired"

export type Task = {
    id: number
    type: TaskType
    performer_id: number
    performer_sub: string
    performer_type: string
    user_id: number
    user_sub: string
    user_type: string
    item_title: string
    item_type: TaskItemType
    item_id: string
    description: string
    status: TaskStatus
    deadline: string
    acknowledged_at: string|null
    acknowledged_by_sub: string|null
    created_at: string
    updated_at: string
}

export default class TasksRepository extends AbstractRepository {

    /**
     * List tasks of current user
     */
    public all(): Promise<Array<Task>> {
        return this.request<{data: {[id: number]: Task}}>('GET', 'my_tasks')
            .then(this.unpackDataCollectionResponse)
    }

    /**
     * List tasks for subject
     */
    public forSubject(subject: string): Promise<Array<Task>> {
        return this.request<{data: {[id: number]: Task}}>('GET', `tasks/${subject}`)
            .then(this.unpackDataCollectionResponse)
    }

    /**
     * Get a single task by ID
     */
    public get(id: number): Promise<Task> {
        return this.request<{data: Task}>('GET', `task/${id}`)
            .then(r => r.data)
    }

    /**
     * Acknowledge a task
     */
    public ack(id: number): Promise<void> {
        return this.request('POST', `my_tasks/${id}/ack`).then(() => {})
    }
}
