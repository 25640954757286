import { useApiCall } from "@jane/lib/src/api";
import { LearnModule } from "@jane/lib/src/api/repositories/learn-repository";
import { Checkbox } from "@jane/lib/src/components/form/Checkbox";
import { Input } from "@jane/lib/src/components/form/Input";
import {FC, useState} from "react";
import {ModalFooter} from "../components/ModalProvider";
import {config} from "../config";

export const EditLearnModuleModal: FC<{module: LearnModule, onFinished: () => void}> = (props) => {
  const {learn} = useApiCall(config)
  const [name, setName] = useState(props.module.name)
  const [description, setDescription] = useState(props.module.description)
  const [mandatory, setMandatory] = useState(props.module.mandatory)
  const save = async () => {
    await learn.editModule(props.module.id, name, description, mandatory)
    props.onFinished()
  }
  return <div className={"flex flex-col space-y-3"}>
    <Input type={'text'} label={'Naam'} value={name} onChange={setName} />
    <Input type={'textarea'} label={'Omschrijving'} value={description} onChange={setDescription} />
    <Checkbox label={'Verplicht onderwerp'} description={'Medewerkers zien dit onderdeel in hun app overzicht zolang ze het nog niet hebben afgerond.'} checked={mandatory} onChange={setMandatory} />
    <ModalFooter text={'Opslaan'} onSubmit={save} />
  </div>
}