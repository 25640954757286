import {AbstractRepository} from "../repository";
export interface Broadcast {
    id: number
    subject: string
    message: string
    status: "draft"|"sent"
    target_id: number
    target_type: "clients"|"employees"
    updated_at: string
    attachment_path: string|null
    attachment_name: string|null
}

export default class BroadcastRepository extends AbstractRepository {

    /**
     * Fetch all broadcasts
     */
    public async get(): Promise<Array<Broadcast>> {
        return this.request<{data: Broadcast[]}>('GET', 'my_broadcasts')
            .then(this.unpackDataCollectionResponse)
    }

    /**
     * Create a new broadcast
     */
    public async create(broadcast: Omit<Broadcast, "id"|"updated_at"|"attachment_path"|"attachment_name">): Promise<{id: number}> {
        return this.request<{data: Broadcast}>('POST', `broadcasts`, broadcast)
            .then(data => data.data)
    }

    public async update(broadcastId: number, broadcast: Partial<Broadcast>): Promise<void> {
        return this.request<{data: []}>('PUT', `broadcasts/${broadcastId}`, broadcast)
            .then(() => undefined)
    }

    public async updateAttachment(broadcastId: number, attatchment: File): Promise<void> {
        return this.request<{data: []}>('POST', `broadcasts/${broadcastId}/upload-attachment`, attatchment, {fileFormName: "attachment"})
            .then(() => undefined)
    }

    public async getSendQuote(broadcastId: number): Promise<number> {
        return this.request<{data: {recipients: number}}>('GET', `broadcasts/${broadcastId}/send-quote`)
            .then((response) => response.data.recipients)
    }

    public async sendBroadcast(broadcastId: number): Promise<void> {
        return this.request<{}>('POST', `broadcasts/${broadcastId}/send`)
            .then(() => undefined)
    }
}
