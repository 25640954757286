import {Team, useApiCall, useApiEndpoint} from "@jane/lib/src/api";
import React, {useMemo, useState} from "react"
import {JaneEmployee} from "../resources/EmployeeContext";
import {config} from "../config";
import {useTeamData} from "../resources/TeamContext";
import { useToasts } from "@jane/lib/src/toasts/toasts";
import { Badge } from "@jane/lib/src/components/content/Badge";
import { Loading } from "@jane/lib/src/components/Loading";
import { Button } from "@jane/lib/src/components/form/Button";
import { Card } from "@jane/lib/src/components/Card";

export default function TeamsForm(props: {employee: JaneEmployee}): JSX.Element {
    const { teams: teamsApi } = useApiCall(config)
    const { teams: teamsResource, isLoading: teamDataLoading } = useTeamData()
    const {resource: secondaryTeams, isLoading: secondaryTeamsLoading, reload} = useApiEndpoint(() => teamsApi.secondaryTeams(props.employee.id))

    const teams = useMemo(() => teamsResource || [], [teamsResource])
    const [isEditing, setIsEditing] = useState(false)
    const assignedTeamIds = (secondaryTeams || []).map(st => st.ons_team_id)
    const primaryTeam: number|undefined = (secondaryTeams || []).find(t => t.primary)?.ons_team_id

    const teamsAndTheirParents: {team: Team, parents: number[]}[] = useMemo(() => {
        function getParents(team: Team): Team[] {
            if (team.parentObjectId !== null) {
                const parent = teams.find(x => x.id === team.parentObjectId)
                if (parent !== undefined) {
                    return [...getParents(parent), team]
                }
            }
            return [team]
        }
        function sortTeams(a: number[], b: number[]): number {
            if (a.length === 0 || b.length === 0) {
                return a.length - b.length
            }
            const [aFirst] = a
            const [bFirst] = b
            if (aFirst === bFirst) {
                return sortTeams(a.slice(1), b.slice(1))
            }
            return bFirst - aFirst
        }

        return teams.map(team => ({
            team,
            parents: getParents(team).map(t => t.id),
        })).sort((a, b) => sortTeams(a.parents, b.parents))
    }, [teams])

    /**
     * An array of team display data on teams that are enabled.
     */
    const enabledTeams = useMemo(() => teamsAndTheirParents.filter(tatp => {
        // Enabled teams are teams that are either directly enabled, or, in case of a primary team, are children of the primary team.
        if (assignedTeamIds.some(at => at === tatp.team.id)) {
            // Team is assigned to the user.
            return true
        }
        return primaryTeam !== undefined && tatp.parents.some(p => p === primaryTeam);
    }), [teamsAndTheirParents, assignedTeamIds, primaryTeam])

    /**
     * If a team's ID is in this array, it should be shown because it is either enabled, or a child or parent is.
     */
    const visibleTeams = useMemo(() => enabledTeams.flatMap(et => et.parents), [enabledTeams])

    /**
     * Teams that should be rendered,
     */
    const teamsToRender = useMemo(() => {
        return isEditing ? teamsAndTheirParents : teamsAndTheirParents.filter(t => visibleTeams.includes(t.team.id))
    }, [teamsAndTheirParents, visibleTeams, isEditing])


    const [formState, setFormState] = useState<number[]>([])
    const [isSaving, setIsSaving] = useState(false)
    const { showToast } = useToasts()

    /**
     * All enabled teams must be saved, with the exception of the primary team, and the children of the primary team.
     */
    const teamsToSave = useMemo(() => {
        return teamsAndTheirParents
            .filter(t => formState.includes(t.team.id))
            .filter(t => primaryTeam === undefined || ! t.parents.includes(primaryTeam))
            .map(t => t.team.id)
    }, [teamsAndTheirParents, formState, primaryTeam])

    async function save() {
        setIsSaving(true)
        console.log('saving...', teamsToSave)
        await teamsApi.updateSecondaryTeams(props.employee.id, teamsToSave)
            .then(() => {showToast('Opgeslagen', 'De coordinator heeft nu toegang tot deze teams')})
            .catch(() => {showToast('Error', "Er is iets verkeerd gegaan bij het opslaan.")})
        setIsSaving(false)
        setIsEditing(false)
        reload()
    }
    function enableTeam(id: number) {
        setFormState(old => [...old, id])
    }
    function disableTeam(id: number) {
        setFormState(old => old.filter(x => x !== id))
    }

    if (isEditing) {
        return <Card title={"Teams"} id={"teams"}>
            <div>
                <div className={"mb-4 text-sm"}>
                    {teamsToRender.map((teamDisplayData, i) => {
                        const teamEnabled = formState.includes(teamDisplayData.team.id)
                        const teamPrimary = teamDisplayData.team.id === primaryTeam
                        const teamPrimaryOrChildOfPrimary = primaryTeam !== undefined && teamDisplayData.parents.includes(primaryTeam)
                        return <div key={i} style={{marginLeft: `${(teamDisplayData.parents.length - 1)*2}rem`}} className={"flex items-center"}>
                            { i !== 0 && "└"}
                            <div className={"mx-1" + (teamPrimary ? " font-weight-bold" : "") + (teamEnabled ? "" : " text-muted")}>
                                <label>
                                    <input
                                        disabled={teamPrimaryOrChildOfPrimary}
                                        type="checkbox"
                                        className={"mr-1"}
                                        checked={teamEnabled}
                                        onChange={(event) => { event.target.checked ? enableTeam(teamDisplayData.team.id) : disableTeam(teamDisplayData.team.id) }}
                                    />
                                    { teamDisplayData.team.name }
                                </label>
                            </div>
                            { teamPrimary && <Badge className={"mx-1"}>Primair</Badge>}
                        </div>
                    })}
                </div>
                <div className={"space-x-2 flex items-center"}>
                    <Button disabled={isSaving} onClick={() => save()} text={ isSaving ? "Opslaan..." : "Opslaan" } size={'sm'} type={'primary'} />
                    { ! isSaving && <Button onClick={() => setIsEditing(false)} size={'sm'} text={'Afbreken'} type={'secondary'} />}
                </div>
            </div>
        </Card>
    }


    const enabledTeamIds = enabledTeams.map(td => td.team.id)
    function startEditing() {
        setFormState(enabledTeamIds)
        setIsEditing(true)
    }

  return <Card title={"Teams"} id={"teams"}>
        <div>
            <div className={"mb-4 text-sm"}>
                <Loading loading={secondaryTeamsLoading || teamDataLoading}>
                    {teamsToRender.map((teamDisplayData, i) => {
                        const teamEnabled = enabledTeamIds.includes(teamDisplayData.team.id)
                        const teamPrimary = teamDisplayData.team.id === primaryTeam
                        return <div key={i} style={{marginLeft: `${teamDisplayData.parents.length - 1}rem`}} className={"flex items-center"}>
                            { i !== 0 && "└"}
                            <div className={"mx-1" + (teamPrimary ? " font-weight-bold" : "") + (teamEnabled ? "" : " text-muted")}>
                                { teamDisplayData.team.name }
                            </div>
                            { teamPrimary && <Badge className={"mx-1 bg-blue-500"}>Primair</Badge>}
                            { teamEnabled && <Badge className={"mx-1 bg-green-500"}>In team</Badge>}
                        </div>
                    })}
                </Loading>
            </div>
            <div>
                <Button text={'Bewerken'} onClick={() => startEditing()} size={'sm'} type={'primary'} />
            </div>
        </div>
    </Card>
}
