import {FC, ReactNode} from "react";

export const HoverHint: FC<{children: ReactNode, hint: string}> = ({ children, hint}) => {

  return <span className={"relative group"}>
    {children}
    <span className={"hidden group-hover:flex absolute bottom-[120%] left-[50%] -translate-x-1/2 transform w-64  justify-center items-center"}>
      <span className={"bg-black text-white text-xs px-2 mt-1 py-1 rounded relative shadow"}>
        {hint}
        {/*Arrow*/}
        <div className={"absolute bottom-0 left-[50%] -translate-x-1/2 translate-y-1/2 rotate-45 h-2 w-2 bg-black"}></div>
      </span>
    </span>
    {/*<span className={"hidden group-hover:flex absolute top-[100%] left-[50%] -translate-x-1/2 transform w-64  justify-center items-center"}>*/}
    {/*  <span className={"bg-black text-white text-xs px-2 mt-1 py-1 rounded relative shadow"}>*/}
    {/*    {hint}*/}
    {/*    /!*Arrow*!/*/}
    {/*    <div className={"absolute top-0 left-[50%] -translate-x-1/2 -translate-y-1/2 rotate-45 h-2 w-2 bg-black"}></div>*/}
    {/*  </span>*/}
    {/*</span>*/}
  </span>
}