import {Team, useApiCall, useApiEndpoint} from "@jane/lib/src/api"
import React, {createContext, ReactNode, useContext} from "react"
import {useAuth} from "@jane/lib/src/auth/hook"
import {usePermissions} from "./PermissionsHook"
import {config} from "../config";

interface TeamContextType {
    teams: Team[]
    isLoading: boolean
    error: string|undefined
    reload: () => void
}

export const TeamContext = createContext<TeamContextType>({} as TeamContextType)

export function TeamContextProvider({ children } :{children: ReactNode}): JSX.Element {
    const { isLoggedIn } = useAuth()

    const { teams } = useApiCall(config)
    const { canListTeams } = usePermissions()
    const { resource, isLoading, error, reload } = useApiEndpoint(() => teams.all(), isLoggedIn && canListTeams)

    return <TeamContext.Provider value={({
        teams: resource || [],
        isLoading,
        reload,
        error: (typeof(error) === "string") ? error : undefined,
    })}>{ children }</TeamContext.Provider>
}

export function useTeamData(): TeamContextType {
    return useContext(TeamContext)
}
