import { useApiCall } from "@jane/lib/src/api"
import { Card } from "@jane/lib/src/components/Card"
import { Button } from "@jane/lib/src/components/form/Button";
import { useToasts } from "@jane/lib/src/toasts/toasts";
import React, {useState} from "react"
import {config} from "../config";

export default function InviteForm(props: {subject: string, type: "client"|"employee"}): JSX.Element {

    const [isLoading, setIsLoading] = useState(false)
    const { employees, clients } = useApiCall(config)
    const { showToast } = useToasts()
    function save() {
        setIsLoading(true)
        const inviteFunc = props.type === "client" ? (sub: string) => clients.invite(sub) : (sub: string) => employees.invite(sub)
        inviteFunc(props.subject)
            .then(() => {
                showToast('Uitnodiging verstuurd', 'E-mail verzonden')
            })
            .finally(() => {
                setIsLoading(false)
            })
    }
    return <Card title={"Uitnodiging voor Jane versturen"} id={"invite"}>
      <p className={"text-sm mb-4"}>
        U kunt deze persoon (opnieuw) uitnodigen voor Jane. Wanneer deze persoo`n
        al een uitnodiging heeft geaccepteerd kan de mail gebruikt worden om het
        wachtwoord opnieuw in te stellen.
      </p>
      <Button type={'primary'} size={'sm'} onClick={save} disabled={ isLoading } text={'Verstuur uitnodiging'} />
    </Card>
}
