import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquare} from "@fortawesome/free-regular-svg-icons";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";

interface CheckboxProps {
  label: string;
  description?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({label, description, checked, onChange}) => {
  return <label className={"flex items-center cursor-pointer text-on-surface hover:bg-brand-surface-variant max-w-md px-3 py-2 rounded-lg"}>
    <FontAwesomeIcon className={"text-brand-primary text-xl"} icon={checked ? faSquareCheck : faSquare} />
    <input type={'checkbox'} checked={checked} onChange={(e) => onChange(e.target.checked)} className={"hidden"} />
    <div className={"flex flex-col ml-4"}>
      <span className={description ? 'text-sm font-medium' : 'text-sm'}>{label}</span>
      {description && <div className={"mt-1 text-sm"}>
        {description}
      </div>}
    </div>
  </label>
}