import { useApiCall } from "@jane/lib/src/api"
import { Card } from "@jane/lib/src/components/Card";
import { Button } from "@jane/lib/src/components/form/Button";
import { Toggle } from "@jane/lib/src/components/form/Toggle";
import { Loading } from "@jane/lib/src/components/Loading";
import { useToasts } from "@jane/lib/src/toasts/toasts";
import React, {FormEvent, useEffect, useState} from "react"
import {config} from "../config";

export default function NotificationPreferencesForm(props: {subject: string}): JSX.Element {
    const { notificationPreferences } = useApiCall(config)
    const { showToast } = useToasts()
    const [error, setError] = useState<string>()
    const [isSaving, setIsSaving] = useState(false)
    const [isLoadingPermissions, setIsLoadingPermissions] = useState(false)
    const [hasLoadedPermissions, setHasLoadedPermissions] = useState(false)
    const [isDirty, setIsDirty] = useState(false)

    const [whatsapp, setWhatsapp] = useState(false)
    const [email, setEmail] = useState(false)
    const [push, setPush] = useState(false)

    const isLoading = isSaving || isLoadingPermissions

    useEffect(() => {
        if (! isLoadingPermissions && ! hasLoadedPermissions) {
            setIsLoadingPermissions(true)
            notificationPreferences.subjectPreferences(props.subject).then(preferences => {
                setWhatsapp(preferences.whatsapp_enabled)
                setEmail(preferences.email_enabled)
                setPush(preferences.push_enabled)
                setHasLoadedPermissions(true)
                setIsLoadingPermissions(false)
            })
        }
    }, [isLoadingPermissions, hasLoadedPermissions, notificationPreferences, props.subject])

    function save(event: FormEvent) {
        event.preventDefault()
        setError('')
        setIsSaving(true)
        notificationPreferences.updateSubjectPreferences(props.subject, {
            whatsapp_enabled: whatsapp,
            push_enabled: push,
            sms_enabled: false,
            email_enabled: email,
        })
            .then(() => {
                showToast('Wijzigingen opgeslagen', 'Melding voorkeuren opgeslagen')
                setIsDirty(false)
            })
            .catch(err => {
                setError(err)
            })
            .finally(() => {
                setIsSaving(false)
            })
    }
    return <Card title={"Voorkeuren voor meldingen"}>

            <Loading loading={isLoadingPermissions || !hasLoadedPermissions}>
                <form onSubmit={save} className={"space-y-4"}>
                    <Toggle label={'Push meldingen'} value={push} onChange={setPush} />
                    <Toggle label={'E-mail meldingen'} value={email} onChange={setEmail} />

                    <Toggle label={'Whatsapp meldingen'} value={whatsapp} onChange={setWhatsapp} />
                    { isDirty && <>
                        <Button type={"primary"} disabled={ isLoading } text={"Wijzigingen opslaan"} size={'sm'} submit />
                    </>}
                    { error === "" ? "" : <>
                        <div className={"text-danger mt-2"}>{ error }</div>
                    </>}
                </form>
            </Loading>

    </Card>
}
