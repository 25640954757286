import { useAuth } from "@jane/lib/src/auth/hook"

export function usePermissions() {
    const { hasRoles } = useAuth()
    return {
        canAccessClients: hasRoles(['manager', 'coordinator']),
        canManageEmployees: hasRoles(['manager', 'coordinator']),
        canAccessEmployees: hasRoles(['manager', 'coordinator']),
        canSeeSubjectIdOnPerson: hasRoles(['manager']),
        canManageSettings: hasRoles(['manager']),

        // Teams
        canListTeams: hasRoles(['manager', 'coordinator', 'telephone']),
        canListAllTeams: hasRoles(['manager']),

        // Clients
        canListClients: hasRoles(['manager', 'coordinator', 'telephone']),
        canManageClientNetwork: hasRoles(['manager', 'coordinator']),
        canAccessClientTasks: hasRoles(['manager', 'coordinator']),
        canManageClientNotifications: hasRoles(['manager', 'coordinator']),
        canResetClientPassword: hasRoles(['manager', 'coordinator']),
        canSendClientInvite: hasRoles(['manager', 'coordinator']),
        canListClientContacts: hasRoles(['manager', 'coordinator', 'telephone']),

        // Employees
        canListEmployees: hasRoles(['manager', 'coordinator', 'telephone']),
        canListCoordinators: hasRoles(['manager']),
        canListManagers: hasRoles(['manager']),
        canManageEmployeePicture: hasRoles(['manager', 'coordinator']),
        canManageEmployeeRoles: hasRoles(['manager']),
        canManageEmployeeTeams: hasRoles(['manager']),
        canResetEmployeePassword: hasRoles(['manager']),
        canSendEmployeeInvite: hasRoles(['manager', 'coordinator']),
        canSeeEmployeeSyncStatus: hasRoles(['manager', 'coordinator']),
        canResetEmployee2fa: hasRoles(['manager']),

        // Broadcasts
        canManageBroadcasts: hasRoles(['manager']),

        // Learn
        canManageLearn: hasRoles(['learn']),

        // Logs
        canAccessLogs: hasRoles(['manager']),

        // Phone
        canAccessPhone: hasRoles(['telephone']),
    }
}
