import {AbstractRepository} from "../repository";
import {KeycloakUser} from "./employees-repository";

export type Client = {
    id: number
    client_number: string,
    sub: string|null,
    gender: "M"|"F"|"X"
    team_id: number|null
    city: string|null
    zipcode: string|null
    actions?: string[]|null
} & KeycloakUser

export type SelectableClient = {
    client_id: number
    client: {
        name: string
        email: string
    }
    permissions: {
        can_access_agenda: boolean
        can_access_contacts: boolean
        can_access_documents: boolean
        can_access_tasks: boolean
    }
}
export default class ClientsRepository extends AbstractRepository {
    /**
     * List all clients
     */
    public all(): Promise<Client[]> {
        return this.request<{data: Client[]}>('GET', 'clients')
            .then(this.unpackDataCollectionResponse)
    }

    /**
     * List my clients
     */
    public selectable(): Promise<SelectableClient[]> {
        return this.request<{data: SelectableClient[]}>('GET', 'my_clients')
            .then(this.unpackDataCollectionResponse)
    }

    /**
     * Invite client account
     */
    public invite(subject: string): Promise<void> {
        return this.request('POST', `client/${subject}/invite`, 'POST')
            .then(() => {})
    }
}
